import React from 'react';
import {get} from 'lodash';
import styled from 'styled-components';
import {Controller} from "react-hook-form";
import {format} from 'date-fns';

import Checkbox from '../../../../components/forms/Checkbox';
import Text from '../../../../components/typography';
import Links from '../../../../components/ui/Links';
import {theme} from '../../../../themes/default-theme';


const SummaryItem = styled.div`
  margin: 35px 0;
`

const SummaryItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.palette.middleGrey};

`

const SummaryItemBody = styled.div`

`

const SummaryItemItem = styled.div`
  margin: 7px 0;
  display: flex;
`

const SummaryItemType = styled.div`
  min-width: 160px;
`

const SummaryItemVal = styled.div`

`

const Error = styled.p`
  color: ${theme.palette.error.form}
`

const FormItem = styled.div`
  display: block;
  margin:${props => props.margin === 'none' ? `0` : `15px 0`};
`

const Step4 = ({formProps, onFormChange, state, summary, country, setStep}) => {

  const {
    control,
    errors
  } = formProps

  const formatDate = (date) => {
    if (date.length === 0) {
      return ''
    }
    const formattedDate = format(date, 'E, do MMM yyyy');
    return formattedDate.toString();
  }
  
  const dealerTitle = process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.name : "{{data.template.dealer.name}}"

  return (
    <>
      <SummaryItem>
        <SummaryItemHeader>
          <Text variant="h6" colour="black">Your details</Text>
          <Links href="#" onClick={() => setStep(1)}>Modify</Links>
        </SummaryItemHeader>
        <SummaryItemBody>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">First Name:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step1.first_name', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Last Name:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step1.last_name', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Email:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step1.email', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Contact Number:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step1.phone', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Postcode:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step1.postcode', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
        </SummaryItemBody>
      </SummaryItem>
      <SummaryItem>
        <SummaryItemHeader>
          <Text variant="h6" colour="black">Service details</Text>
          <Links href="#" onClick={() => setStep(2)}>Modify</Links>
        </SummaryItemHeader>
        <SummaryItemBody>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Service type:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step2.service_type.text', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Additional notes:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{get(summary, 'step2.notes', '')}</Text></SummaryItemVal>
          </SummaryItemItem>
          <SummaryItemItem>
            <SummaryItemType><Text variant="body1" colour="black">Supporting photos:</Text></SummaryItemType>
            <SummaryItemVal><Text variant="body1">{summary.step2 && summary.step2['files[]'] ? summary.step2['files[]'].length : 0}</Text></SummaryItemVal>
          </SummaryItemItem>
        </SummaryItemBody>
      </SummaryItem>
    
      <FormItem>
        <Controller
          as={Checkbox}
          name="newsletter"
          type="checkbox"
          control={control}
          label={`Sign me up for ${process.env.DEALER_SITE ? `${dealerTitle}'s` : `Jayco ${country}`} e-Newsletter`}
          error={get(errors, `[newsletter]`, false) ? true : false}
          defaultValue={state.newsletter || false}
          rules={{
            type: 'custom'
          }}
        />
      </FormItem>
      <FormItem>
        <Controller
          as={Checkbox}
          name="privacy"
          type="checkbox"
          control={control}
          label={`I have read and agree to ${process.env.DEALER_SITE ? `${dealerTitle}'s` : `Jayco ${country}`} <a href="/privacy" target="_blank">Privacy Policy</a>*`}
          error={get(errors, `[privacy]`, false) ? true : false}
          defaultValue={state.privacy || false}
          rules={{
            required: true,
            type: 'custom'
          }}
        />
        {(get(errors, `[privacy]`, false) && !state.time) && <Error>This field is required</Error>}
      </FormItem>
    </>
  )
}
  
Step4.propTypes = {

}
  
Step4.defaultProps = {

}
  
export default Step4
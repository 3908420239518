import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Cta from '../Cta';
import {theme} from '../../../themes/default-theme';


const Container = styled.div`
    padding-left: 16px;
    padding-right: 16px;
${theme.breakpoints.up('md')} {
    max-width: 1254px;
    margin: auto;
  }
`
const CtaContainer = styled.div`
    text-align: center;
    ${theme.breakpoints.up("md")} {
         padding-bottom: 16px;
        > button {
            min-width: 220px;
        }
    }
    ${theme.breakpoints.down("sm")} {
        padding-bottom: 16px;
   }
`
const IframeContainer = styled.div`
    margin-top: 0;
`

const ApplyNowIframe = (props) => {

    const {
        ctaText,
        iframeCode
    } = props
  
    const [iframeToggle, setIframeToggle] = useState(false);

    const handleClick = () => {
        setIframeToggle(!iframeToggle)
    }

    return (
        <Container>
            {
                ctaText &&
                    <CtaContainer>
                        <Cta inline onClick={handleClick}>
                            {ctaText}
                        </Cta>
                    </CtaContainer>
            }
            {
                iframeCode && iframeToggle &&
                    <IframeContainer dangerouslySetInnerHTML={{ __html: iframeCode}}/>
            }
        </Container>
    )
  }
    
  ApplyNowIframe.propTypes = {
    ctaText: propTypes.string,
    iframeCode: propTypes.string
  }
    
  ApplyNowIframe.defaultProps = {
    ctaText: null,
    iframeCode: null
  }
    
  export default ApplyNowIframe
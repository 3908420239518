import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {Grid} from '@material-ui/core';
import NextLink from '../../../components/navigation/NextLink';
import Typography from '@material-ui/core/Typography';
import {Text} from '../../../components/typography'
import Moment from 'moment';
import Router from 'next/router';
import Truncate from 'react-truncate';
import ReactHtmlParser from 'react-html-parser';

import {RespImg, SocialShareUpdate} from '../../../components/ui';
import YoutubeVideo from '../../../components/multimedia/YoutubeVideo';
import Cta from '../../../components/ui/Cta';
import DynamicForm from '../../../components/forms/DynamicForm';
import ThumbnailCarousel from '../../../components/multimedia/ThumbnailCarousel';


const TopWrapper = styled.div`
  background-color: ${theme.palette.white};
  padding: 30px 0;

  ${theme.breakpoints.up('md')} {
    padding: 40px 0;
  }
  ${theme.breakpoints.up('lg')} {
    padding: 80px 0;
	}
	
	img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}

	.rounded-image picture {
		border-top-left-radius: 80px;
		overflow: hidden;
	}
`

const PageContainer = styled.div`
  max-width: 1440px;
  font-weight: 700;
  margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;

  & .hide-for-large {
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }
  & .hide-for-small {
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  }
` 

const ArticleDetailContainer = styled.div`
	margin-bottom: 56px;
	display: flex;

	${theme.breakpoints.down('md')} {
		margin-bottom: 10px;
		flex-direction: column;
	}
` 

const Article = styled.div`
	width: 100%;

	${props => props.sideBar &&`
		${theme.breakpoints.down('md')} {
			padding-bottom: 40px;
			border-bottom: 1px solid #D6D6D6;
		}

		${theme.breakpoints.up('lg')} {
			min-width: 969.095px;
			padding-right: 72px;
			border-right: 1px solid #D6D6D6;
		}
	`}
` 

const ArticleHeader = styled.div`
	
` 

const ArticleBody = styled.div`
	
` 

const ArticleHeaderBottom = styled.div`
	position: relative;
	margin-top: 23px;
	padding-top: 23px;
	padding-bottom: 23px;
	border-top: 1px solid #D6D6D6;
	display: flex;
	align-items: center;
	margin-bottom: 37px;
	min-height: 100px;

	${theme.breakpoints.down('sm')} {
		margin-bottom: 0;
	}
` 

const ShareContainer = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
` 

const TextContainer = styled.div`
	padding: 0;

	& a {
		color: ${theme.palette.vividBlue};
	}

	& .MuiButtonBase-root {
		color: white !important;
	}
	& .strong {
		color: ${theme.palette.vividBlue} !important;
	}

	${theme.breakpoints.down('sm')} {
		padding: 17px 0px;
	}
` 

const BodyText = styled.div`
	font-size: 16px;
	font-weight: 400;

	ul,
	ol {
		color: ${theme.palette.darkGrey};
	}

	${theme.breakpoints.down('sm')} {
		font-size: 14px;

		ul,
		ol {
			font-size: 14px;
		}
	}
` 

const CategoryFormat = styled.div`
	color: #758094;
	font-size: 16px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 10px;

	${theme.breakpoints.down('sm')} {
		font-size: 14px;
		margin-top: 8px;
		text-align: center;
	}
` 

const CategoryTitle = styled.div`
	width: max-content;
	color: #FFF;
	font-size: 12px;
	font-weight: 700;
	line-height: 21px;
	text-transform: capitalize;
	background-color: #0170D4;
	padding: 3px 10px;
	margin-bottom: 25px;

	${theme.breakpoints.down('sm')} {
		font-size: 10px;
		font-weight: 700;
		line-height: 21px;
		padding: 1px 6px;
		margin-bottom: 15px;
		margin-left: auto;
		margin-right: auto;
	}
` 

const ArticleTitle = styled(Text)`
	font-size: 34px;
	font-weight: 700;

	${theme.breakpoints.down('sm')} {
		font-size: 18px;
		text-align: center;
	}
` 

const Profile = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
` 

const AmbassadorName = styled.div`
	color: #758094;
	font-size: 16px;
	font-weight: 700;
	line-height: 23px;
` 

const AmbassadorMotto = styled.div`
	color: #758094;
	font-size: 16px;
	font-weight: 400;
	line-height: 23px
` 

const AmbassadorImage = styled.div`
	width: 75px;
	height: 75px;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 100%;
	overflow: hidden;

	${theme.breakpoints.down('sm')} {
		width: 50px;
		height: 50px;
	}

	${props => props.src &&
		`background-image: url(${props.src})`
	}
` 

const CtaItem = styled.div`
	display: inline-block;
	margin-right: 30px;

	${theme.breakpoints.down('sm')} {
		display: block;
		margin: 0;
		margin-bottom: 10px;
	}
	
	button {
		min-width: 263px;
		${theme.breakpoints.down('sm')} {
			width: 100%;
			min-width: auto;
		}
	}
`

const FeaturedArticles = styled.div`
	${theme.breakpoints.down('md')} {
		padding-top: 23px;
	}
		
	${theme.breakpoints.up('lg')} {
		padding-left: 78px;
	}
`

const CommunityGrid = styled(Grid)`
	${theme.breakpoints.down('sm')} {
		margin-bottom: 16px;
	}
  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
	
	& .box-child {
		${theme.breakpoints.up('md')} {
			
			height: 100%;
		}
	}

	a {
		text-decoration: none;
	}
` 

const CommunityColumnItem = styled.div`
  display: flex;
	flex-direction: column;
	gap: 14px;

	${theme.breakpoints.down('sm')} {
		direction: rtl;
	}

	${theme.breakpoints.down('md')} {
		flex-direction: row;
	}

	> div:first-child {
		width: 100%;

		${theme.breakpoints.up('md')} {
			max-width: 20%;
		}

		${theme.breakpoints.up('lg')} {
			max-width: 211px;
		}
	}
`

const Category = styled.div`
	position: absolute;
	background: ${theme.palette.vividBlue};
	left: 0px;
	top: 0px;
	color: white;
	padding: 3px 10px;
	color: #FFF;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	line-height: 21px;
	text-transform: capitalize;

	${theme.breakpoints.down('md')} {
		left: auto;
		right: 0;
	}
` 

const ThumbnailContainer = styled.div`
	position: relative;
` 

const CaptionContainerSmall = styled.div`
	direction: ltr;

	${theme.breakpoints.down('md')} {
		width: 180%;
	}

	${theme.breakpoints.up('lg')} {
		padding: 0;
	}
` 

const CommunityTitleSmall = styled.div`
  font-size: 20px;
	font-weight: 700;
	padding-bottom: 4px;
	color: ${theme.palette.charcoal};

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
		padding-bottom: 8px;
  }
` 

const CommunityDescriptionSmall = styled.div`
  font-size: 16px;
	font-weight: 400;
	padding-bottom: 4px;
	color: ${theme.palette.gunmentalGrey};
	margin-top: 10px;
	margin-bottom: 10px;

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
		padding-bottom: 6px;
  }

	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
` 

const ArticleContainer = (props) => {
	const {title, date, category, data, ambassador, featuredArticles} = props;
	
	Moment.locale('en');
	var dt = date;

  useEffect(() => {
    // This will only happen in preview mode (when domain isn't available in getStaticPaths)
    if (!data) {
      // To prevent any slow routing on development
      process.env.NODE_ENV === 'development' ?
        window.location.href = '/community'
      :
      Router.push('/community');
    }
  }, [])
	console.log('props: ', props);

  return (
    <div>
			<TopWrapper>
        <PageContainer>
					<ArticleDetailContainer>
						<Article sideBar={props.featuredArticles ? true : false}>
							<ArticleHeader>
								<Typography>
									{category && 
									<NextLink href={`/community/category/${category.toLowerCase().replace(" &amp; ", "-").replace(/ /g, '-')}`}>
										<a>
											<CategoryTitle dangerouslySetInnerHTML={{__html: category}} />
										</a>
									</NextLink>
									}
									<ArticleTitle variant="h1" colour="black">
										<span dangerouslySetInnerHTML={{__html: title}} />
									</ArticleTitle>
									<CategoryFormat>
										{dt && Moment(dt).format('Do MMM YYYY')}
									</CategoryFormat>
								</Typography>
								<ArticleHeaderBottom>
									{ambassador && (ambassador.image || ambassador.name) &&
										<Profile>
											{ambassador.image &&
												<AmbassadorImage src={ambassador.image.sourceUrl} />
											}
											<div>
												<AmbassadorName>{ambassador.name}</AmbassadorName>
												<AmbassadorMotto>Jayco Ambassadors</AmbassadorMotto>
											</div>
										</Profile>
									}
									<ShareContainer>
										<SocialShareUpdate />
									</ShareContainer>
								</ArticleHeaderBottom>
							</ArticleHeader>
							<ArticleBody>
								{data && data.map(function(articleComponent, index) {
									if (articleComponent.image) {									
										return (
											<RespImg
												{...articleComponent.image}
											/>
										)
									} 
									else if (articleComponent.text) {  
										return (
											<TextContainer>
												<BodyText dangerouslySetInnerHTML={{ __html: articleComponent.text }} />
												{articleComponent.cta && articleComponent.cta.map((item, index) => (
													<CtaItem key={index}>
														<Cta
															className={index === 1 && 'strong'}
															href={item.link} 
															target={item.target} 
															inline 
															bg={index % 2 === 0 ? item.colour : null}
															outline={index % 2 !== 0 ? true : false}
															>
																{item.title}
															</Cta>
													</CtaItem>
												))}
											</TextContainer>
										)
									} else if (articleComponent.staticlyForm) {
										return (
											<DynamicForm
												{...articleComponent.staticlyForm}
											/>
										)
									} else if (articleComponent.video) {  
										return (
											<YoutubeVideo
												url={articleComponent.video}
											/>
										)
									} else if (articleComponent.gallery) {
										return (
											<ThumbnailCarousel
												key={index}
												arrowPadding={true}
												items={articleComponent.gallery.map(item => {
													return {
														image: item.image,
														thumbnail: item.image
													}
												})}
											/>
										)
									}
								})}
							</ArticleBody>
						</Article>
						{props.featuredArticles &&
							<FeaturedArticles>
								{props.featuredArticles.slice(0, 4).map(function(article, index) {
									return (
										<CommunityGrid>
											<NextLink href={article.uri}>
												<a>
													<CommunityColumnItem>
														{article.img &&
															<ThumbnailContainer radius={props.borderRadius ? 1 : 0}>
																<RespImg
																	{...article.img}
																/>
																{article.category && <Category dangerouslySetInnerHTML={{__html: article.category}} />}
															</ThumbnailContainer>
														}
														<CaptionContainerSmall className={props.type}>
															{article.title && <CommunityTitleSmall>{ReactHtmlParser(article.title)}</CommunityTitleSmall>}
															{article.teaser && <CommunityDescriptionSmall>{ReactHtmlParser(article.teaser)}</CommunityDescriptionSmall>}
														</CaptionContainerSmall>
													</CommunityColumnItem>
												</a>
											</NextLink>
										</CommunityGrid>
									)
								})}
							</FeaturedArticles>
						}
					</ArticleDetailContainer>
          {/* <Grid container className={'hasPadding'} spacing={0} direction={'row'} align={'left'} justify={'center'} wrap={'wrap'}>
						<Grid item xs={12} md={10} lg={8}>
							{data && data.map(function(articleComponent, index) {
								if (articleComponent.image) {									
									return (
										<Box key={index} px={2} className={index === 0 ? 'rounded-image' : 'base-image'}>
											<RespImg
												{...articleComponent.image}
											/>
										</Box>
									)
								} 
								else if (articleComponent.text) {  
									return (
										<Box key={index} px={2}>
											<TextContainer>
												<BodyText dangerouslySetInnerHTML={{ __html: articleComponent.text }} />
												{articleComponent.cta && articleComponent.cta.map((item, index) => (
													<CtaItem key={index}>
														<Cta
															className={index === 1 && 'strong'}
															href={item.link} 
															target={item.target} 
															inline 
															bg={index % 2 === 0 ? item.colour : null}
															outline={index % 2 !== 0 ? true : false}
															>
																{item.title}
															</Cta>
													</CtaItem>
												))}
											</TextContainer>
										</Box>
									)
								} else if (articleComponent.staticlyForm) {
                  return (
                    <Box key={index}>
                      <DynamicForm
                        {...articleComponent.staticlyForm}
                      />
                    </Box>
                  )
                } else if (articleComponent.video) {  
									return (
										<Box key={index} px={2}>
											<YoutubeVideo
												url={articleComponent.video}
											/>
										</Box>
									)
								} else if (articleComponent.gallery) {
									return (
										<Box key={index} px={2}>
											<ThumbnailCarousel
												key={index}
                        arrowPadding={true}
												items={articleComponent.gallery.map(item => {
                          return {
                            image: item.image,
                            thumbnail: item.image
                          }
                        })}
											/>
										</Box>
									)
								}
							})}
						</Grid>
          </Grid> */}
        </PageContainer>
      </TopWrapper>
    </div>
  )
}

export default ArticleContainer;
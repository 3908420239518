import React, { useState, useRef } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import { get } from 'lodash';

import { theme } from '../../../themes/default-theme';
import Text from '../../typography';
import TabsUnderline from '../../ui/TabsUnderline';
import SeriesCard from '../../navigation/NewMainNav/SeriesCard';
import Slider from '../SsrSlider';
import { ReactSVG } from 'react-svg';

import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';
import Cta from '../../ui/Cta'
import NextLink from '../../navigation/NextLink'

// import Carousel from "react-multi-carousel";

const Container = styled.div`

`

const Heading = styled(props => {
    return <Text {...props} />
})`
    margin-bottom: 5px;
    text-align: center;
    ${theme.breakpoints.up("md")} {
        margin-bottom: 20px;
    }
  `

const SeriesCardContainer = styled.div`  
    width:100%;
    padding: 0 8px;
    margin-top: 25px;    

    .slick-dots {
        list-style: none;
        text-align: center;
        font-size: 0;
        text-align: center;
        padding: 0;

        li {
            display: inline-block;
            padding: 0 6px;

            &.slick-active {
                button {
                    background-color: ${theme.palette.vividBlue};
                }
            }

            &.slick-next,
            &.slick-prev {
                ${theme.breakpoints.down('md')} {
                    display: none;
                }
                button {
                    background-color: transparent;
                    padding: 0;
                }

                svg {
                    height: 10px;
                    width: auto;
                    position: relative;
                    top: 5px;
                    [stroke] {
                        stroke: #758094;
                    }
                }
            }

            svg {
                ${theme.breakpoints.down('md')} {
                    display: none;
                }
            }
        }

        button {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            border: none;
            background-color: ${theme.palette.middleGrey};
            font-size: 0;
        }
    }

    ${props => props.carouselLength < 5 &&
        `
        ${theme.breakpoints.up('lg')} {
            .slick-track {
                text-align: center;
            }
    
            .slick-slide {
                width: 25% !important;
                display: inline-block;
                float: none;
            }    
        }

        `
    }



`

const SeriesCardItemContainer = styled.div`
    ${props => (props.display === false || props.display === 'false') && `
    display: none!important;
    `}
    `

const SeriesCardItem = styled.div`

    ${props => (props.display === false || props.display === 'false') && `
        display: none!important;
    `
    }
    text-align: center;
    padding: 0 8px;
    margin-bottom: 15px;
    > div {
        margin-left: auto;
        margin-right: auto;
        max-width: 240px;

        ${theme.breakpoints.up('md')} {
            max-width: 320px;
        }
    }
    * {
        text-align: center;
        justify-content: center;
    }
`

const CtasContainer = styled.div`
    font-size: 0;
    margin-top: 20px;
    text-align: center;
`
const CtaItem = styled.div`
    display: inline-block;
    margin-bottom: 14px;
`

const RangeCarousel = (props) => {
    const [activeRange, setActiveRange] = useState(0)
    const slider = useRef();

    const {
        title,
        ranges,
        settings,
        cta
    } = props

    const handleArrowClick = (type) => {
        if (type === 'prev') {
            slider.current.slickPrev();
        } else {
            slider.current.slickNext();
        }
    }

    const carouselLength = get(ranges[activeRange], 'series', []).length;

    const slickSettings = {
        fade: false,
        dots: true,
        rows: 1,
        slidesToShow: carouselLength < 4 ? carouselLength : 4,
        arrows: false,
        slidesToScroll: 1,
        infinite: false,
        appendDots: (dots) => {
            return (
                <ul className="slick-dots">
                    <li className="slick-arrow slick-prev"><button onClick={() => handleArrowClick('prev')}><ReactSVG src={chevronLeft} /></button></li>
                    {dots}
                    <li className="slick-arrow slick-next"><button onClick={() => handleArrowClick('next')}><ReactSVG src={chevronRight} /></button></li>
                </ul>
            )
        },
        responsive: [
            {
                breakpoint: theme.breakpoints.width('md'),
                settings: {
                    slidesToShow: carouselLength > 1 ? 2 : 1,
                    rows: carouselLength > 4 ? 2 : 1,
                }
            }
        ],
        // prevArrow: <PrevArrow {...arrowProps}/>,
        // nextArrow: <NextArrow  {...arrowProps}/>,
        ...settings
    }

    // const responsive = {
    //     superLargeDesktop: {
    //       // the naming can be any, depends on you.
    //       breakpoint: { max: 4000, min: 3000 },
    //       items: 5
    //     },
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1024 },
    //       items: 3
    //     },
    //     tablet: {
    //       breakpoint: { max: 1024, min: 464 },
    //       items: 2
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 1
    //     }
    // };
    console.log('rc', ranges);
    return (
        <Container>
            <Grid container spacing={0} direction={'row'} wrap="nowrap" justifyContent={'space-between'} align={'flex-start'} >
                <Grid item xs={12}>
                    <Box px={2}>
                        {title &&
                            <Heading variant="h3" size="extraLarge" colour="charcoal" uppercase={true} weight={800}>{title}</Heading>
                        }
                        {ranges &&
                            <TabsUnderline data={ranges} activeIndex={activeRange} onChange={e => setActiveRange(e)} />
                        }
                    </Box>
                    <Box>
                        {(ranges && ranges.length > 0 && ranges[activeRange] && ranges[activeRange].series) &&
                            <SeriesCardContainer carouselLength={ranges[activeRange].series.length}>

                                {/* <Carousel responsive={responsive}> */}

                                {ranges.map((parent, i) => {
                                    return (
                                        <SeriesCardItemContainer display={activeRange == i ? true : false}>
                                            <Slider
                                                key={activeRange} // re render when range updates
                                                // forwardedRef={slider} 
                                                ref={slider}
                                                settings={slickSettings}
                                                arrowProps={{
                                                    grey: false,
                                                    lightGrey: theme === 'dark' ? false : true,
                                                    darkGrey: theme === 'dark' ? true : false,
                                                    medium: true
                                                }}
                                            >
                                                {(parent.series && parent.series.length > 0) && parent.series.map((item, index) => {
                                                    return (
                                                        <SeriesCardItem key={item.id || index} display={((ranges[activeRange] && ranges[activeRange].series) && (JSON.stringify(parent.series) == JSON.stringify(ranges[activeRange].series))) ? true : false}>
                                                            <SeriesCard
                                                                href={item.href}
                                                                title={item.title}
                                                                subTitle={item.subTitle}
                                                                sleeps={item.sleeps}
                                                                image={item.image}
                                                                hasborder='false'
                                                                mobileStyles={false}
                                                                hideSleeps={true}
                                                            />
                                                        </SeriesCardItem>
                                                    )

                                                })

                                                }
                                            </Slider>

                                        </SeriesCardItemContainer>
                                    )
                                })}
                                {/* </Carousel> */}
                                {/* <div className="slider-controls">
                                    <button ref={prev} type="button" className="slide-m-prev">prev</button>
                                    <div ref={dots} className="slide-m-dots"></div>
                                    <button ref={next} type="button" className="slide-m-next">next</button>
                                </div> */}
                                {/* <CustomDots>
                                    <li><button className="slick-prev slick-arrow"></button></li>
                                    {ranges[activeRange].series.map((item, index) => {
                                        return (
                                            <li key={item.id || index}>
                                                <button>{index}</button>
                                            </li>
                                        )
                                    })}
                                    <li><button className="slick-next slick-arrow"></button></li>
                                </CustomDots> */}
                                {cta &&
                                    <CtasContainer>
                                        <CtaItem>
                                            <NextLink href={cta.href}>
                                                <a>
                                                    <Cta inline>
                                                        {cta.title}
                                                    </Cta>
                                                </a>
                                            </NextLink>
                                        </CtaItem>
                                    </CtasContainer>
                                }
                            </SeriesCardContainer>
                        }
                    </Box >
                </Grid>
            </Grid>
        </Container>
    )
}

RangeCarousel.propTypes = {
}

RangeCarousel.defaultProps = {
    settings: {}
}

export default RangeCarousel
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import {theme} from '../../../themes/default-theme';
import Link from '../../navigation/NextLink';
import { ReactSVG } from 'react-svg';

import chevronRight from '../../../assets/icons/chevron-right-white.svg';
import { ButtonBase } from '@material-ui/core';

const LinkButtonContainer = styled(ButtonBase)`
  & {
    font-size: 17px;
    color: ${theme.palette.vividBlue};
    text-decoration: none;
  }
  & .svg-container {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${theme.palette.vividBlue};
    svg {
      margin: 0 auto;
      width: 100%;
      height: 12px;
    }
  }
  &:hover {
    text-decoration: none;
    color: #0C5393;
    .svg-container {
      background: #0C5393;
    }
  }
  ${theme.breakpoints.down("md")} {
    & {
      font-size: 12px;
    }
    & .svg-container {
      font-size: 14px;
      width: 18px;
      height: 18px;
      svg {
        height: 14px;
      }
    }
  }
  ${theme.breakpoints.down("sm")} {
    & {
      font-size: 14px;
    }
  }
  ${props =>
    props.secondary &&
      css`
      & {
        color: ${theme.palette.skyBlue};
      }
      & .svg-container {
        background: ${theme.palette.skyBlue};
      }
      &:hover {
        color: #0170D4;
        .svg-container {
          background: #0170D4;
        }
      }
  `}
  ${props =>
    props.stroke &&
      css`
      & {
        font-size: 14px;
        font-weight: 900;
        color: ${theme.palette.charcoal};
      }
      & .svg-container {
        width: 20px;
        height: 20px;
        background: none;
        position: relative;
        top: 2px;
        display: inline-block;

        svg {
          height: 16px;
        }
      }
      &:hover {
        color: ${theme.palette.charcoal};
        .svg-container {
          background: none;
          path {
            stroke: ${theme.palette.skyBlue};
          }
        }
      }
  `}
  ${props =>
    props.white &&
      css`
      & {
        color: ${theme.palette.white};
      }
      & .svg-container {
        width: 20px;
        height: 20px;
        background: none;
        position: relative;
        top: 2px;
        display: inline-block;
        * {
          stroke: ${theme.palette.white};
        }
      }
      &:hover {
        color: ${theme.palette.charcoal};
        .svg-container {
          background: none;
          path {
            stroke: ${theme.palette.charcoal};
          }
        }
      }
  `}
  ${props =>
    props.save &&
      css`
      & .svg-container {
        * {
          stroke: ${theme.palette.white};
        }
      }
  `}
`;

export const LinkButton = props => {
  const {children, svg} = props;

  return (
    <LinkButtonContainer>
      <ReactSVG src={svg} className="svg-container" />
      {children}
    </LinkButtonContainer>
  );
}

LinkButton.propTypes = {
  svg: PropTypes.string,
  children: PropTypes.any.isRequired
};

LinkButton.defaultProps = {
  svg: chevronRight,
};

export default LinkButton;
import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import Link from '../NextLink';
import Text from '../../typography';
import BreadcrumbsSeparator from '../../../assets/icons/breadcrumbs-separator.svg';
import BreadcrumbsSeparatorMob from '../../../assets/icons/breadcrumbs-separator-mob.svg';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import NextLink from '../NextLink';
import chevronRight from '../../../assets/icons/chevron-right-grey.svg';
import { useMediaQuery } from '@material-ui/core';

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)';
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)';

const ForsaleContainer = styled.div`
  font-size: 14px;
`

const Item = styled.span`
  display: inline-block;
  vertical-align: middle;
  position: relative;

  a {
    color: inherit;
    text-decoration: none;

    &[href]:hover {
      text-decoration: underline;
    }
  }

`

const SvgContainer = styled.span`
  display: inline-block;
  position: relative;
  top: 1px;

  svg {
    width: 11px;
    height: 12px;
    margin-right: 8px;
    margin-left: 8px;
  }
`

const Container = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    z-index: 1;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 5;
    ${downSm} {
        ${props => props.isSeriesPage == 1 && `
                position: absolute;
            `
        }
        ${props => props.isSeriesPage == 1 && !props.dealer && `
            top: 71px;
            padding-top: 6px!important;
            margin-top: 4px;
        `
        }
        ${props => props.isSeriesPage == 1 && props.dealer == 1 && `
            top: 63px;
            padding-top: 6px!important;
            margin-top: 4px;
        `
        }
    }
    ${upMd} {
        max-width: 1432px;
        position: absolute;
        padding-left: 16px;
        padding-right: 16px;
        ul {
            position: relative;
            width: max-content;
            li {
                position: relative;
            }
        }
    }
    ${theme.breakpoints.up('lg')} {
        max-width: 1480px;
        padding-left: 40px;
        padding-right: 40px;
    }
    ${props => props.hasSubnav == 1 && `
        // ${upMd} {
        //     margin-top: 155px;
        //  }
        // ${downSm} {
        //     margin-top: 135px;
        // }
    `
    }
    ${props => props.breadcrumbsPosition == 'absolute' && `
        ${upMd} {
             position: absolute;
             ul {
                // padding: 0 10px;
                // background: rgba(0,0,0,0.2);
                // &:before {
                //     content: '';
                //     background: rgba(0,0,0,0.2);
                //     position: absolute;
                //     width: 100%;
                //     height: 100%;
                //     left: 0;
                // }
            }   
        }
        ${downSm} {
            border-top: 1px solid #D6D6D6;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    `}
    ${props => props.breadcrumbsPosition == 'relative' && `
        ${downSm} {
             position: absolute;
        }
    `}
    * {
        margin-bottom: 0;
        line-height: 24px;
        font-size: 14px;
        ${theme.breakpoints.down('sm')} {
            font-size: 10px;
        }
        // transition: all 1000ms ease;
        color: #758094;
        font-family: ${theme.headingFontFamily};
        font-weight: 500
    }
`

const Breadcrumbs = styled.ul`
        font-family: ${theme.headingFontFamily};
        font-weight: 500;
        margin: 0;
        padding: 0;
        opacity: 1;
        a {
            font-weight: 500;
            line-height: 24px;
            // transition: all 1000ms ease;
            text-decoration: none;
            color: #758094;
            font-family: ${theme.headingFontFamily};
        }
      ${props => props.breadcrumbsPosition == 'absolute' && `
        ${upMd} {
            li, a, div {
                color: ${theme.palette.white};
                font-weight: 600;
            }
            li {
                &:not(:last-child) {
                    &:after {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
    `}
     
`
const BreadcrumbsList = styled.li`
    &, > div {
        display: inline-block;
    }
    &:not(:last-child) {
        margin-right: 14px;
        &:after {
            content: url(${BreadcrumbsSeparator});
            margin-left: 14px;
            vertical-align: middle;
            ${theme.breakpoints.down('sm')} {
                content: url(${BreadcrumbsSeparatorMob});
            }
        }
    }

`


const NewBreadcrumbs = (props) => {
    const {
        list,
        items,
        showBreadcrumbs,
        breadcrumbsPosition,
        hasSubnav,
        onClick
    } = props;

    const router = useRouter();

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": list?.length > 0 && list.map((item, index) => {
            const itemData = item.link.href ? {
                item: (process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN ? 'https://' + process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN : '') + item.link.href
            } : {}
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.link.title,
                ...itemData
            }
        })
    }

    const schemaDataForSale = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": items?.list?.length > 0 && items.list.map((item, index) => {
            const itemData = item.link.href ? {
                item: (process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN ? 'https://' + process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN : '') + item.link.href
            } : {}
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.link.title,
                ...itemData
            }
        })
    }

    // Set Dynamic width
    const media = useMediaQuery(theme.breakpoints.up('0'));
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (router?.route == '/range/[range]/[series]') {
            if (ref.current && ref.current.getBoundingClientRect()) {
                setHeight(ref.current.getBoundingClientRect());
                const heightT = ref.current.getBoundingClientRect();
                props.setBreadcrumbsHeight(heightT.height);
            }
            function handleWindowResize() {
                if (ref.current && ref.current.getBoundingClientRect()) {
                    setHeight(ref.current.getBoundingClientRect());
                    const heightT = ref.current.getBoundingClientRect();
                    props.setBreadcrumbsHeight(heightT.height);
                }
            }
            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        } else {
            setHeight(0);
        }

    }, [router && router.route]);

    // useEffect(() => {
    //     if (router?.asPath.indexOf('for-sale/') > -1) {
    //         setHeight(0);
    //     } else {
    //         props.setBreadcrumbsHeight(height.height);
    //     }
    // }, [height])


    return (
        router?.asPath.indexOf('for-sale/') > -1 ?

            items?.list?.length > 0 &&
            <>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaDataForSale) }} />

                <ForsaleContainer>
                    {items.list.map((link, index) => {
                        return (
                            <Item key={index}>
                                <NextLink href={link.link.href}><a onClick={(e) => onClick(e, link)}>{link.link.title}</a></NextLink>
                                {index + 1 !== items.list.length ?
                                    <SvgContainer>
                                        <ReactSVG src={chevronRight} />
                                    </SvgContainer>
                                    : ' '}
                            </Item>
                        )
                    })}
                </ForsaleContainer>
            </>
            :
            list?.length > 0 && showBreadcrumbs &&
            <>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />

                <Container ref={ref} role="presentation" theme={theme} hasSubnav={hasSubnav ? 1 : 0} breadcrumbsPosition={breadcrumbsPosition} isSeriesPage={router?.route == '/range/[range]/[series]' ? 1 : 0} dealer={process.env.DEALER_SITE !== true ? 0 : 1}>
                    <Breadcrumbs breadcrumbsPosition={breadcrumbsPosition}>
                        {list.filter((child) => (child?.link?.title != 'Category' && child?.link?.title != 'Article') ).map((item, index) => {
                            return (
                                <BreadcrumbsList>
                                    {!item.active ?
                                            <Link href={item.link.href} key={index}>
                                                <a target={item.link.target}>
                                                    {item.link.title}
                                                </a>
                                            </Link>
                                        : 
                                            <Text variant="body1" key={index}>
                                                {item.link.title}
                                            </Text>
                                    }
                                </BreadcrumbsList>
                            )
                        })}
                    </Breadcrumbs>
                </Container>

            </>
    )
}

NewBreadcrumbs.propTypes = {
    list: propTypes.array,
    items: propTypes.object,
    show: propTypes.bool,
    breadcrumbsPosition: propTypes.string,
    hasSubnav: propTypes.bool,
    onClick: propTypes.func
}

NewBreadcrumbs.defaultProps = {
    list: [],
    items: null,
    showBreadcrumbs: true,
    breadcrumbsPosition: null,
    hasSubnav: null,
    onClick: () => { }
}

export default NewBreadcrumbs
import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box as MuiBox} from '@material-ui/core';

import {theme} from '../../../themes/default-theme';
import MultiContentBanner from '../MultiContentBannerv2';
import Carousel from '../Carousel';
import getOrCreateRef from '../../../util/getOrCreateRef';
import Text from '../../typography';

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  position: relative;

  .carousel-arrow-group {
    display: none;

    ${theme.breakpoints.up("md")} {
      display: block;
    }

    ${theme.breakpoints.up("lg")} {
      margin: 20px;
    }
  }

  .react-multi-carousel-dot-list  {
    bottom:27%;    
    left:50%;
    transform:translateX(-50%);
    ${theme.breakpoints.up("md")} {
      bottom:20px;   
      width:100%;
      padding-left: 36px;
      padding-right: 36px;
      justify-content:flex-start;
    }
    ${theme.breakpoints.up("lg")} {
      padding-left: 84px;
      padding-right: 84px;
    }
  }

  .react-multi-carousel-dot {

    button {
      border:none; 
      appearance: none;
      background:${theme.palette.white};
    }
    &--active {
      button {
        background:${theme.palette.vividBlue};
      }
    }
  }

`

const Heading = styled(props => {
  return <Text {...props} />
})`
  margin-bottom: 10px;
  ${theme.breakpoints.up("md")} {
    text-align: center;
    margin-bottom: 50px;
  }
`

let videoReferences = [];

const MultiContentBannerSlider = ({
  banner,
  title,
  id
}) => {

  const sliderRef = useRef(null);

  const handlePrevClick = index => {
    const prevSlide = sliderRef.current.state.currentSlide - 1;
    sliderRef.current.goToSlide(prevSlide)
  }

  const handleNextClick = index => {
    const nextSlide = sliderRef.current.state.currentSlide + 1;
    sliderRef.current.goToSlide(nextSlide)
  }

  const carouselSettings = {
    speed: 500,
    beforeChange: (oldIndex, newIndex) => resetVid(oldIndex, newIndex),
    infinite: banner.length > 1 ? true : false,
    arrows: false,
    showDots : banner.length > 1 ? true : false,
  }

  const resetVid = (oldIndex, newIndex) => {

    const oldRef = videoReferences[oldIndex];
    const newRef = videoReferences[newIndex];

    if (oldRef && oldRef.current) {
      // Wait until transition complete
      setTimeout(() => { 
        oldRef.current.currentTime = 0;
        oldRef.current.pause();
      }, carouselSettings.speed || 500);
    }

    if (newRef && newRef.current) {
      newRef.current.play();
    }
  }

  return (
    <Container id={id}>
      {title &&
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <MuiBox px={2}>
              <Heading variant="h3" weight={800} colour="charcoal" uppercase={true}>{title}</Heading>
            </MuiBox >
          </Grid>
        </Grid>
      }
      {/* {process.env.DEALER_SITE ?
        <>
          <Carousel
            forwardedRef={sliderRef}
            showButtonGroup={false}
            settings={carouselSettings}
          >
            <MultiContentBanner
              mobileArrows={false}
              onPrevClick={() => {handlePrevClick('{{@index}}')}}
              onNextClick={() => {handleNextClick('{{@index}}')}}
              videoRef={getOrCreateRef('{{@index}}', videoReferences)}
              slider={true}
              heading={'{{ibcTitle}}'}
            />
          </Carousel>
        </>
      : */}
        { banner && typeof(banner) === 'object' &&
          <Carousel
            forwardedRef={sliderRef}
            showButtonGroup={false}
            settings={carouselSettings}
          >
          
            {banner.map((item, index) =>  (
              <MultiContentBanner
                key={index}
                mobileArrows={banner.length > 1 ? true : false}
                onPrevClick={() => {handlePrevClick(index)}}
                onNextClick={() => {handleNextClick(index)}}
                videoRef={getOrCreateRef(index, videoReferences)}
                slider={true}
                {...item}
              />
            ))}
          </Carousel>
        }

    </Container>
  )
}

MultiContentBannerSlider.propTypes = {
  banner: propTypes.array.isRequired,
  title: propTypes.string,
  id: propTypes.string
}
  
MultiContentBannerSlider.defaultProps = {
  banner: [],
  title: null,
  id: null
}
  
export default MultiContentBannerSlider
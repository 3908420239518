import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import {theme} from '../../../themes/default-theme';
import Slider from '../../multimedia/Slider';
import Text from '../../typography';

const ListContainer = styled.div`
  margin: 0 -16px;
  ${theme.breakpoints.up('sm')} {
    margin: 0 -11px;
  }
`

const TextContainer = styled.div`
  margin-bottom: 60px;
`

const List = styled.div`
  overflow: hidden;
  padding-bottom: 60px;

  .slick-track {

  }

  .slick-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }

  .slick-arrow {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 1;
    display: block;
    bottom: -45px;

    ${theme.breakpoints.up('md')} {
      bottom: -60px;
    }
  }

  .slick-prev {
    transform: translateX(-20px);

    ${theme.breakpoints.up('md')} {
      transform: translateX(-30px);
    }
  }

  .slick-next {
    transform: translateX(20px);

    ${theme.breakpoints.up('md')} {
      transform: translateX(30px);
    }
  }
`

const ListItem = styled.div`
  outline: none;
  height: 100%;

  ${theme.breakpoints.up('sm')} {
    padding: 0 11px;
  }
`

const ListItemContainer = styled.div`
  background-color: ${theme.palette.lightBlue};
  padding: 40px 30px;
  height: 100%;

  ${theme.breakpoints.up('md')} {
    padding: 40px;
  }

  ${props => props.greyBg && `
    background-color: ${theme.palette.lightGrey};
  `}

  ${props => props.first && ` {

    ${theme.breakpoints.up('md')} {
      border-top-left-radius: 70px;
    }
  `}

  ${props => props.last && ` {

    ${theme.breakpoints.up('md')} {
      border-bottom-right-radius: 70px;
    }
  `}
`
  
const ListingText = ({
  heading,
  items
}) => {

  const slickSettings = {
    slidesToShow: 3,
    fade: false,
    infinite: false,
    responsive: [
      {
        breakpoint: theme.breakpoints.width('md'),
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: theme.breakpoints.width('sm'),
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
      <Grid item xs={12}>
        <Box px={2}>
          {heading && 
            <TextContainer>
              <Text variant="h2" size="large" colour="charcoal" uppercase={true} align="center">{heading}</Text>
            </TextContainer>
          }
          <List>
            <ListContainer>
              <Slider
                settings={slickSettings}
                arrowProps={{
                  skyBlue: true,
                  grey: false
                }}
              >
                {items.map((item, index) => {
                  return (
                    <ListItem key={item.title || index}>
                      <ListItemContainer first={index === 0} last={(index + 1) === items.length} greyBg={index % 2 !== 0 ? true : false}>
                        <Text variant="h5" colour="charcoal">{item.title}</Text>
                        <Text component="div" variant="body1">{ReactHtmlParser(item.content)}</Text>
                      </ListItemContainer>
                    </ListItem>
                  )
                })}
              </Slider>
            </ListContainer>
          </List>
        </Box>
      </Grid>
    </Grid>
  )
}
  
ListingText.propTypes = {
  heading: propTypes.string,
  items: propTypes.array.isRequired
}
  
ListingText.defaultProps = {
  heading: null,
  items: []
}
  
export default ListingText
import React from 'react';
import StockContainer from './Container';
import styled from 'styled-components';

import {theme} from '../../themes/default-theme';
import Quicklinks from '../../components/navigation/Quicklinks';

const Container = styled.div `
  padding: 30px 0 0;
  margin-bottom: 30px;

  ${theme.breakpoints.up('md')} {
    padding: 60px 0 0;
    margin-bottom: 60px;
  }

  ${theme.breakpoints.up('lg')} {
    padding: 100px 0 0;
    margin-bottom: 100px;
	}

`

const Stock = (props) => {
  return (
  	<Container>
      <StockContainer {...props} />
      <Quicklinks 
        dealerComponent={true}
      />
    </Container>
  )
}
  
Stock.propTypes = {
}
  
Stock.defaultProps = {
}
  
export default Stock
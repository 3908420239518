import React, {useState} from 'react';
import propTypes from 'prop-types';
import {theme} from '../../themes/default-theme';
import styled from 'styled-components';
import NextLink from '../../components/navigation/NextLink';
import {ReactSVG} from 'react-svg';

import Dropdown from '../../components/ui/Dropdown';
import withWidth, {ShowOnWidth} from '../../components/hoc/withWidth';
import {isWidthUp} from '@material-ui/core/withWidth';

import chevronDown from '../../assets/icons/new-chevron-down.svg';
import NewSearchIcon from '../../assets/icons/search_bolded.svg';
import NavClose from '../../assets/icons/new_nav_close.svg';

const Container = styled.div`
	margin-top: 47px;
	margin-bottom: 0;

	${theme.breakpoints.up("md")} {
		margin-top: 60px;
	}

	${theme.breakpoints.down("md")} {
		margin-bottom: 20px;
	}
`

const CategoryWrapper = styled.div`
  text-align: center;
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const CategoryContainer = styled.div`
  text-align: center;
  position: relative;
  display: flex;
	justify-content: center;
	gap: 18px;

  ${props => props.search && `
    padding-right: 40px;

    ${theme.breakpoints.down("sm")} {
      display: flex;
      padding-right: 0;
    }
  `}

  ${props => props.visible === 0 && `
    opacity: 0;
  `}

  ${theme.breakpoints.up("md")} {
		gap: 35px;
    padding-top: 24px;
  }
`

const Category = styled.a`
	position: relative;
	color: #758094;
	font-size: 15px;
	font-weight: 700;
	text-transform: capitalize;
  text-decoration: none;
	padding-bottom: 7px;
  cursor: pointer;
  
  ${theme.breakpoints.up("md")} {
    padding-bottom: 14px;
  }

	&.category-current {
		color: ${theme.palette.vividBlue};

		&:after {
			content: '';
			width: 80%;
			height: 4px;
			background-color: ${theme.palette.vividBlue};
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			${theme.breakpoints.up("lg")} {
				bottom: 10px;
			}
		}
	}
`

const DropdownContainer = styled.div`
	width: 100%;
	max-width: 347px;
	position: relative;
	top: -12px;
	display: flex;
	gap: 11px;

	${theme.breakpoints.down("sm")} {
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	
	> div {
		width: 100%;
		height: 44px;

		${theme.breakpoints.up("md")} {
			min-width: 168px;
		}

		${theme.breakpoints.down("sm")} {
			width: 50%;
			height: 38px;
			line-height: 20px;
			font-size: 14px;
		}
	}
`

const SearchButton = styled.div`
	position: relative;
	height: 44px;
	padding: 8px 25px;
	color: #758094;
	font-size: 16px;
	font-weight: 400;
	border-radius: 22px;
	border: 1px solid #D6D6D6;
	text-align: left;
	line-height: 26px;
	cursor: pointer;

	&:after {
		content: '';
		width: 16px;
		height: 17.563px;
		background-image: url(${NewSearchIcon});
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		right: 21px;
		top: 50%;
		transform: translateY(-50%);
	}
`

const MoreButton = styled.div`
	position: relative;
	color: #758094;
	font-size: 15px;
	font-weight: 700;
	text-transform: capitalize;
	text-decoration: none;
	padding-bottom: 7px;
	cursor: pointer;
	display: flex;
  gap: 6px;

	svg {
		width: 11px;
	}

	${theme.breakpoints.up("md")} {
		padding-bottom: 14px;
	}
`

const MenuContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #F2F2F2;
	left: 100%;
	top: 0;
	transition: all 300ms ease;
	z-index: 99;
	display: flex;
	flex-direction: column;
	padding: 81px 28px;
	align-items: flex-start;
	gap: 33px;

	${props => props.active && `
		left: 0;
	`}
`

const MenuCategory = styled.a`
	position: relative;
	color: #758094;
	font-size: 13px;
	font-weight: 700;
	line-height: 7.5px;
	cursor: pointer;
	padding-bottom: 12px;

	&.category-current {
		color: ${theme.palette.vividBlue};

		&:after {
			content: '';
			width: 100%;
			height: 4px;
			background-color: ${theme.palette.vividBlue};
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			${theme.breakpoints.up("lg")} {
				bottom: 10px;
			}
		}
	}
`

const SearchNavClose = styled(ReactSVG)`
  position: absolute;
  right: 30%;
  top: 34px;
  cursor: pointer;
  z-index: 115;

  ${theme.breakpoints.down('sm')} {
    right: 22px;
    top: 22px;
  }

  &:hover {
      svg {
          [fill] {
              fill: ${theme.palette.charcoal};
          }
      }
  }
`

const FilterContainer = (props) => {
	const [activeDropdown, setActiveDropdown] = useState(false);

	const {
    categories,
    categoryLocation,
		width,
		searchClick,
		sortClick,
		activeSort,
		dealer
	} = props;

  return (
		<>
			<Container>
				<ShowOnWidth show={isWidthUp('lg', width)}>
					<CategoryWrapper>
						<CategoryContainer>
							<NextLink href={'/community'}>
								<Category
									className={categoryLocation === '/community' && 'category-current'} 
									dangerouslySetInnerHTML={{__html: 'Featured'}}
								/>
							</NextLink>
							{categories.map((item, index) => (
								<NextLink key={index} href="/community/category/[item.slug]" as={`/community/category/${item.slug}`}>
									<Category
										className={categoryLocation === '/community/category/' + item.slug && 'category-current'} 
										dangerouslySetInnerHTML={{__html: item.name}}
									/>
								</NextLink>
							))}
							{!dealer &&
								<DropdownContainer>
									<Dropdown
										label={activeSort ? activeSort : 'Sort by'}
										options={[
											{
												text: 'Newest',
												value: 'newest'
											},
											{
												text: 'Oldest',
												value: 'oldest'
											}
										]}
										theme={'v2'}
										onChange={e => sortClick(e)}
									/>
									<SearchButton onClick={searchClick}>
										Search
									</SearchButton>
								</DropdownContainer>
							}
						</CategoryContainer>
					</CategoryWrapper>
				</ShowOnWidth>
				<ShowOnWidth show={!isWidthUp('lg', width)}>
					<CategoryWrapper>
						<CategoryContainer>
							<NextLink href={'/community'}>
								<Category
									className={categoryLocation === '/community' && 'category-current'} 
									dangerouslySetInnerHTML={{__html: 'Featured'}}
								/>
							</NextLink>
							{categories.slice(0, isWidthUp('md', width) ? 3 : 1).map((item, index) => (
								<NextLink key={index} href="/community/category/[item.slug]" as={`/community/category/${item.slug}`}>
									<Category
										className={categoryLocation === '/community/category/' + item.slug && 'category-current'} 
										dangerouslySetInnerHTML={{__html: item.name}}
									/>
								</NextLink>
							))}
							<MoreButton
								onClick={() => setActiveDropdown(!activeDropdown)}
							>
								More
								<ReactSVG src={chevronDown} />
							</MoreButton>
						</CategoryContainer>
						{!dealer &&
							<CategoryContainer>
								<DropdownContainer>
									<Dropdown
										label={activeSort ? activeSort : 'Sort by'}
										options={[
											{
												text: 'Newest',
												value: 'newest'
											},
											{
												text: 'Oldest',
												value: 'oldest'
											}
										]}
										onChange={e => sortClick(e)}
									/>
									<SearchButton onClick={searchClick}>
										Search
									</SearchButton>
								</DropdownContainer>
							</CategoryContainer>
						}
						<MenuContainer active={activeDropdown}>
							<NextLink href={'/community'}>
								<MenuCategory
									className={categoryLocation === '/community' && 'category-current'} 
									dangerouslySetInnerHTML={{__html: 'Featured'}}
								/>
							</NextLink>
							{categories.map((item, index) => (
								<NextLink key={index} href="/community/category/[item.slug]" as={`/community/category/${item.slug}`}>
									<MenuCategory
										className={categoryLocation === '/community/category/' + item.slug && 'category-current'} 
										dangerouslySetInnerHTML={{__html: item.name}}
									/>
								</NextLink>
							))}
							<SearchNavClose
								src={NavClose}
								onClick={() => setActiveDropdown(!activeDropdown)}
							/>
						</MenuContainer>
					</CategoryWrapper>
				</ShowOnWidth>
			</Container>
		</>
  )
}

FilterContainer.propTypes = {
  categories: propTypes.array,
  categorySearch: propTypes.bool,
  categoryLocation: propTypes.string,
	searchClick: propTypes.func,
	sortClick: propTypes.func,
	activeSort: propTypes.string,
	dealer: propTypes.bool,
}
  
FilterContainer.defaultProps = {
  categories: [],
  categorySearch: false,
  categoryLocation: null,
	searchClick: () => {},
	sortClick: null,
	activeSort: null,
	dealer: false
}
  
export default withWidth(FilterContainer)
import React from 'react';

// components
import ThankYouContainer from '../../../../src/containers/Forms/ThankYou';

const ThankYou = (props) => {
  return (
    <>
      <ThankYouContainer 
        {...props}
        text="Your request has been received. A service agent will contact you soon to confirm your booking."
      />
    </>
  )
}
  
ThankYou.propTypes = {

}
  
ThankYou.defaultProps = {

}

export default ThankYou

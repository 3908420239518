import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';

import Banner from '../../../components/multimedia/HeroBannerSmall';
import BannerDealer from '../../../components/multimedia/Banner';
import ArticleContainer from './ArticleContainer';
import DealerContainer from './ArticleContainerDealer';
import CommunityContainer from '../CommunityContainer';

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)';
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)';

const Wrapper = styled.div`
  ${theme.breakpoints.up('sm')} {
    // margin-top: 74.15px;
  }
  ${upMd} {
    margin-top: 87px;
  }

  ${props => props.negativeMargin && `
    ${theme.breakpoints.up('sm')} {
      margin-top: -75px;
    }
    ${theme.breakpoints.up('md')} {
      margin-top: -81px;
    }
  `}
`

const BottomWrapper = styled.div`
  padding-bottom: 30px;

  ${theme.breakpoints.up('md')} {
    padding-bottom: 60px;
  }
  ${theme.breakpoints.up('lg')} {
    padding-bottom: 120px;
	}

`

const ArticlePage = (props) => {

  const [data, setData] = useState(props);
  const [dealerData, setDealerData] = useState(false);
  
  useEffect(() => {
    setData({
      ...data,
      ...props
    });
  },[props])

	useEffect(() => {
    try {
      const component = document.getElementById('staticly-dynamics-component-CommunityArticle');
      const jsonData = window.staticly.data && window.staticly.data.CommunityArticle ? window.staticly.data.CommunityArticle : null

      if (component && !jsonData) {
        component.remove();
        return
      }
      if (jsonData) {
        jsonData && setData(jsonData);
        setDealerData(true);
        component && component.remove();
      }
    } catch {
    }
	}, []);

  const hbString = `{{#staticlyDynamic data from="template.data" id="CommunityArticle" component="src/containers/Community/Article/index.js"}}{{/staticlyDynamic}}`

  const main_site = !process.env.DEALER_SITE;

  if (!main_site && !dealerData) {
    return (
      <div dangerouslySetInnerHTML={{__html: hbString}}>
      </div>
    )
  } else {

    console.log('props: ', props);

    return (
      <Wrapper negativeMargin={data.heroBanner && process.env.DEALER_SITE ? true : false}>
        {data.heroBanner && !process.env.DEALER_SITE ?
          <Banner
            {...data.heroBanner[0]}
          />
        :
          <BannerDealer
            {...data.heroBanner}
            type="mediumBanner"
          />
        }
        {!process.env.DEALER_SITE ?
          <ArticleContainer
            title={data.title}
            category={data.category}
            date={data.date}
            data={data.articleConversion}
            ambassador={data.ambassador}
            featuredArticles={data.featuredArticles}
          />
        :
          <DealerContainer
            title={data.title}
            category={data.category}
            date={data.date}
            data={data.articleConversion}
          />
        }
        {data.categoryConversion && data.categoryConversion.length > 0 &&
          <BottomWrapper>
            <CommunityContainer
              title={data.category}
              articles={data.categoryConversion.slice(0, 3)}
            />
          </BottomWrapper>
        }
      </Wrapper>
    )
  }
}

ArticlePage.propTypes = {
  negativeMargin: propTypes.bool
}

ArticlePage.defaultProps = {
  negativeMargin: false
}

export default ArticlePage;
import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Pagination} from '@material-ui/lab';
import {theme} from '../../../themes/default-theme';

const StyledPagination =  styled(props => {
  return <Pagination {...props} />
})`
  .MuiPaginationItem-root {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.gunmentalGrey};

    &.Mui-selected:hover {
      color: white;
      background-color: ${theme.palette.vividBlue};
    }
    ${theme.breakpoints.down("sm")} {
      width: 20px;
      height: 20px;
      min-width: auto;
      font-size: 12px;
      padding: 0;
      margin: 0px 8px;
    }
  }
  ${theme.breakpoints.up("md")} {
    li:first-child,
    li:last-child {
      .MuiPaginationItem-root {
        width: 110px;
        font-size: 17px;
        color: ${theme.palette.white};
        background-color: ${theme.palette.vividBlue};
        border-radius: 24.5px;
        opacity: 1 !important;
        svg {
          display: none;
        }
      }
      .Mui-disabled {
        background: none;
        border: 2px solid ${theme.palette.vividBlue};
        &:after{
          color: ${theme.palette.vividBlue} !important;
        }
      }
    }
    li:first-child .MuiPaginationItem-root {
      margin-right: 20px;
      &:after {
        content: 'Prev';
        color: white;
      }
    }
    li:last-child .MuiPaginationItem-root {
      margin-left: 20px;
      &:after {
        content: 'Next';
        color: white;
      }
    }
  }
  ${theme.breakpoints.down("sm")} {
    li:first-child,
    li:last-child {
      .MuiPaginationItem-root {
        color: ${theme.palette.white};
        background-color: ${theme.palette.skyBlue};
        border-radius: 24.5px;
      }
    }
  }
  .Mui-selected {
    color: ${theme.palette.white};
    background: ${theme.palette.vividBlue};
  }
  .MuiPaginationItem-ellipsis {
    padding-top: 2px;
    ${theme.breakpoints.up("md")} {
      padding-top: 11px;
    }
  }
`;

export const OutputPagination = props => {  
  return (
    <StyledPagination
      {...props}
    />
  );
}

export default OutputPagination;
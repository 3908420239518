import React, {useState, useEffect} from 'react';
import {theme} from '../../../themes/default-theme';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import Banner from '../../../components/multimedia/HeroBannerSmall';
import BannerDealer from '../../../components/multimedia/Banner';
import CommunityContainer from '../CommunityContainer';
import NewNavSearch from '../../../components/navigation/NewMainNav/newNavSearch';
import FilterContainer from '../FilterContainer';
import Router from 'next/router';

import NavClose from '../../../assets/icons/new_nav_close.svg';

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)';
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)';

const SearchContainer = styled.div`
  background: #F2F2F2;
  display: flex;
  flex-direction: column;
  gap: 31px;
  justify-content: center;
  align-items: center;
  padding-top: 93px;
  padding-bottom: 65px;
  position: fixed;
  z-index: 4;
  width: 100%;

  ${theme.breakpoints.down('sm')} {
    height: 100%;
    justify-content: flex-start;
  }

  > div:nth-child(2) {
    width: 100%;
    max-width: 694px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SearchBG = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  background: black;
  opacity: .3;
  top: 0;
  left: 0;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const SearchNavClose = styled(ReactSVG)`
  position: absolute;
  right: 30%;
  top: 34px;
  cursor: pointer;
  z-index: 115;

  ${theme.breakpoints.down('sm')} {
    right: 22px;
    top: 22px;
  }

  &:hover {
      svg {
          [fill] {
              fill: ${theme.palette.charcoal};
          }
      }
  }
`

const SearchTitle = styled.div`
  color: #0170D4;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const Wrapper = styled.div`
  ${theme.breakpoints.up('sm')} {
    // margin-top: 74.15px;
  }
  ${upMd} {
    margin-top: 87px;
  }

  ${props => props.negativeMargin && `
    ${theme.breakpoints.up('sm')} {
      margin-top: -75px;
    }
    ${theme.breakpoints.up('md')} {
      margin-top: -81px;
    }
  `}
`

const BottomWrapper = styled.div`
  padding: 30px 0;

  ${theme.breakpoints.up('md')} {
    padding-bottom: 60px;

    ${props => props.paddingTop &&`
      padding-top: 60px;
    `}
  }
`

const CategoryPage = (props) => {
  const [heroBanner, setHeroBanner] = useState(props.heroBanner);
  const [articleConversion, setArticleConversion] = useState(props.articleConversion);
  const [categories, setCategories] = useState(props.categories);
  const [dealerData, setDealerData] = useState(false);
  const [search, setSearch] = useState(false);
  const [sortArticles, setSortArticles] = useState(null);
  const [activeSort, setActiveSort] = useState(null);

  // useEffect(() => {
  //   setArticleConversion(props.articleConversion);
  // },[props.articleConversion]);

	useEffect(() => {

    try {
      const component = document.getElementById('staticly-dynamics-component-CommunityCategory');
      // const properties = component ? component.getAttribute('data-component-properties') : null;
      // const jsonData  = properties ? JSON.parse(properties) : null;
      const jsonData = window.staticly.data && window.staticly.data.CommunityCategory ? window.staticly.data.CommunityCategory : null

      if (component && !jsonData) {
        component.remove();
        return
      }

      if (jsonData) {
        jsonData.heroBanner && setHeroBanner(jsonData.heroBanner);
        jsonData.articleConversion && setArticleConversion(jsonData.articleConversion);
        jsonData.categories && setCategories(jsonData.categories);
        setDealerData(true);
        component && component.remove();
      }
    } catch {
      // do nothing
    }

	}, []);

  const searchClick = () => {
    setSearch(!search)
  };

  const sortHandle = (e) => {
    var articles = articleConversion.sort((a, b) => b.date.localeCompare(a.date));

    if (e.value === 'oldest') {
      articles = articleConversion.reverse();
    }
    setSortArticles(articles);
    setActiveSort(e.text);
  };

  useEffect(() => {
    setArticleConversion(sortArticles ? sortArticles : props.articleConversion);
  },[activeSort]);

  const handleSearchSubmit = (val) => {
    setSearch(false);
    window.location.href = "/community?search=" + val
      .split(' ').join('+');
  }

  const hbString = `{{#staticlyDynamic data from="template.data" id="Community"  component="src/containers/Community/Category/index.js"}}{{/staticlyDynamic}}`

  const main_site = !process.env.DEALER_SITE;

  if (!main_site && !dealerData) {
    return (
      <div dangerouslySetInnerHTML={{__html: hbString}}>
      </div>
    )
  } else {
    
    return (
      <Wrapper negativeMargin={heroBanner && process.env.DEALER_SITE ? true : false}>
        {search &&
          <>
            <SearchContainer>
              <SearchTitle>SEARCH JAYCO COMMUNITY</SearchTitle>
              <NewNavSearch
                onFormSubmit={handleSearchSubmit}
              />
              <SearchNavClose src={NavClose} onClick={searchClick} />
            </SearchContainer>
            <SearchBG onClick={searchClick} />
          </>
        }
        {heroBanner && !process.env.DEALER_SITE ?
          <Banner
            {...heroBanner[0]}
          />
        :
          <BannerDealer
            {...heroBanner}
            type="mediumBanner"
          />
        }
        <FilterContainer
          categories={categories}
          categoryLocation={'/community/category/' + props.slug}
          categorySearch={process.env.DEALER_SITE}
          searchClick={searchClick}
          sortClick={sortHandle}
          activeSort={activeSort}
          dealer={process.env.DEALER_SITE ? true : false}
        />
        <BottomWrapper paddingTop={process.env.DEALER_SITE ? true : false}>
          <CommunityContainer
            title={props.name}
            articles={articleConversion}
            slug={props.slug}
          />
        </BottomWrapper>
      </Wrapper>
    )
  }
}

export default CategoryPage;
import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';

import {RespImg} from '../../ui';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';
import topo from '../../../assets/topo-grey.png';


const Wrapper = styled.div`

`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #2E3238;
  mix-blend-mode: normal;
  padding-bottom: 22px;

  &:before {
    content: ' ';
    display: block;
    width: 1600px;
    height: 22px;
    background-color: white;
    background-image: url(${topo});
    background-size: cover;
    background-position: bottom;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  ${theme.breakpoints.up("lg")} {
    max-height: 398px;
    max-width: ${theme.containers.full.width};
    margin: 0 auto;
    padding-bottom: 60px;

    &:before {
      height: 60px;
    }
  }
`

const ImageContainer = styled.div`
  opacity: .85;

  ${theme.breakpoints.up("lg")} {
    position: relative;
    height: 340px;

    picture {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const ContentContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-60%);
`

const Title = styled(props => {
  return <Text {...props} />
})`
  text-align: center;
  font-size: 28px;
  margin-bottom: 11px;

  ${theme.breakpoints.up("lg")} {
    margin-bottom: 30px;
    font-size: 84px;
  }
`

const Subtitle = styled(props => {
  return <Text {...props} />
})`
  text-align: center;
  line-height: 16px;
  letter-spacing: 4px;
`

const HeroBannerSmall = (props) => {
  const {
    title,
    subtitle,
    image
  } = props

  return (
    <Wrapper>
      <Container>
        {image &&
          <ImageContainer>
            <RespImg
              {...image}
            />
          </ImageContainer>
        }
        {(title || subtitle) &&
          <ContentContainer>
            {title && 
              <Title variant="h1" size="extraLarge" colour="white" uppercase={true}>{title}</Title>
            }
            {subtitle && 
              <Subtitle variant="h6" size="extraLarge" colour="white" uppercase={true}>{subtitle}</Subtitle>
            }
          </ContentContainer>
        }
      </Container>      
    </Wrapper>
  )
}
  
HeroBannerSmall.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  image: propTypes.object
}
  
HeroBannerSmall.defaultProps = {
  title: null,
  subtitle: null,
  image: {}
}
  
export default withWidth()(HeroBannerSmall)

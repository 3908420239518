import React from 'react';
import {get} from 'lodash';
import styled from 'styled-components';
import {TextField} from '@material-ui/core';
import {Controller} from "react-hook-form";
import Dropdown from '../../../../components/ui/Dropdown';
import FileUpload from '../../../../components/forms/FileUpload';
import {theme} from '../../../../themes/default-theme';

const FormItem = styled.div`
  display: block;
  margin:${props => props.margin === 'none' ? `0` : `30px 0`};
`

const Error = styled.p`
  color: ${theme.palette.error.form}
`

const Step2 = ({formProps, onFormChange, state}) => {

  const {
    control,
    errors
  } = formProps

  const textFieldProps = {
    as:TextField,
    control:control,
    rules:{required: true },
    variant:"outlined",
    fullWidth: true,
    defaultValue: ''
  }

  const serviceOptions = [{
    optionValue: 'Retail repairs',
    label: 'Retail repairs'
  },{
    optionValue: 'Insurance repairs',
    label: 'Insurance repairs'
  },{
    optionValue: 'Annual servicing',
    label: 'Annual servicing'
  },{
    optionValue: 'Warranty repairs',
    label: 'Warranty repairs'
  }]

  return (
    <>
      {/* Service type */}
      <FormItem>
        <Controller
          as={
            <Dropdown 
              variant="outlined"
              showComplete={true}
              error={get(errors, `[service_type]`, false) ? true : false}
              label={'Service type*'}
              options={serviceOptions.map((item, i) => {
                return {
                  id: item.optionValue,
                  value: item.optionValue,
                  text: item.label,
                }
              })}
            />
          }
          control={control}
          rules={{
            ...textFieldProps.rules,
            type: 'custom'
          }}
          defaultValue={typeof(state.service_type) === 'object' ? get(state, 'service_type.value', undefined) : state.service_type || undefined}
          name='service_type'
          onUpdate={onFormChange}
        />
        {(get(errors, `[service_type]`, false)) && <Error>Please select an option</Error>}
      </FormItem>
      <FormItem>
        <Controller
          {...textFieldProps}
          name={'notes'}
          multiline={true}
          type="textarea"
          rules={{}}
          placeholder={'Any additional notes and list of repairs required'}
          error={get(errors, `[notes]`, false) ? true : false}
          defaultValue={state.notes || ''}
          rows={7}
        />
      </FormItem>
      <FormItem>
        <Controller
          as={
            <FileUpload 
              defaultValue={state['files[]']}
            />
          }
          control={control}
          rules={{
            type: 'custom'
          }}
          name='files[]'
        />
      </FormItem>
    
    </>
  )
}
  
Step2.propTypes = {
}
  
Step2.defaultProps = {
}
  
export default Step2
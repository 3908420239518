import {get} from 'lodash';

const getFallbackStateAus = (post_code, filteredDealers) => {
  const postcode = parseInt(post_code);
  var dealer;
  switch(true) {
    case (postcode < 3000): // NSW
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'nsw'
      })
      break;
    case (postcode >= 3000 && postcode < 4000): // VIC
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'vic'
      })
      break;
    case (postcode >= 4000 && postcode < 5000): // QLD
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'qld'
      })
      break;
    case (postcode >= 5000 && postcode < 6000): // SA
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'sa'
      })
      break;
    case (postcode >= 6000 && postcode < 7000): // WA
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'wa'
      })
      break;
    case (postcode >= 7000): // TAS
      dealer = filteredDealers.find(item => {
        return get(item, 'acf_dealers.state', '').toLowerCase() === 'tas'
      })
      break;
  }
  return dealer ? dealer : filteredDealers[0]
}

export default getFallbackStateAus;
import React, {useState, useEffect} from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components';
import qs from 'qs';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import {get} from 'lodash';
import {ReactSVG} from 'react-svg';

import {theme} from '../../themes/default-theme';
import Text from '../../components/typography';
import Cta from '../../components/ui/Cta';
import Dropdown from '../../components/ui/Dropdown';
import RespImg from '../../components/ui/RespImg';
import {findIndex} from 'lodash';
import topo from '../../../src/assets/topo.png';
import { ButtonBase } from '@material-ui/core';
import Modal from '../../../src/components/ui/Modal';
import GetLocation from '../../../src/containers/GetLocation';
import getClosestCoordinate from '../../../src/util/getClosestCoodinate';
import latestTimestamp from '../../../src/util/latestPostcodeTimestamp';
import plus from '../../../src/assets/icons/plus.svg';

const Container = styled.div`
  background: black; // remove
  height: 400px;
  position: relative;
  padding-top: 24px;
  margin-bottom: 40px;

  ${theme.props.desktopNavUp} {
    padding-top: 180px;
    margin-bottom: 60px;
    height: 590px;
  }
  &:after {
    content: ' ';
    position: absolute;
    height: 160px;
    z-index: -1;
    left: 0;
    bottom: -40px;
    display: block;
    background-image: url(${topo});
    background-size: 400px auto;
    background-repeat: repeat;
    background-position: center bottom;
    width: 100%;
    ${theme.props.desktopNavUp} {
      bottom: -60px;
    }
  }
`

const BgImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  picture,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
  }
`

const Content = styled.div`
  max-width: 500px;
  margin 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 20px 20px; 

  h4 {
    max-width: 770px;
    margin: 0 auto;
    letter-spacing: 0;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
    }

    ${theme.breakpoints.up('md')} {
      font-size: 28px;
    }

    ${theme.breakpoints.up('lg')} {
      font-size: 34px;
    }
  }
  
  ${theme.breakpoints.up('md')} {
    max-width: 950px;
  }
  
`

const Heading = styled(Text)`

`;

const Filters = styled.div`
  color: white;
  margin-top: 12px;
  ${theme.breakpoints.up('md')} {
    padding: 30px;
    background-color: rgba(46,50,56,0.7);
    border-radius: 6px;
  }
`

const Dropdowns = styled.div`
  ${theme.breakpoints.up('md')} {
    text-align: center;
    font-size: 0;
  }
`

const DropdownItem = styled.div`
  margin-bottom: 10px;
  
  ${theme.breakpoints.up('md')} {
    display: inline-block;
    max-width: 215px;
    width: 100%;
    margin: 0 15px;
    text-align: left;
    vertical-align: bottom;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 240px;
  }
`

const PriceFilters = styled.div`
  margin-top: 10px;
  font-size: 0;

  ${theme.breakpoints.down('sm')} {
    margin-left: -15px;
    margin-right: -15px;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

  }

  ${theme.breakpoints.up('md')} {
    text-align: center;
  }
`

const PriceDropdownItem = styled.div`
  width: 136px;

  ${theme.breakpoints.down('sm')} {
    float: left;
    width: 123px;
    &:last-child {
      float: right;
    }
  }

  ${theme.breakpoints.up('md')} {
    display: inline-block;
    text-align: center;
  }
`

const PriceDropdownContainer = styled.div`
  ${props => props.autowidth === 1 ? `
    width: auto;
  ` : `
    width: 100%;
  `}

  ${theme.breakpoints.up('md')} {
    display: inline-block;
  }
`

const ContentContainer = styled.div`
  ${theme.breakpoints.down('sm')} {
    margin-top: 12px;
    padding: 20px;
    background-color: rgba(46,50,56,0.7);
    border-radius: 6px;
  }
`

const Submit = styled.div`
  margin-top: 10px;
  ${theme.breakpoints.up('md')} {
    margin: 30px auto 0;
    display: block;
    width: 140px;
  }
`

const Location = styled(ButtonBase)`
  border-radius: 22px;
  background-color: white;
  display: block;
  width: 100%;
  color: black;
  text-align: left;
  padding: 12.5px 32px 12.5px 19px;
  border: 1px solid ${theme.palette.middleGrey};
  color: ${theme.palette.darkGrey};
  font-size: 16px;
  position: relative;
  min-height: 38px;

  ${theme.breakpoints.up('md')} {
    font-size: 14px;
    min-height: 45px;
  }

  p {
    top: 50%;
    position: absolute;
    font-size: 14px;
    transform: translateY(-50%);
    padding-left: 19px;
    left: 0;
    margin: 0;

    ${theme.breakpoints.up('md')} {
      font-size: 16px;
    }
  }
`

const GetLocationContainer = styled.div`
  background-color: ${theme.palette.lightGrey};
  max-width: 366px;
  margin: 0 auto;
  padding: 20px;
  border-bottom-left-radius: 30px;

  ${theme.breakpoints.up('md')} {
    padding: 40px 50px;
    border-bottom-left-radius: 60px;
  }
`

const GetLocationError = styled.div`
  p {
    color: ${theme.palette.error.form};
  }
`

const LocationsIcon = styled.i`
  width: 24px;
  height: 24px;
  margin-right: 0px;
  color: #fff;
  border: 1px solid #fff;
  display: block;
  position: absolute;
  top: 6px;
  right: 9px;
  border-radius: 24px;
  background-color: ${theme.palette.vividBlue};
  border: 1px solid ${theme.palette.vividBlue};

  ${theme.breakpoints.up('md')} {
    top: 10px;
  }

  svg {
    text-align: center;
    width: auto;
    height: 12px;
    display: block;
    margin-left: 5px;
    margin-top: 5px;
    [fill] {
      fill: white;
    }
  }
`

const useStyles = makeStyles(theme => ({
  modalOverlay: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  modalInner: {
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    maxWidth: 390,

    [theme.breakpoints.up('md')]: {
      maxWidth: 520,
    }
  },
  modalClose: {
    top: '-40px',
    left: 10,
    marginBottom: '20px',
    position: 'absolute',

    [theme.breakpoints.up('md')]: {
      top: 0,
      bottom: 'auto',
      left: 'auto',
      right: 20,
      marginBottom: 0,
    }
  }
}));

const ForSaleHero = (props) => {

  const {
    heading,
    image,
    filters
  } = props

  const [type, setType] = useState(null);
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [modal, setModal] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [postcodes, setPostcodes] = useState([]);
  const [postcodeData, setPostcodeData] = useState(null);
  const [locationText, setLocationText] = useState('Location');


  useEffect(() => {
    const getData = async () => {
      await axios({
        method: 'GET',
        url: '/data/stock-pages-postcodes.json'
        // url: 'https://z65d722a-sb-assets-t441d948-sp.s3.amazonaws.com/wp-rest-api-json/stock-pages-postcodes/data.json'
      })
      .then((response) => {
        console.log('postcodes', response);
        setPostcodeData(response.data);
      })
      .catch((error) => {
        console.log('Request error: ', error);
        //setApiData(testData);
      });
    }
    getData();
  },[])

  useEffect(() => {
    const getPostcodes = () => {
      //setPostcodeLoading(true);
      axios.get('/data/postcodes.json', {})
      .then(function ( response) {
        // handle success
        const lsPostcodes = {
          date: Date.now(),
          data: response.data
        }
        setPostcodes(lsPostcodes.data);
        localStorage.setItem('postcodes', JSON.stringify(lsPostcodes))
        //setPostcodeLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //setPostcodeLoading(false);
      })
      .then(function () {
        // always executed
        //setPostcodeLoading(false);
      });
    }

    if (localStorage && localStorage.getItem('postcodes')) {
      const lsPostcodes = JSON.parse(localStorage.getItem('postcodes'));
      if (lsPostcodes.data && lsPostcodes.date > latestTimestamp) {
        setPostcodes(lsPostcodes.data);
      } else {
        getPostcodes();
      }
    } else {
      getPostcodes();
    }
  }, [])  

  const getFilterUrl = () => {
    let searchObj = {};

    let url = ''

    if (type) {
      searchObj.type = type.value;
    }
    if (minPrice) {
      searchObj.minPrice = minPrice.value;
    }
    if (maxPrice) {
      searchObj.maxPrice = maxPrice.value;
    }

    if (category) {
      url = `/for-sale/${category.value}`
    } else {
      url = (filters.categories && filters.categories.length > 0 ) ? `/for-sale/${filters.categories[0].value}` : ''
    }

    if (location) {
      url += `/${location.state.trim().split(' ').join('-').toLowerCase()}`
      if (location.region) {
        url += `/${location.region.trim().split(' ').join('-').toLowerCase()}`
      }
    }

    const query = qs.stringify({
      search: searchObj
    })

    url += query.length > 0 ? `?${query}` : ''

    return url;
  }

  const [url, setUrl] = useState(getFilterUrl());

  useEffect(() => {
    const filterUrl = getFilterUrl();
    setUrl(filterUrl);
  },[type,category,location,minPrice,maxPrice])

  const handlePriceChange = (type, val) => {
    if (type === 'min') {
      if (!maxPrice) {
        setMinPrice(val);
        return;
      }
      if (maxPrice.value <= val.value) {
        // setMinPrice(val);
        const priceIndex = findIndex(filters.price.max, (item => item.id === val.id));
        priceIndex && setMaxPrice(filters.price.max[priceIndex + 1]);
        if(priceIndex === 0) {
          setMaxPrice(filters.price.min[priceIndex + 1])
        } else {
          setMaxPrice(filters.price.min[priceIndex + 1]);
          setMinPrice(val);
        }
      } else {
        setMinPrice(val);
      }
    }
    if (type === 'max') {
      if (!minPrice) {
        setMaxPrice(val);
        return;
      }

      if (minPrice.value >= val.value) {
        const priceIndex = findIndex(filters.price.min, (item => item.id === val.id));
        if(priceIndex === 0) {
          setMinPrice(filters.price.min[priceIndex - 1])
        } else {
          setMinPrice(filters.price.min[priceIndex - 1]);
          setMaxPrice(val);
        }
      } else {
        setMaxPrice(val);
      }
    }
  }

  const handleLocationSubmit = (val, type) => {
    setModal(false);
    let stateLocation = null

    if (type === 'state') {
      stateLocation = {
        state: val.value
      }
      setLocationText(val.text);
      setLocation({
        state: val.value
      })
      return;
    } 

    const closest = type !== 'postcode' ? getClosestCoordinate(val, postcodes) : null; // get current location
    const postcode = type === 'postcode' ? val.postcode : get(closest, 'item.postcode', null); // postcode text input

    if (postcode && postcodeData) {
      
      const locations = postcodeData.filter(item => {
        return item.postcodes.find(locationPostcode => locationPostcode.toString() === postcode.toString())
      })

      if (locations.length > 0) {

        const location = locations.length === 1 ? locations[0] : locations.find(item => {
          return item.region.length > 0;
        })

        const l = location ? {
          state: location.state,
          region: location.region || null
        } : locations[0]

        const states = [{
          text: 'Australian Capital Territory',
          value: 'act'
        },{
          text: 'New South Wales',
          value: 'nsw'
        },{
          text: 'Northern Territory',
          value: 'nt'
        },{
          text: 'Queensland',
          value: 'qld'
        },{
          text: 'South Australia',
          value: 'sa'
        },{
          text: 'Tasmania',
          value: 'tas'
        },{
          text: 'Victoria',
          value: 'vic'
        },{
          text: 'Western Australia',
          value: 'wa'
        }]

        const lState = states.find(i => i.value.toLowerCase() ===  l.state.toLowerCase());
        if (lState) {
          const lText = l.region && l.region.length > 0 ? l.region.charAt(0).toUpperCase() + l.region.slice(1) : lState.text // l.state.charAt(0).toUpperCase() + l.state.slice(1)
          setLocationText(lText);
          setLocation(l)
        } else {
          setLocation(null)
          setLocationText('Location')
        }

      } else {
        setLocation(null)
        setLocationText('Location')
      }
    }
    
  }

  const classes = useStyles();

  return (
  	<Container>
      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        overlayClassname={classes.modalOverlay}
        innerClassname={classes.modalInner}
        closeClassname={classes.modalClose}
      >
        <GetLocationContainer>
          <GetLocation 
            onSubmit={handleLocationSubmit}
            locationButton={false}
            stateDropdown={true}
            // postcode={postcodeQuery ? postcodeQuery.toString() : ''}
          />
          {locationError &&
            <GetLocationError>
              <Text variant="body2" element="p" align="center">{locationError}</Text>
            </GetLocationError>
          }
        </GetLocationContainer>
      </Modal>

      {image && 
        <BgImage>
          <RespImg {...image} />
        </BgImage>}
      <Content>
        {heading && 
          <Heading variant="h4" uppercase colour="white" align="center">{heading}</Heading>
        }
        <ContentContainer>
          <Filters>
            <Dropdowns>
              {filters.types &&
                <DropdownItem>
                  <Dropdown 
                    value={type}
                    variant="outlined"
                    showComplete={true}
                    label={'Type'}
                    options={filters.types}
                    onChange={e => setType(e)}
                  />
                </DropdownItem>
              }
              {filters.categories &&
                <DropdownItem>
                  <Dropdown 
                    value={category}
                    variant="outlined"
                    showComplete={true}
                    label={'Category'}
                    options={filters.categories}
                    onChange={e => setCategory(e)}
                  />
                </DropdownItem>
              }
              {filters.locations &&
                <DropdownItem>
                  <Location onClick={() => setModal(true)}>
                    <Text variant="body1" element="p">
                      {locationText}
                    </Text>
                    <LocationsIcon>
                      <ReactSVG src={plus} />
                    </LocationsIcon>
                  </Location>
                  {/* <Dropdown 
                    value={location}
                    variant="outlined"
                    showComplete={true}
                    label={'Location'}
                    options={filters.locations}
                    onChange={e => setLocation(e)}
                  /> */}

                </DropdownItem>
              }
            </Dropdowns>
        
            <PriceFilters>
              <PriceDropdownItem>
                <PriceDropdownContainer autowidth={minPrice ? 1 : 0}>
                  <Dropdown 
                    theme="lite"
                    value={minPrice}
                    variant="outlined"
                    showComplete={true}
                    label={'Price (min)'}
                    options={filters.price.min}
                    onChange={e => handlePriceChange('min', e)}
                  />
                </PriceDropdownContainer>
              </PriceDropdownItem>
              <PriceDropdownItem>
                <PriceDropdownContainer autowidth={maxPrice ? 1 : 0}>
                  <Dropdown 
                    theme="lite"
                    value={maxPrice}
                    variant="outlined"
                    showComplete={true}
                    label={'Price (max)'}
                    options={filters.price.max}
                    onChange={e => handlePriceChange('max', e)}
                  />
                </PriceDropdownContainer>
              </PriceDropdownItem>
            </PriceFilters>
          </Filters>
          <Submit>
            <Cta href={url}>Search</Cta>
          </Submit>
        </ContentContainer>
      </Content>
    </Container>
  )
}
  
ForSaleHero.propTypes = {
  heading: propTypes.string,
  image: propTypes.object,
  filters: propTypes.object
}
  
ForSaleHero.defaultProps = {
  heading: null,
  image: null,
  filters: null
}
  
export default ForSaleHero
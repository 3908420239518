import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {TextField} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {get} from 'lodash';

import Text from '../../components/typography'
import {theme} from '../../themes/default-theme';
import {Cta} from '../../components/ui';
import Dropdown from '../../components/ui/Dropdown';
import searchIcon from '../../assets/icons/search-white.svg';


const Input = styled.div`
  margin: 10px 0;
  text-align: left;

  ${theme.breakpoints.up('md')} {
    margin: 12px 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 15px 0;
  }
`

const Or = styled(Text)`

`

const PostCodeInput = styled(TextField)`
  width: 100%;
  input {
    padding-right: 45px;
  }
`

const SetLocationWrapper = styled.span`
  ${props => !props.isEnabled && `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const SetLocation = styled(Cta)`
  min-width: 250px;
`

const Error = styled.p`
  // position: absolute;
  margin: 5px;
`

const PostCodeInputContainer = styled.div`
  position: relative;
`

const SubmitIcon = styled.img`
  width: 20px;
  height: 20px;
  display: block;

  ${theme.breakpoints.down('sm')} {
    width: 12px;
    height: 12px;
  }
`
const Submit = styled(Button)`
  width: 35px;
  height: 35px;
  background-color: ${theme.palette.vividBlue};
  border-radius: 30px;
  min-width: auto;
  color: ${theme.palette.white};
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: ${theme.palette.vividBlueHover}
  }

  ${theme.breakpoints.down('sm')} {
    width: 26px;
    height: 26px;
    right: 10px;
  }
`

const GetLocation = (props) => {
  const {
    onSubmit,
    locationButton,
    stateDropdown
  } = props
  const [postcodeError, setPostcodeError] = useState(false);
  const [postcode, setPostcode] = useState(props.postcode);
  const [locationBtnActive, setLocationBtn] = useState(true);
  const [gettingLocation, isGettingLocation] = useState(false);
  const [state, setState] = useState(null);
  const [selectOpen, setSelectOpen] = useState(undefined);

  useEffect(()=> {
    // navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    // .then((PermissionStatus) => {
    //   if (PermissionStatus.state !== 'granted' && PermissionStatus.state !== 'prompt') {
    //     setLocationBtn(false)
    //   }
    // })
    const userLocation = JSON.parse(get(window, 'localStorage.userLocation', '{}'));

    if (userLocation.postcode && props.postcode !== '') {
      setPostcode(userLocation.postcode);
    }

  }, [])

  const handleStateChange = (e) => {
    setState(e);
    setSelectOpen(false);
    // race condition with custom event listener on dropdown which prevents scroll
    setTimeout(() => {
      onSubmit(e, 'state');
    }, 10)
  }

  const handlePostcodeKeyup = (e) => {
    if (postcode.length === 4 && e.keyCode == 13) {
      handleSubmit();
      return
    }
    if (e.keyCode === 13) {
      setPostcodeError(true)
    }
  }

  const handleSubmit = () => {
    if (postcode.length !== 4) {
      setPostcodeError(true)
    } else {
      const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {}
      const location = {
        postcode
      }
      const data = {
        ...userLocation,
        ...location
      }
      localStorage.setItem('userLocation', JSON.stringify(data));
      onSubmit(data, 'postcode')
    }
  }

  const handlePostcodeChange = (e) => {
    const val = e.target.value;
    if (!isNaN(val) && val.length <= 4) {
      setPostcode(val);
    }
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      isGettingLocation(true);
      navigator.geolocation.watchPosition(showPosition, () => {
        setLocationBtn(false);
        isGettingLocation(false);
      });
    } else {
      setLocationBtn(false);
    }
  }

  const showPosition = (position) => {
    const localStorage = window.localStorage;
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }
    const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {} 
    const data = {
      ...userLocation,
      ...location
    }    
    localStorage.setItem('userLocation', JSON.stringify(data));
    isGettingLocation(false);
    setLocationBtn(true);
    onSubmit(data, 'location')
  }

  return (
  	<>
      <Input>
        <PostCodeInputContainer>
          <PostCodeInput
            placeholder={'Enter your postcode'}
            value={postcode}
            onChange={(e) => handlePostcodeChange(e)}
            onKeyUp={handlePostcodeKeyup}
            error={postcodeError}
            variant="outlined"
            type="tel"
          />
          <Submit onClick={handleSubmit}>
            <SubmitIcon src={searchIcon} alt="search"/>
          </Submit>
        </PostCodeInputContainer>
      </Input>
      {locationButton &&
      <>
        <Or variant="subtitle1" uppercase align="center" colour="charcoal">Or</Or>
        <Input>
          <SetLocationWrapper
            isEnabled={locationBtnActive}
          >
            <SetLocation
              onClick={getCurrentLocation}
              outline>{gettingLocation ? 'Getting location...' : 'Use my current location'}</SetLocation>
          </SetLocationWrapper>
          {!locationBtnActive &&
            <Error>Browser location is blocked.<br />Please update your browser settings.</Error>
          }
        </Input>
      </>
      }
      {stateDropdown &&
      <>
        <Or variant="subtitle1" uppercase align="center" colour="charcoal">Or</Or>
        <Input>
          <Dropdown
            open={selectOpen}
            label="Select state"
            value={state}
            options={[{
              text: 'Australian Capital Territory',
              value: 'act'
            },{
              text: 'New South Wales',
              value: 'nsw'
            },{
              text: 'Northern Territory',
              value: 'nt'
            },{
              text: 'Queensland',
              value: 'qld'
            },{
              text: 'South Australia',
              value: 'sa'
            },{
              text: 'Tasmania',
              value: 'tas'
            },{
              text: 'Victoria',
              value: 'vic'
            },{
              text: 'Western Australia',
              value: 'wa'
            }]}
            onChange={handleStateChange}
          />
        </Input>
      </>
      }

    </>
  )
}
  
GetLocation.propTypes = {
  onSubmit: propTypes.func,
  postcode: propTypes.string,
  locationButton: propTypes.bool,
  stateDropdown: propTypes.bool
}
  
GetLocation.defaultProps = {
  onSubmit: () => {},
  postcode: '',
  locationButton: true,
  stateDropdown: false
}
  
export default GetLocation
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {Grid, Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {Text} from '../../../components/typography'
import Moment from 'moment';
import Router from 'next/router';

import {RespImg, SocialShare} from '../../../components/ui';
import YoutubeVideo from '../../../components/multimedia/YoutubeVideo';
import Cta from '../../../components/ui/Cta';
import DynamicForm from '../../../components/forms/DynamicForm';
import ThumbnailCarousel from '../../../components/multimedia/ThumbnailCarousel';


const TopWrapper = styled.div`
  background-color: ${theme.palette.white};
  padding: 30px 0;

  ${theme.breakpoints.up('md')} {
    padding: 60px 0;
  }
  ${theme.breakpoints.up('lg')} {
    padding: 120px 0;
	}
	
	img {
		width: 100%;
	}

	.rounded-image picture {
		border-top-left-radius: 80px;
		overflow: hidden;
	}
`

const PageContainer = styled.div`
  max-width: 1400px;
  font-weight: 700;
  margin: 0 auto;
  & .hide-for-large {
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }
  & .hide-for-small {
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  }
` 

const ArticleDetailContainer = styled.div`
	margin-bottom: 56px;

	${theme.breakpoints.down('sm')} {
		margin-bottom: 10px;
	}
` 

const ShareContainer = styled.div`
	margin-top: 15px;
	text-align: right;

	${theme.breakpoints.down('sm')} {
		margin-top: 18px;
		text-align: left;
	}
` 

const TextContainer = styled.div`
	padding: 27px 54px;

	& a {
		color: ${theme.palette.vividBlue};
	}

	& .MuiButtonBase-root {
		color: white !important;
	}
	& .strong {
		color: ${theme.palette.vividBlue} !important;
	}

	${theme.breakpoints.down('sm')} {
		padding: 17px 0px;
	}
` 

const BodyText = styled.div`
	font-size: 16px;
	font-weight: 400;

	ul,
	ol {
		color: ${theme.palette.darkGrey};
	}

	${theme.breakpoints.down('sm')} {
		font-size: 14px;

		ul,
		ol {
			font-size: 14px;
		}
	}
` 

const CategoryFormat = styled.div`
	font-size: 14px;
	color: ${theme.palette.darkGrey};
	margin-top: 20px;
	font-weight: 700;
	text-transform: uppercase;

	${theme.breakpoints.down('sm')} {
		font-size: 12px;
	}
` 

const CategoryTitle = styled.div`
	font-weight: 700;
	color: ${theme.palette.vividBlue};
	display: inline-block;

	&::after {
		content: '|';
		display: inline-block;
		color: ${theme.palette.darkGrey};
		margin-left: 8px;
		margin-right: 8px;
	}
` 

const CtaItem = styled.div`
	display: inline-block;
	margin-right: 30px;

	${theme.breakpoints.down('sm')} {
		display: block;
		margin: 0;
		margin-bottom: 10px;
	}
	
	button {
		min-width: 263px;
		${theme.breakpoints.down('sm')} {
			width: 100%;
			min-width: auto;
		}
	}
`

const ArticleContainer = (props) => {
	const {title, date, category, data} = props;
	
	Moment.locale('en');
	var dt = date;

  useEffect(() => {
    // This will only happen in preview mode (when domain isn't available in getStaticPaths)
    if (!data) {
      // To prevent any slow routing on development
      process.env.NODE_ENV === 'development' ?
        window.location.href = '/community'
      :
      Router.push('/community');
    }
  }, [])

  return (
    <div>
			<TopWrapper>
        <PageContainer>
					<ArticleDetailContainer>
						<Grid container className={'hasPadding'} spacing={0} direction={'row'} justify={'center'}>
							<Grid item xs={12} md={10} lg={7}>
								<Box px={2}>
									<Grid container className={'hasPadding'} spacing={0} direction={'row'} justify={'center'}>
										<Grid item xs={12} md={9}>
											<Typography>
												<Text variant="h3" colour="black">
													<span dangerouslySetInnerHTML={{__html: title}} />
												</Text>
												<CategoryFormat>
													{category && <CategoryTitle dangerouslySetInnerHTML={{__html: category}} />}
													{dt && Moment(dt).format('Do MMM YYYY')}
												</CategoryFormat>
											</Typography>
										</Grid>
										<Grid item xs={12} md={3}>
											<ShareContainer>
												<SocialShare />
											</ShareContainer>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</ArticleDetailContainer>
          <Grid container className={'hasPadding'} spacing={0} direction={'row'} align={'left'} justify={'center'} wrap={'wrap'}>
						<Grid item xs={12} md={10} lg={8}>
							{data && data.map(function(articleComponent, index) {
								if (articleComponent.image) {									
									return (
										<Box key={index} px={2} className={index === 0 ? 'rounded-image' : 'base-image'}>
											<RespImg
												{...articleComponent.image}
											/>
										</Box>
									)
								} 
								else if (articleComponent.text) {  
									return (
										<Box key={index} px={2}>
											<TextContainer>
												<BodyText dangerouslySetInnerHTML={{ __html: articleComponent.text }} />
												{articleComponent.cta && articleComponent.cta.map((item, index) => (
													<CtaItem key={index}>
														<Cta
															className={index === 1 && 'strong'}
															href={item.link} 
															target={item.target} 
															inline 
															bg={index % 2 === 0 ? item.colour : null}
															outline={index % 2 !== 0 ? true : false}
															>
																{item.title}
															</Cta>
													</CtaItem>
												))}
											</TextContainer>
										</Box>
									)
								} else if (articleComponent.staticlyForm) {
                  return (
                    <Box key={index}>
                      <DynamicForm
                        {...articleComponent.staticlyForm}
                      />
                    </Box>
                  )
                } else if (articleComponent.video) {  
									return (
										<Box key={index} px={2}>
											<YoutubeVideo
												url={articleComponent.video}
											/>
										</Box>
									)
								} else if (articleComponent.gallery) {
									return (
										<Box key={index} px={2}>
											<ThumbnailCarousel
												key={index}
                        arrowPadding={true}
												items={articleComponent.gallery.map(item => {
                          return {
                            image: item.image,
                            thumbnail: item.image
                          }
                        })}
											/>
										</Box>
									)
								}
							})}
						</Grid>
          </Grid>
        </PageContainer>
      </TopWrapper>
    </div>
  )
}

export default ArticleContainer;
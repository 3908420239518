import React, {useState} from 'react';
import {Grid, Box} from '@material-ui/core';
import {theme} from '../../themes/default-theme';
import styled from 'styled-components';

import Banner from '../../components/multimedia/HeroBannerSmall';
import {RespImg} from '../../components/ui';
import Pagination from '../../components/navigation/Pagination';
import IconLink from '../../components/ui/IconLink';

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)';
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)';

const Wrapper = styled.div`
  ${theme.breakpoints.up('sm')} {
    // margin-top: 74.15px;
  }
  ${upMd} {
    margin-top: 87px;
  }
`

const PageContainer = styled.div`
  max-width: 1440px;
  font-weight: 700;
	margin: 0 auto;
	margin-top: 60px;

	${theme.breakpoints.down('md')} {
		margin-top: 30px;
  }
	
	& .MuiPagination-root {
		max-width: max-content;
		margin: 0 auto;
	}
` 

const StyledGrid = styled(Grid)`
	${theme.breakpoints.down('md')} {
		padding-left: 20px;
		padding-right: 20px;
	}
` 

const IntroContainer = styled.div`
	text-align: center;
	max-width: 780px;
	margin-bottom: 48px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
  margin-right: auto;

	${theme.breakpoints.down('sm')} {
		text-align: left;
  }
` 

const IntroTitle = styled.div`
	color: #2E3238;
	font-size: 34px;
	font-weight: 800;
	text-transform: uppercase;
	color: ${theme.palette.charcoal};

	${theme.breakpoints.down('sm')} {
		font-size: 16px;
  }
` 

const IntroCaption = styled.div`
	font-size: 15px;
	color: ${theme.palette.gunmentalGrey};
	font-weight: 400;

	${theme.breakpoints.down('sm')} {
		font-size: 14px;
  }
` 

const AmbassadorGrid = styled(Grid)`
	margin-bottom: 78px;

	${theme.breakpoints.down('md')} {
		margin-bottom: 30px;
  }
` 

const AmbassadorGridSmall = styled(Grid)`
	margin-bottom: 78px;

	${theme.breakpoints.down('md')} {
		margin-bottom: 30px;
  }

	${theme.breakpoints.up('md')} {
		padding-right: 3px;
  }
` 

const CaptionContainer = styled.div`
	padding: 12px;
	margin-top: -4px;
	background: ${theme.palette.white};

	${theme.breakpoints.up('md')} {
		padding: 26px;
  }
` 

const AmbassadorTitle = styled.div`
  font-size: 18px;
	font-weight: 700;
	padding-bottom: 4px;
  color: ${theme.palette.charcoal};

	${theme.breakpoints.up('md')} {
		min-height: 40px;
	}

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
  }
` 

const AmbassadorDescription = styled.div`
  font-size: 16px;
	font-weight: 400;
	color: ${theme.palette.gunmentalGrey};

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	
	${theme.breakpoints.up('md')} {
		min-height: 90px;
	}

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
  }
` 

const IconLinkContainer = styled.div`
	margin-top: 18px;
	display: block;
`

const Ambassadors = (props) => {
  const width = props.width;

	//Ambassadors
	const [page, setPage] = React.useState(1);
	const [start, setStart] = React.useState(0);
	const [end, setEnd] = React.useState(6);

	const handleChange = (event, value) => {
		setPage(value);
		setStart(value === 1 ? 0 : (value - 1) * 6);
		setEnd(value * 6);
	};

  return (
		<Wrapper>
			{props.heroBanner &&
				<Banner
					{...props.heroBanner[0]}
				/>
			}
			<PageContainer>
				<IntroContainer>
					{props.pageHeading && <IntroTitle>{props.pageHeading}</IntroTitle>}
					{props.pageBody && <IntroCaption dangerouslySetInnerHTML={{ __html: props.pageBody}} />}
				</IntroContainer>
				<StyledGrid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
					{props.filteredAmbassadors && props.filteredAmbassadors.slice(start,end).map(function(ambassador, index) {
						return (
							<AmbassadorGrid key={index} item xs={12} md={4}>
								<Box px={.06}>
									<RespImg
										{...ambassador.img}
									/>
									<CaptionContainer>
										{ambassador.title && <AmbassadorTitle dangerouslySetInnerHTML={{ __html: ambassador.title}} />}
										{ambassador.description && <AmbassadorDescription dangerouslySetInnerHTML={{ __html: ambassador.description}} />}
										<IconLinkContainer>
											<IconLink colour="vividBlue" href={`/ambassadors/${ambassador.uri}`} link={{ href: `/ambassadors/${ambassador.uri}`, title: 'READ MORE' }} />
										</IconLinkContainer>
									</CaptionContainer>
								</Box>
							</AmbassadorGrid>
						)
					})}
				</StyledGrid>
				<IntroContainer>
					{props.friendsHeading && <IntroTitle>{props.friendsHeading}</IntroTitle>}
					{props.friendsBody && <IntroCaption dangerouslySetInnerHTML={{ __html: props.friendsBody}} />}
				</IntroContainer>
				<StyledGrid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
					{props.filteredFriends && props.filteredFriends.map(function(friend, index) {
						return (
							<AmbassadorGridSmall key={index} item xs={12} md={3}>
								<Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
									<Grid item xs={6} md={12}>
									<RespImg
										{...friend.img}
									/>
									</Grid>
									<Grid item xs={6} md={12}>
										<CaptionContainer>
											{friend.title && <AmbassadorTitle dangerouslySetInnerHTML={{ __html: friend.title}} />}
											{friend.description && <AmbassadorDescription dangerouslySetInnerHTML={{ __html: friend.description}} />}
											<IconLinkContainer>
												<IconLink colour="vividBlue" href={`/ambassadors/${friend.uri}`} link={{ href: `/ambassadors/${friend.uri}`, title: 'READ MORE' }} />
											</IconLinkContainer>
										</CaptionContainer>
									</Grid>
								</Grid>
							</AmbassadorGridSmall>
						)
					})}
				</StyledGrid>
			</PageContainer>
		</Wrapper>
  )
}

export default Ambassadors;
import React from 'react';
import propTypes from 'prop-types';
import Text from '../../typography'
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';
import RespImg from '../../ui/RespImg';
import {theme} from '../../../themes/default-theme';

const Container = styled.div`
  margin-top: 75px;
  max-width: 1200px;

  ${theme.breakpoints.up("md")} {
    display: flex;
  }
  ${theme.breakpoints.down("md")} {
    margin-top: 40px;
  }
  ${theme.breakpoints.down("sm")} {
    margin-top: 24px;
  }
`

const Logo = styled.div`
  ${theme.breakpoints.up("md")} {
    flex-grow: 1;
    display: inline-block;
    & {
      border-left: 1px solid ${theme.palette.middleGrey};
    }
    &:last-child {
      border-left: 1px solid ${theme.palette.middleGrey};
      border-right: 1px solid ${theme.palette.middleGrey};
    }
  }
  ${theme.breakpoints.down("sm")} {
    width: 100%;
    padding: 5px;
    & {
      border-bottom: 1px solid ${theme.palette.middleGrey};
    }
    &:last-child {
      border-top: none;
      border-bottom: none;
    }
  }
`

const LogoContainer = styled.div`
  max-width: 268px;
  ${theme.breakpoints.down("md")} {
    max-width: 140px;
  }
  ${theme.breakpoints.down("sm")} {
    max-width: 160px;
  }
`

const LogoListing = ({title, items}) => {
  return (
    <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
      <Grid item xs={12}>
        <Box px={2}>
          {title && <Text variant="h2" size="large" colour="charcoal" uppercase={true} align="center">{title}</Text>}
          <Container>
            {items.map((item, index) => (
              <Logo key={index}>
                <LogoContainer>
                  {item.image &&
                    <RespImg 
                      {...item.image}
                    />
                  }
                  {item.alt || item.sizes &&
                    <RespImg 
                      {...item}
                    />
                  }
                </LogoContainer>
              </Logo>
            ))}
          </Container>
        </Box>
      </Grid>
    </Grid>
  )
}
  
LogoListing.propTypes = {
  title: propTypes.string,
  items: propTypes.array
}
  
LogoListing.defaultProps = {
  title: null,
  items: []
}
  
export default LogoListing
import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import Slider from '../Slider';
import RespImg from '../../ui/RespImg'

const Wrapper = styled.div`
  ${theme.breakpoints.up("md")} {
    ${props => !props.padding && `padding: 0 38px;`}
  }
`

const SliderContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 50px;

  ${theme.breakpoints.up("md")} {
    ${props => props.padding && `padding: 0 38px;`}
  }

  ${theme.breakpoints.up("md")} {
    padding-bottom: 0;
    margin-top: 30px;
  }

  .slick-slider {
    ${theme.breakpoints.up("md")} {
      margin: 0 -4px;
    }
  }

  .slick-arrow {
    position: absolute;
    z-index: 1;
    bottom: -50px;

    ${theme.breakpoints.up("md")} {
      bottom: auto;
      top: 50%;
    }
  }

  .slick-prev {
    left: 50%;
    transform: translateX(-35px);

    ${theme.breakpoints.up("md")} {
      left: -38px;
      transform: translateY(-50%);
    }
  }

  .slick-next {
    right: 50%;
    transform: translateX(35px);
    ${theme.breakpoints.up("md")} {
      right: -38px;
      transform: translateY(-50%);
    }
  }

  .slick-slide {
    opacity: 0.5;
    padding: 0 4px;
    outline: none;
  }

  .slick-current {
    opacity: 1;
    outline: none;

    img,
    .img {
      border: 3px solid ${theme.palette.yellow};
    }
  }
`

const CarouselItem = styled.div`
  
`

const SliderImgContaner = styled.div`
  position: relative;
  overflow: hidden;

`

const SliderImg = styled.div`
  transition: all 300ms ease;

  ${props => props.cursor === 1 && `
    cursor: pointer;
  `}
  
  ${props => props.active === 1 ? `
    position: relative;
    z-index: 1;
    opacity: 1;
  `: `
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  `}
`

const Img = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  background-size: cover;
  background-position: center;
`

const Label = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 92px;
  padding: 10px;
  color: white;
  text-align: center;
  border-top-left-radius: 12px;
  font-weight: bold;
  font-size: 12px;

  ${props => props.bg ? `
    background-color: ${theme.palette[`${props.bg}`]};
  ` : `
    background-color: ${theme.palette.vividBlue};
  `}
`

const ThumbnailCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleCarouselChange = (current, next) => {
    setActiveIndex(next)
  }

  return (
    <Wrapper padding={props.arrowPadding}>
      <SliderImgContaner>
        {props.items.map((item, index) => (
          <SliderImg key={index} active={index === activeIndex ? 1 : 0} onClick={props.onImgClick ? () => props.onImgClick(index) : () => {} } cursor={props.onImgClick ? 1 : 0}>
            {item.image && item.image.sizes &&
              <RespImg
                {...item.image}
              />
            }
            {item.image && !item.image.sizes &&
              <Img
                style={{backgroundImage: `url(${item.image ? item.image : '/fallback.png'})`}}
                className={'img'}
              />
            }
            {item.label &&
              <Label bg={item.labelBg}>{item.label}</Label>
            }
          </SliderImg>
        ))}
      </SliderImgContaner>
      {props.items.length > 1 &&
        <SliderContainer padding={props.arrowPadding}>
          <Slider
            settings={{
              fade: false,
              slidesToShow: props.items.length < 5 ? props.items.length : 5,
              infinite: true,
              beforeChange: handleCarouselChange,
              focusOnSelect: true,
              responsive: [
                {
                  breakpoint: theme.breakpoints.width('sm'),
                  settings: {
                    slidesToShow: props.items.length < 3 ? props.items.length : 3,
                  }
                }
              ],
            }}
            arrowProps={{
              skyBlue: true,
              small: true
            }}
          >
            {props.items.map((image, index) => (
              <CarouselItem key={index}>
                {image.thumbnail && image.thumbnail.sizes &&
                  <RespImg 
                    {...image.thumbnail}
                  />
                }
                {image.thumbnail && !image.thumbnail.sizes &&
                  <Img
                    // src={image.thumbnail}
                    // width={image.width}
                    // height={image.height}
                    className={'img'}
                    style={{backgroundImage: `url(${image.thumbnail ? image.thumbnail : '/fallback.png'})`}}
                  />
                }
              </CarouselItem>
            ))}
          </Slider>
        </SliderContainer>
      }
    </Wrapper>
  )
}

ThumbnailCarousel.propTypes = {
  arrowPadding: propTypes.bool,
  onImgClick: propTypes.func
}

ThumbnailCarousel.defaultProps = {
  arrowPadding: false,
  onImgClick: null
}

export default ThumbnailCarousel
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { gql } from "apollo-boost";
import contentBuilderQuery from '../src/graphql/queries/content-builder';
import client from '../src/graphql/client';
import refactorBreadcrumbsData from '../src/data-restructure/navigation/breadcrumbs/breadcrumbs';
import refactorContentBuilderData from '../src/data-restructure/contentBuilder';
import ContentBuilder from '../src/containers/ContentBuilder';
import fetch from 'isomorphic-unfetch';
import Router from 'next/router';
import styled from 'styled-components';
import { Grid, Box } from '@material-ui/core';
import { theme } from '../src/themes/default-theme';
import Disclaimer from '../src/components/ui/Disclaimer';
import ReactHtmlParser from 'react-html-parser';
import seoQuery from '../src/graphql/queries/seo';
import JaycoServiceBooking from '../src/containers/Forms/JaycoServiceBooking';
import JaycoServiceBookingThankyou from '../src/containers/Forms/JaycoServiceBooking/ThankYou';
import FindADealer from '../src/containers/FindADealer';
import Ambassadors from '../src/containers/Ambassadors';
import Careers from '../src/containers/Careers';
import OwnersClubs from '../src/containers/OwnersClubs';
import FindAServiceCentre from '../src/containers/FindAServiceCentre';
import ContactUs from '../src/containers/ContactUs';
import Stock from '../src/containers/Stock';
import Article from '../src/containers/Community/Article';
import CategoryPage from '../src/containers/Community/Category';
import ForSaleLanding from '../src/containers/ForSaleLanding';
import ListingPage from '../src/containers/ListingPage';

import {
  findADealerQuery,
  findAServiceCentreQuery,
  findAServiceAgentListingQuery,
  ambassadorsQuery,
  careersQuery,
  ownersClubQuery,
  stockQuery,
  forSaleLandingPageQuery
} from '../src/graphql/queries/pages'

import {
  findADealerRestructure,
  findAServiceCentreRefactor,
  findAServiceCentreListingRefactor,
  ambassadorsRestructure,
  careersRestructure,
  ownersClubsRestructure,
  stockRestructure,
  forSaleLandingPage
} from '../src/data-restructure/pages'

import {
  basicRestructure
} from '../src/data-restructure/rest/pages'
import ApplyNowIframe from '../src/components/ui/ApplyNowIframe';

// Combination of both main and dealer site urls
const pathsToDisclude = ['home-page', 'press-room-page', 'community', 'book-a-service', 'book-a-viewing', 'brochure-request', 'enquiry', 'finance-enquiry', 'repayments-calculator', 'enews-registration', 'find-my-perfect-jayco', 'get-a-quote', 'build-price', 'compare'];

// Main site only paths
const mainSitePaths = ['find-a-dealer', 'ambassadors', 'careers', 'owners-clubs', 'find-a-service-centre'];

// Dealer site only paths
const dealerSiteBlankTemplates = [];
const dealerSitePaths = ['contact-us', ...dealerSiteBlankTemplates];


const DisclaimerWrapper = styled.div`
  padding: 30px 0;
  background-color: ${theme.palette.lightGrey};
  ${theme.breakpoints.up('lg')} {
    padding: 55px 0;
  }
`

const ContentBuilderTemplate = (props) => {

  const {
    pageData,
    financeAddfirstTwoElem,
    financeRemoveFirstTwoElem
  } = props

  useEffect(() => {
    // This will only happen in preview mode (when domain isn't available in getStaticPaths)
    const currentDomain = props.countryProps.assignedDomain;
    const countryCode = props.countryProps.countryCode;

    const mainSite = process.env.DEALER_SITE === true ? false : true;

    if (!process.env.PREVIEW_MODE) {
      return;
    }

    if (
      // if props assignedDomains.length === 0 & main site
      // or
      // countryProps assignedDomains != props assigned domain
      // or
      // no assignedDomain props & meta assigned domain != countryProps.assignedDomain
      // or
      // dealer site & props.assignedDomains & props assignedDomains country code + -dealers = -1 & dealerSiteBlankTemplates != router path
      props.assignedDomains && (mainSite && (props.assignedDomains.length === 0 ||
        (currentDomain.length === 1 && props.assignedDomains.indexOf(currentDomain[0]) === -1) ||
        (!props.assignedDomains && (get(props, 'meta.assignedDomains', []).indexOf(currentDomain) === -1)))) ||
      (!mainSite && (props.assignedDomains && props.assignedDomains.indexOf(`${countryCode.toUpperCase()}-dealers`) === -1) && (dealerSiteBlankTemplates.indexOf(props.router.asPath.replace('/', '')) === -1))
    ) {
      console.log('props.assignedDomains', props)
      console.log('currentDomain', currentDomain)
      console.log('countryCode', countryCode)

      // To prevent any slow routing on development
      process.env.NODE_ENV === 'development' ?
        window.location.href = '/404'
        :
        Router.push('/404')
    }
  }, []);


  

  const template = () => {
    if (props.router.asPath.indexOf('/stock') !== -1) {
      return <Stock {...props} />
    }

    if (props.router.asPath === '/find-a-service-centre/listing') {
      return <ListingPage {...props} />
    }

    switch (props.router.asPath.split('?')[0]) {
      case '/find-a-dealer':
        return <FindADealer {...props} />
        break;
      case '/find-a-service-centre':
        return <FindAServiceCentre {...props} />
        break;
      case '/ambassadors':
        return <Ambassadors {...props} />
        break;
      case '/careers':
        return <Careers {...props} />
        break;
      case '/owners-clubs':
        return <OwnersClubs {...props} />
        break;
      case '/contact-us':
        return <ContactUs {...props} />
        break;
      case '/staticly-template-contact-page':
        return <ContactUs {...props} />
        break;
      case '/staticly-template-basic-page':
        return <ContentBuilder dealerCb={true} />
        break;
      case '/staticly-template-article-category':
        return <CategoryPage />
        break;
      case '/staticly-template-article':
        return <Article />
      case '/for-sale':
        return <ForSaleLanding {...props} />
        break;
      case '/finance':
        return (
          <div>
            {pageData.length > 2 ?
              <ContentBuilder
                uri={props.uri}
                data={financeAddfirstTwoElem}
                countryProps={props.countryProps}
              />
              :
              <ContentBuilder
                uri={props.uri}
                data={pageData}
              />
            }
            { props.applyNowIframe &&
                <ApplyNowIframe ctaText={props.applyNowIframe.ctaText && props.applyNowIframe.ctaText} iframeCode={props.applyNowIframe.iframeCode && props.applyNowIframe.iframeCode} />
            }
            {
              pageData.length > 2 &&
              <ContentBuilder
                uri={props.uri}
                data={financeRemoveFirstTwoElem}
                countryProps={props.countryProps}
              />
            }
            {props.disclaimers &&
              <DisclaimerWrapper>
                <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
                  <Grid item xs={12}>
                    <Box px={2}>
                      <Disclaimer>
                        {props.disclaimers && ReactHtmlParser(props.disclaimers)}
                      </Disclaimer>
                    </Box>
                  </Grid>
                </Grid>
              </DisclaimerWrapper>
            }
          </div>
        )
        break;
      default:
        return (
          pageData &&
          <div>
            <ContentBuilder
              data={pageData}
              uri={props.uri}
            />
            {props.router.asPath === '/jayco-service-booking' &&
              <JaycoServiceBooking
                countryProps={props.countryProps}
              />
            }

            {props.router.asPath === '/jayco-service-booking/thank-you' &&
              <JaycoServiceBookingThankyou
                countryProps={props.countryProps}
              />
            }

            {props.disclaimers &&
              <DisclaimerWrapper>
                <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
                  <Grid item xs={12}>
                    <Box px={2}>
                      <Disclaimer>
                        {props.disclaimers && ReactHtmlParser(props.disclaimers)}
                      </Disclaimer>
                    </Box>
                  </Grid>
                </Grid>
              </DisclaimerWrapper>
            }
          </div>
        )
    }
  }

  return (
    <>
      {template()}
    </>
  )
}

const getPath = (url) => {
  let slashReg = new RegExp('\/$');
  var pathNameRegex = /^(?:(?:\w{3,5}:)?\/\/[^\/]+)?(?:\/|^)((?:[^#\.\/:?\n\r]+\/?)+(?=\?|#|$|\.|\/))/;
  var matches = url.match(pathNameRegex);
  return matches ? matches[matches.length - 1].replace(slashReg, '') : null;
}

const getAllRestPages = async () => {
  let restResultPerPage = 100;
  let max = 10; // max of 1000 results

  const cms = process.env.CMS || 'cms.jayco-com-au.staticlylocal.com'
  var pages = `https://${cms}/wp-json/wp/v2/pages?per_page=${restResultPerPage}`;
  var pagesArr = [];

  for (var i = 1; i < max; i++) {
    var pagesURL = `${pages}&page=${i}`;
    var res = await fetch(pagesURL);
    var resdata = await res.json();
    if (resdata.data && resdata.data.status && resdata.data.status === 400) {
      break;
    }
    pagesArr = pagesArr.concat(resdata);
  }

  return pagesArr;
}

const fetchData = async (slug) => {
  const cms = process.env.CMS || 'cms.jayco-com-au.staticlylocal.com'
  var page = `https://${cms}/wp-json/wp/v2/pages?slug=${slug}`;
  var res = await fetch(page);
  var resdata = await res.json();
  return resdata && resdata[0] ? resdata[0] : null // && resdata.data[0] ? resdata.data[0] : null;
}

export async function getStaticPaths() {

  const mainSite = process.env.DEALER_SITE === true ? false : true;

  // First get all pages
  const pagesArr = await getAllRestPages();

  let paths = process.env.DEALER_SITE ? [...dealerSiteBlankTemplates.map(item => {
    return {
      params: {
        id: item.split('/'),
      },
    }
  })] : [];

  // if (!mainSite) {
  //   paths.push({
  //     params: {
  //       // id: path.split('/'),
  //       id: ['stock', '*'],
  //       // pageID: item.id
  //     },
  //   })
  // }

  const country = process.env.DEV_COUNTRY_CODE ? process.env.DEV_COUNTRY_CODE.toLowerCase() : process.env.COUNTRY_CODE.toLowerCase();
  // const domain = process.env.DEV_ASSIGNED_DOMAIN ? process.env.DEV_ASSIGNED_DOMAIN : (process.env.PREVIEW_MODE === 'true' ? null : process.env.ASSIGNED_DOMAIN.toLowerCase());
  const domain = process.env.DEV_ASSIGNED_DOMAIN ? process.env.DEV_ASSIGNED_DOMAIN : process.env.ASSIGNED_DOMAIN.toLowerCase()

  // console.log('ddd2', domain, process.env.ASSIGNED_DOMAIN)

  pagesArr.forEach(item => {
    const path = getPath(item.link);
    const assignedDomain = get(item, 'acf.assigned_domains', []);
    if (path) {
      const countryPath = path.replace(`-cc${country}`, '').replace(`-${country}-dealers`, '');
      // if ((pathsToDisclude.indexOf(countryPath) === -1 && (domain ? ((mainSite && assignedDomain.indexOf(domain) !== -1) || (!mainSite && assignedDomain.indexOf(`${domain.toUpperCase()}-dealers`) !== -1)) : true))
      if ((pathsToDisclude.indexOf(countryPath) === -1)
        // If dealer site & countryPath is not in mainOnlyPath array
        && (
          (
            // dealersite && 
            (!mainSite && ((dealerSitePaths.indexOf(countryPath) !== -1) || (assignedDomain.indexOf(`${country.toUpperCase()}-dealers`) !== -1))))
          ||
          (mainSite && (mainSitePaths.indexOf(countryPath) !== -1 || (domain ? assignedDomain.indexOf(domain) !== -1 : true))
          )
        )
      ) {
        paths.push({
          params: {
            // id: path.split('/'),
            id: countryPath.split('/'),
            // pageID: item.id
          },
        })
      }
    }
  });

  const mainSiteAuPaths = (domain === 'jayco.com.au') ? [{
    params: {
      id: ['for-sale']
    }
  }] : null

  const allPaths = mainSiteAuPaths ? [...paths, ...mainSiteAuPaths] : paths;

  console.log('allPaths', JSON.stringify(allPaths), country);

  return { paths: allPaths, fallback: false }
}

export async function getStaticProps(context) {

  const country = process.env.DEV_COUNTRY_CODE ? process.env.DEV_COUNTRY_CODE.toLowerCase() : process.env.COUNTRY_CODE.toLowerCase();
  const domain = country === 'au' ? 'jayco.com.au' : 'jayco.co.nz';
  const otherCountry = country === 'au' ? 'nz' : 'au'

  const {
    params
  } = context;

  const pagesArr = await getAllRestPages();

  const pageID = pagesArr.find(item => {
    const path = getPath(item.link);
    return path && path.replace(`-cc${country}`, '').replace(`-${country}-dealers`, '') === params.id.join('/');
  });

  const GET_PAGE_QUERY = gql`
    query GET_PAGE($uri: String, $otherUri: String) {
      pageBy(uri: $uri) {
        title
        seo {
          ${seoQuery}
        }
        acf_domains {
          assignedDomains
        }
        ${contentBuilderQuery()}
        acf_page_disclaimer {
          pageDisclaimerRef {
            ... on Disclaimer {
              id
              acf_disclaimer {
                disclaimerText
              }
            }
          }
        }
        acf_apply_now_iframe {
          ctaText
          iframeCode
        }
      }
      otherPageBy: pageBy(uri: $otherUri) {
        id
      }
    }
  `

  const uri = pageID ? getPath(pageID.slug).replace('-ccau', '').replace('-ccnz', '').replace(`-au-dealers`, '').replace(`-nz-dealers`, '') : null // slug here, not link // .replace(`-cc${country}`, '')

  var query = GET_PAGE_QUERY

  // 1 - Main Site
  if (params.id[0] === 'find-a-dealer') {
    query = gql`${findADealerQuery(country)}`
  }
  if (params.id[0] === 'find-a-service-centre') {
    if (typeof params.id[1] != 'undefined' && params.id[1] == 'listing') {
      query = gql`${findAServiceAgentListingQuery(country)}`
    } else {
      query = gql`${findAServiceCentreQuery(country)}`
    }
  }
  if (params.id[0] === 'ambassadors') {
    query = gql`${ambassadorsQuery(country)}`
  }
  if (params.id[0] === 'careers') {
    query = gql`${careersQuery(country)}`
  }
  if (params.id[0] === 'owners-clubs') {
    query = gql`${ownersClubQuery(country)}`
  }

  if (params.id[0] === 'for-sale') {
    query = gql`${forSaleLandingPageQuery()}`
  }

  // 1 - Dealer Site
  if (params.id[0] === 'contact-us') {
    query = 'contact-us'
  }

  if (params.id[0] === 'stock') {
    query = gql`${stockQuery(country)}`
  }

  var stockResult = null

  // if (params.id[0] === 'stock') {
  //   query = 'stock'

  //   // /stock url
  //   if (params.id.length === 1) {
  //     stockResult = await client.query({
  //       query: gql`${stockQuery(country)}`
  //     })
  //   } else {
  //     // /stock/[model]
  //   }
  // }

  const uriQuery = process.env.DEALER_SITE ? `${uri}?${country}-dealer` : `${uri}?${country}`;
  const otherUriQuery = process.env.DEALER_SITE ? `${uri}?${otherCountry}-dealer` : `${uri}?${otherCountry}`;

  const result = dealerSitePaths.indexOf(params.id[0]) === -1 ? await client.query({
    query,
    variables: {
      uri: uriQuery,
      otherUri: otherUriQuery
      // id: pageID && parseInt(pageID.id)
    }
  }) :
    await fetchData(query);

  var props
  // 2
  if (params.id[0] === 'find-a-dealer') {
    props = await findADealerRestructure(result, country);
  } else if (params.id[0] === 'find-a-service-centre') {
    if (typeof params.id[1] != 'undefined' && params.id[1] == 'listing') {
      props = await findAServiceCentreListingRefactor(result, country);
    } else {
      props = await findAServiceCentreRefactor(result, country);
    }
  } else if (params.id[0] === 'ambassadors') {
    props = await ambassadorsRestructure(result, domain);
  } else if (params.id[0] === 'careers') {
    props = await careersRestructure(result, domain);
  } else if (params.id[0] === 'owners-clubs') {
    props = await ownersClubsRestructure(result, domain);
  } else if (params.id[0] === 'contact-us') {
    props = basicRestructure(result)
  } else if (params.id[0] === 'stock') {
    props = stockRestructure(result, country)
  } else if (params.id[0] === 'for-sale') {
    props = await forSaleLandingPage(result);
  }

  else {
    props = await refactorCatchAll(result, uri)
  }

  return {
    props: {
      ...props
    }
  }
}

const refactorCatchAll = async (result, uri, handlebars) => {
  const data = result && result.data && result.data.pageBy ? await refactorContentBuilderData(result.data.pageBy, null, null, handlebars) : null;
  const financeAddfirstTwoElem = data && data.length > 2 && data.slice(0, 2);
  const financeRemoveFirstTwoElem = data && data.length > 2 && data.map(function (value, index) { return index != 0 && index != 1 && value });
  const pageBy = data ? get(result, 'data.pageBy', {}) : {};
  const disclaimerVals = get(result, 'data.pageBy.acf_page_disclaimer.pageDisclaimerRef', null);
  const applyNowIframe = get(result, 'data.pageBy.acf_apply_now_iframe', null);

  const disclaimers = disclaimerVals ? disclaimerVals.map(item => {
    return get(item, 'acf_disclaimer.disclaimerText', '')
  }).reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  }) : null

  const assignedDomains = get(pageBy, 'acf_domains.assignedDomains', []);
  const showHreflang = result && result.data.otherPageBy ? true : false
  const breadcrumbs = uri ? await refactorBreadcrumbsData(uri, assignedDomains) : null;

  // Pass post data to the page via props
  return {
    // props: {
    meta: {
      title: pageBy.title || null,
      seo: pageBy.seo || null,
      showHreflang
    },
    disclaimers,
    applyNowIframe,
    result,
    pageData: data,
    financeAddfirstTwoElem: financeAddfirstTwoElem,
    financeRemoveFirstTwoElem: financeRemoveFirstTwoElem,
    uri,
    assignedDomains,
    breadcrumbs
    // }
  }
}

export default ContentBuilderTemplate
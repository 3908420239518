import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {get} from 'lodash';
import Router from 'next/router';
import {Loader} from 'google-maps';
import {Grid, Box} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import * as geolib from 'geolib';
import { fitBounds } from 'google-map-react/utils';
import qs from 'qs'

import {theme} from '../../../src/themes/default-theme';
import ReactHtmlParser from 'react-html-parser';

import {getTopCoordinates} from '../../../src/util/getClosestCoodinate';
import getClosestDealersByPostcode from '../../../src/util/getClosestDealersByPostcode';
import getFallbackStateAus from '../../../src/util/getFallbackStateAus';
import formatAddress from '../../../src/util/formatDealerAddress';
import postcodeRefactor from '../../../src/util/postcodeRefactor';

// components
import Banner from '../../../src/components/multimedia/Banner';
import BannerSlider from '../../../src/components/multimedia/BannerSlider';
import GetLocation from '../../../src/containers/GetLocation';
import BodyText from '../../../src/components/content/BodyText';
import {Links, Cta} from '../../../src/components/ui';
import MapPin from '../../../src/components/ui/MapPin';
import Text from '../../../src/components/typography';
import NextLink from '../../../src/components/navigation/NextLink';

// icons
import chevronLeft from '../../../src/assets/icons/chevron-left-white.svg';

// other 
import googleMapStyle from '../../../src/themes/google-map.json';

const Container = styled.div`
  margin: 35px 0;
  ${theme.breakpoints.up('md')} {
    margin: 55px 0;
  }
  ${theme.breakpoints.up('lg')} {
    margin: 90px 0;
  }
`

const GridOrder = styled(props => {
  return <Grid {...props} lgorder={undefined}/>
})`
  ${theme.breakpoints.up("lg")} {
    order: ${props => props.lgorder};
  }
`

const BodyTextContainer = styled.div`
  max-width: 780px;
  margin: 35px auto 0;
  ${theme.breakpoints.up('md')} {
    margin: 55px auto 0;
  }
  ${theme.breakpoints.up('lg')} {
    margin: 110px auto 0;
  }
`

const MapTitle = styled.div`
  h4 {
    margin-bottom: 20px;
  }
  ${theme.breakpoints.up('lg')} {
    h4 {
      display: none;
    }
  }
`

const GoogleMapContainer = styled.div`
  width: 100%;
  height: 268px;
  ${theme.breakpoints.up('md')} {
    height: 400px;
  }
  ${theme.breakpoints.up('lg')} {
    height: 700px;
    padding-left: 30px;
  }
`

const GoogleMapBorder = styled.div`
  border-top-left-radius: 40px;
  overflow: hidden;
  height: 100%;
  > div {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  ${props => !props.display && `
    background: ${theme.palette.lightGrey};
    > div {
      opacity: 0 !important;
    }
  `}
  ${theme.breakpoints.up('md')} {
    border-top-left-radius: 50px;
  }
  ${theme.breakpoints.up('lg')} {
    border-top-left-radius: 80px;
  }
`

const FormBox = styled.div`
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 40px 15px;
  background-color: ${theme.palette.lightGrey};
  border-radius: 0;
  text-align: center;
  ${theme.breakpoints.up('md')} {
    padding: 50px 40px;
    border-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${theme.breakpoints.up('lg')} {
    max-width: 550px;
    padding: 50px;
    border-radius: 0;
  }
`

const Form = styled.div`
  margin-top: 25px;
`

const Inputs = styled.div`
  max-width: 450px;
  > div:last-child {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  margin: 15px auto 0;
  ${theme.breakpoints.up('md')} {
    margin: 20px auto 0;
  }
  ${theme.breakpoints.up('lg')} {
    margin: 30px auto 0;
  }
`

const ReturnLink = styled.div`
	margin: 20px auto 25px;
	padding-right: 20px; 
  max-width: 610px;
  text-align: left;
  padding: 0 ${theme.containers.main.gutter};
  ${theme.breakpoints.up('md')} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  ${theme.breakpoints.up('lg')} {
    max-width: 100%;
    margin-bottom: 60px;
  }
` 

const Results = styled.div `
  margin-top: 20px;
  h4 {
    display: none;
  }
  ${theme.breakpoints.up('lg')} {
    margin-top: 30px;
    h4 {
      display: block;
    }
  }
`

const ResultsList = styled.div `
  
`

const ResultsListItem = styled.div `
  margin: 20px 0;
  padding: 20px 0 10px;
  border-top: 1px solid ${theme.palette.lightGrey};
  &:last-child {
    border-bottom: 1px solid ${theme.palette.lightGrey};
    padding-bottom: 30px;
  }
  h6 {
    margin-bottom: 13px;
    span {
      color: ${theme.palette.skyBlue};
    }
  }
`

const ResultsCta = styled.div`
  margin-top: 20px;
`

const ResContainer = styled.div`
  margin-bottom: 40px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 60px;
  }
  ${theme.breakpoints.up('lg')} {
    margin-bottom: 80px;
  }
`
const CtaContainer = styled.div`
  margin: 35px auto auto;
  max-width: 265px;
  a, span {
    text-decoration: none;
  }
`

const FindAServiceCentre = (props) => {

  const dealerUri = '/dealer/'
  // const countryCode = props.countryProps.countryCode;
  const [geocoder, setGeocoder] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(15);
  const googleMapKey = process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDvDPE1nXC0ejI_nWsFYf37isGY_XfJCp4';
  const mapRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);

  const {
    dealers,
  } = props

  const currentCountry = props.countryProps.countryCode === 'AU' ? 'Australia' : 'New Zealand';

  useEffect(() => {
    const asyncLoad = async () => {
      setLoading(true);
      const loader = new Loader(process.env.GOOGLE_MAPS_API_KEY, {});
      const google = await loader.load();
      setGeocoder(new google.maps.Geocoder())
      setLoading(false);
    }
    asyncLoad()
  }, [])

  useEffect(() => {
    if (locations) {

      const boundsVals = geolib.getBounds(locations.map(item => {
        return {
          latitude: item.latitude,
          longitude: item.longitude
        }
      }));

      const boundsObj = {
        ne: {
          lat: boundsVals.maxLat,
          lng: boundsVals.maxLng,
        },
        sw: {
          lat: boundsVals.minLat,
          lng: boundsVals.minLng,
        }
      };

      const size = {
        width:  mapLoaded ? mapRef.current.offsetWidth - 52 : 0, // Map width in pixels - pin width
        height: mapLoaded ? mapRef.current.offsetHeight - 73 : 0, // Map height in pixels - pin height
      };

      const {center, zoom} = fitBounds(boundsObj, size);

      center && setCenter(center);
      zoom && setZoom(zoom);

    }
  }, [locations, mapLoaded]);

  useEffect(() => {
    if (geocoder) {
      const coordinatesQuery = get(props, 'router.query["coordinates[latitude]"]', null) && get(props, 'router.query["coordinates[longitude]"]', null) ? true : false;
      const postcodeQuery = get(props, 'router.query.postcode', null);

      if (coordinatesQuery) {
        setCoordinates({
          latitude: props.router.query['coordinates[latitude]'],
          longitude: props.router.query['coordinates[longitude]']
        });
        
      } else if (postcodeQuery && postcodeQuery.length === 4) {
        setPostcode(postcodeQuery);
      }
    }
  }, [geocoder])

  useEffect(() => {
    if (postcode) {
      getDealerFromPostcode(postcode);
      return
    }
    if (coordinates) {
      getDealerFromLocation(coordinates)
    }
  },[postcode, coordinates] )

  const getFallbackAus = (post_code) => {
    return getFallbackStateAus(post_code, dealers);
  }

  const getFallbackNz = (post_code) => {
    const postcode = parseInt(post_code);
    const closestDealer = getClosestDealersByPostcode(postcode, dealers, 1);
    return closestDealer[0]
  }

  const getDealerFromPostcode = (_postcode) => {

    let postcode = _postcode;

    const postcodeFromTo = postcodeRefactor

    postcodeFromTo.forEach(item => {
      if (item.from === _postcode.toString()) {
        postcode = item.to
      }
    })

    const country = props.countryProps.countryCode === 'AU' ? 'Australia' : 'New Zealand';
    const fallback = get(dealers[0], 'acf_dealers.country') === 'Australia' ? getFallbackAus(postcode) : getFallbackNz(postcode);

    geocoder.geocode( { 'address': 'postalcode+' + postcode + country},
    (results, status) => {
      if (status == 'OK') {
        const latFunc = get(results, '[0].geometry.location.lat', null)
        const lngFunc = get(results, '[0].geometry.location.lng', null)

        if (latFunc && lngFunc) {
          const latLng = {
            latitude: latFunc(),
            longitude: lngFunc()
          }
          const closest = getTopCoordinates(latLng, dealers.map(item => {
            return {
              ...item,
              longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
              latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
            }
          }), 3);

          console.log('closest', closest, results, postcode + country, latLng)

          setLocations(closest);

          if (closest) {
            setLocations(closest);
          } else {
            fallback && fallback.uri && Router.push(dealerUri + fallback.uri + '?service').then(() => window.scrollTo(0, 0));
          }

          // if (closest && closest.item && closest.item.uri) {
          //   Router.push(dealerUri + closest.item.uri + '?service');
          // } else {
          //   fallback && fallback.uri && Router.push(dealerUri + fallback.uri + '?service');
          // }
        }
      } else {
        fallback && fallback.uri && Router.push(dealerUri + fallback.uri + '?service').then(() => window.scrollTo(0, 0));
      }
    });
  }

  const getDealerFromLocation = (location) => {
    const closest = getTopCoordinates(location, dealers.map(item => {
      return {
        ...item,
        longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
        latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
      }
    }), 3);

    if (closest) {
      setLocations(closest);
    } else {
      fallback && fallback.uri && Router.push(dealerUri + fallback.uri + '?service').then(() => window.scrollTo(0, 0));
    }

    // if (closest && closest.item && closest.item.uri) {
    //   Router.push(dealerUri + closest.item.uri + '?service');
    // } else {
    //   const fallback = get(dealers[0], 'acf_dealers.country') === 'Australia' ? getFallbackAus(postcode) : getFallbackNz(postcode);
    //   fallback && fallback.uri && Router.push(dealerUri + fallback.uri + '?service');
    // }
  }

  const handleBackClick = (e) => {
    e && e.preventDefault();
    window.history.replaceState(null, null, '');
    setLocations(null);
  }

  const handleSubmit = (location, type) => {
    if (type === 'postcode') {
      const postcode = get(location, 'postcode', null);
      postcode && getDealerFromPostcode(postcode);
      window.history.replaceState(null, null, `?postcode=${postcode}`);

    } else {
      getDealerFromLocation({...location, postcode: undefined});
      window.history.replaceState(null, null, `?${qs.stringify({coordinates: {...location, postcode: undefined}})}`);
    }

  }
  const alpha = ['A', 'B', 'C'];
  const postcodeQuery = get(props, 'router.query.postcode', null);

  return (
  	<div>
      {props.heroBanner &&
        <Banner
          {...props.heroBanner}
          largePaddingTop={true}
        />
      }
      {props.heroBannerSlider &&
        <BannerSlider
          {...props.heroBannerSlider}
        />
      }
      
        <ResContainer style={{display: locations && center ? 'block' : 'none'}}>
          <Grid container spacing={0} direction={'row'} align={'right'} className={'hasPadding'}>
            <Grid item xs={12}>
              <ReturnLink>
                <Links href={'#'} svg={chevronLeft} onClick={(e) => handleBackClick(e)}>
                  Go back
                </Links>
              </ReturnLink>
            </Grid>
          </Grid>
          <Grid container className={'hasPadding'} spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
            <GridOrder item xs={12} lg={7} lgorder="2">
              <Box px={2}>
                <MapTitle>
                  <Text variant="h4" colour="black">Your nearest Service Centres</Text>
                </MapTitle>

                <GoogleMapContainer key={zoom}>
                  <GoogleMapBorder ref={mapRef} display={mapLoaded}>
                    {locations &&
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: googleMapKey }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                        options={{
                          styles: googleMapStyle
                        }}
                        onTilesLoaded={() => setMapLoaded(true)}
                        >
                        {locations.map((item, index) => {
                          return (
                            <MapPin
                              key={index}
                              alpha={alpha[index]}
                              lat={item.latitude}
                              lng={item.longitude}
                            />
                          )
                        })}

                      </GoogleMapReact>
                    }
                  </GoogleMapBorder>
                </GoogleMapContainer>
              </Box>
            </GridOrder>
            <GridOrder item xs={12} lg={5} lgorder="1">
              <Box px={2}>
                <Results>
                  <Text variant="h4" colour="black">Your nearest Service Centres</Text>
                  <ResultsList>
                    {locations && locations.map((item, index) => {
                      return (
                        <ResultsListItem key={item.uri || index}>
                          <Text variant="h6" colour="black"><span>{alpha[index]}.</span> {ReactHtmlParser(item.title)}</Text>
                          <Text variant="body1" colour="black">
                            {ReactHtmlParser(formatAddress(item.acf_dealers, true))}
                          </Text>
                          <ResultsCta>
                            <NextLink href={`/dealer/${item.uri}?service`}>
                              <a>
                                <Cta component="span" inline={true}>
                                  Find out More
                                </Cta>
                              </a>
                            </NextLink>
                          </ResultsCta>
                        </ResultsListItem>
                      )
                    })}
                  </ResultsList>
                </Results>
              </Box>
            </GridOrder>
          </Grid>
        </ResContainer>

      {!locations &&
      <>
        {props.bodyText &&
          <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
            <Grid item xs={12}>
              <BodyTextContainer>
                <BodyText 
                  {...props.bodyText}
                  headerType="h4"
                  align="center"
                />
              </BodyTextContainer>
            </Grid>
          </Grid>
        }
        <Container>
          <FormBox>
            <Form>
              <Inputs>
                <GetLocation 
                  onSubmit={handleSubmit}
                  postcode={postcodeQuery ? postcodeQuery.toString() : ''}
                />
              </Inputs>
            </Form>
          </FormBox>
          {currentCountry && currentCountry == 'Australia' &&
            <CtaContainer>
              <NextLink href={`/find-a-service-centre/listing`}>
                <a>
                  <Cta component="span" outline>
                    View all Service Agents
                  </Cta>
                </a>
              </NextLink>
            </CtaContainer>
          }   
        </Container>
      </>
      }
    </div>
  )
}
  
FindAServiceCentre.propTypes = {
}

FindAServiceCentre.defaultProps = {
}

export default FindAServiceCentre
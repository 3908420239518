import React, {useState, useEffect} from 'react';
import {get} from 'lodash';
import {Grid, Box} from '@material-ui/core';
import {theme} from '../../themes/default-theme';
import styled from 'styled-components';

import Banner from '../../components/multimedia/Banner';
import {TabsMobileDropdown} from '../../components/ui';
import CommunityContainer from '../Community/CommunityContainer';

const TopWrapper = styled.div`
  background-color: ${theme.palette.lightGrey};
  padding: 30px 0;

  ${theme.breakpoints.up('md')} {
    padding: 60px 0;
  }
  ${theme.breakpoints.up('lg')} {
    padding: 120px 0;
	}

`

const Title = styled.div`
	font-size: 15px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: ${theme.palette.charcoal};
	margin: 0 auto;

	${theme.breakpoints.up('md')} {
		font-size: 24px;
		max-width: 780px;
	}
	${theme.breakpoints.down('sm')} {
		padding-left: 15px;
		padding-right: 15px;
	}
`

const Body = styled.div`
	font-size: 14px;
	text-align: center;
	margin: 0 auto;
	color: ${theme.palette.gunmentalGrey};

	${theme.breakpoints.up('md')} {
		font-size: 15px;
		max-width: 780px;
	}
	${theme.breakpoints.down('sm')} {
		padding-left: 15px;
		padding-right: 15px;
	}
`

const TabsContainer = styled.div`
  margin-top: 44px;
  margin-bottom: 50px;
  padding: 0 16px;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`

const NoPositions = styled.div`
	text-align: center;
	font-size: 15px;
	
	${theme.breakpoints.down('sm')} {
		padding-left: 15px;
		padding-right: 15px;
  }
`

const Careers = (props) => {
	const width = props.width;

	// const jobCategories = ['Manufacturing', 'Clerical', 'Human Resources', 'Customer Service', 'Supply chain', 'Research and Development', 'Planning', 'Marketing', 'I.T.'];
	
	const [activeTab, setActiveTab] = useState(props.filteredCategories ? props.filteredCategories[0] : null);
	const [activeContainer, setActiveContainer] = useState(props.filteredCategories ? props.jobConversion.filter(job => job.positionType === props.filteredCategories[0]): null);

  useEffect(() => {
    if (props.filteredCategories && props.filteredCategories[0]) {
      setActiveTab(props.filteredCategories[0]);
      if (props.jobConversion) {
        setActiveContainer(props.jobConversion.filter(job => job.positionType === props.filteredCategories[0]));
      }
    }
  }, [props.filteredCategories, props.jobConversion]);

  const updateTab = (e) => {
		e.text && setActiveTab(e.text);
		e.text && setActiveContainer(props.jobConversion.filter(job => job.positionType === e.text));
  }

  return (
		<div>
			{props.heroBanner &&
				<Banner
					{...props.heroBanner}
					type="mediumBanner"
				/>
      }
			<TopWrapper>
				<Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
					<Grid item xs={12}>
						{(props.title || props.body) &&
							<Box px={2}>
								<Title dangerouslySetInnerHTML={{ __html: props.title}} />
								<Body dangerouslySetInnerHTML={{ __html: props.body }} />
							</Box>
						}
					</Grid>
				</Grid>
				{props.filteredCategories && props.filteredCategories.length > 1 &&
					<TabsContainer>
						<TabsMobileDropdown
							active={activeTab}
							items={props.filteredCategories.map(item => {return {text: item}})}
							maxWidth={false}
							tabGap={false}
							small={false}
							onChange={(e) => updateTab(e)}
						/>
					</TabsContainer>
				}
				{activeContainer && activeContainer.length > 0 ?
					<CommunityContainer
						articles={activeContainer}
						path={'/careers/jobs/'}
					/>
					:
					<NoPositions>There are currently no positions open.</NoPositions>
				}
			</TopWrapper>
		</div>
  )
}

export default Careers;
import React, {useState, useRef, useEffect} from 'react';
import {get} from 'lodash';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import {useForm} from "react-hook-form";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Router from 'next/router';

import Topo from '../../../../src/assets/topo-block-blue.png'
import Button from '../../../../src/components/ui/Cta';
import Text from '../../../../src/components/typography';
import {theme} from '../../../../src/themes/default-theme';

// components
import TabSelect from '../../../../src/components/forms/TabSelect';

// steps
import Step1 from '../../../../src/containers/Forms/JaycoServiceBooking/Steps/Step1';
import Step2 from '../../../../src/containers/Forms/JaycoServiceBooking/Steps/Step2';
import Step3 from '../../../../src/containers/Forms/JaycoServiceBooking/Steps/Step3';

import step1Svg from '../../../../src/assets/icons/service-form-icon-1.svg';
import step2Svg from '../../../../src/assets/icons/service-form-icon-2.svg';
import step3Svg from '../../../../src/assets/icons/service-form-icon-4.svg';

const Wrapper = styled.div`

`

const FormWrapper = styled.div `
  background: ${theme.palette.skyGradientLight};
  padding: 20px 16px;
  position: relative;

  ${theme.breakpoints.up('md')} {
    padding: 110px 15px; 60px;

    &:before {
      content: ' ';
      display: block;
      width: 345px;
      height: 224px;
      background-image: url(${Topo});
      position: absolute;
      background-size: 100% auto;
      left: 0;
      right: 0;
      margin: auto;
      z-index: -1;
      top: 50px;
      transform: translateX(-190px);
    }
  }
`

const Container = styled.div`
  background-color: ${theme.palette.white};
  max-width: 570px;
  margin: 0 auto;
  padding: 20px 16px;
  border-bottom-left-radius: 30px;

  ${theme.breakpoints.up('md')} {
    padding: 52px 58px;
    border-bottom-left-radius: 60px;
  }
`

const Form = styled.form`
  text-align: left;
`

const FormItem = styled.div`
  display: block;
  margin:${props => props.margin === 'none' ? `0` : `30px 0`};

`

const Footer = styled.div`
  border-top: 1px solid ${theme.palette.middleGrey};

    ${props => props.marginTop === 1 && `
      margin-top: 30px;
    `
    }
`

const Error = styled.p`
  color: ${theme.palette.error.form}
`

const ButtonContainer = styled.div`
  margin-left: 10px;
  display: inline-block;
  margin-bottom: 10px;
`

const IconContainer = styled.div`
  max-width: 500px;
  margin: 0 auto 50px;

  ${theme.breakpoints.up('md')} {
    margin-top: 80px;
    margin-bottom: 50px;
  }
`

const Step = styled.div `
  ${props => !props.show && `
    display: none;
  `
}`

const JaycoServiceBooking = (props) => {

  const country = props.countryProps.countryCode === 'AU' ? "Australia's" : "New Zealand's";

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [step, setStep] = useState(1);
  const [stepsValid, setStepsValid] = useState({});

  const [tabSelectState, setTabSelectState] = useState([
    {
      icon: step1Svg,
      tick: false,
      id: 1,
    },
    {
      icon: step2Svg,
      tick: false,
      id: 2,
    },
    {
      icon: step3Svg,
      tick: false,
      id: 3,
    }
  ])

  const handleTabChange = async (val) => {

    await handleNext(false);

    // If moving forward
    if (val + 1 && stepsValid[step] === true) {
      setStep(val + 1);
    }

    // If moving back
    if (stepsValid[val + 1] === true) {
      setStep(val + 1);
    }
  }

  useEffect(() => {
    const newTabSelectState = tabSelectState.map((tab, index) => {
      const tick = stepsValid[index + 1] === true ? true : false
      return {
        ...tab,
        tick: tick
      }
    })
    setTabSelectState(newTabSelectState);
  }, [stepsValid])

  useEffect(()=> {
    if (step === 4) {
      const invalidStep = tabSelectState.find(item => item.tick === false);
      if (invalidStep && invalidStep.id !== 4 && invalidStep.id !== 3) {
        setStep(invalidStep.id);
      }
    }
    setGoogleCaptcha(null);
  }, [step])

  const [step1FormState, setStep1FormState] = useState({
    // first_name: 'C',
    // last_name: 'H',
    // email: 'test@test.com',
    // phone: '04040404040',
    // postcode: '3000',
    // chassis: '0'
  });
  const [step2FormState, setStep2FormState] = useState({});
  // const [step3FormState, setStep3FormState] = useState({});
  const [step3FormState, setStep3FormState] = useState({
    newsletter: false,
    privacy: false
  });

  const step1FormProps = useForm();
  const step2FormProps = useForm();
  // const step3FormProps = useForm();
  const step3FormProps = useForm();

  const [googleCaptcha, setGoogleCaptcha] = useState(false);

  const onSubmit = async data => {

    const recaptchaValue = recaptchaRef.current.getValue();

    let refatorDropdowns = {}

    const dealerId = process.env.DEALER_SITE && window.staticly.dealerData.id ? {
      dealer: window.staticly.dealerData.id
    } : {}

    const formData = {
      ...data,
      ...refatorDropdowns,
      'g-recaptcha-response': googleCaptcha,
      ...step1FormState,
      ...step2FormState,
      // ...step3FormState,
      form_id: 'jayco_service_booking',
      ...dealerId
    }

    if (googleCaptcha && recaptchaValue.length > 0 && Object.keys(step3FormProps.errors).length === 0) {
      let formattedData = {};
      let bodyFormData = new FormData();

      Object.keys(formData).forEach(key => {

        if (typeof formData[key] === 'object' && formData[key].value) {
          // For dropdowns
          const value = formData[key].value.toString();
          formattedData[key] = formData[key].value.toString() || ''
          bodyFormData.set(key, value);
          //console.log('dd', formData[key]);
        } else if (typeof formData[key] === 'object' && !formData[key].value && formData[key].length > 0) {
          // For file upload
          formattedData[key] = [];
          for(var i = 0; i < formData[key].length; i++){
            let item = formData[key][i];
            bodyFormData.append('files[' + i + ']', item.file);
            formattedData[key].push({
              path: item.file
            });
          }
        } else {
          // For all others
          //console.log('other', formData[key]);
          formattedData[key] = formData[key].toString() || '';
          bodyFormData.append(key, formData[key].toString() || '');
        }
      })

      setCaptchaError(null);

      const endpoint = props.formAction ? props.formAction : process.env.STATICLY_FORMS_API_ENDPOINT;

      setSending(true);
      const thankyou = '/jayco-service-booking/thank-you';

      axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        console.log('response:', response);
        process.env.NODE_ENV === 'development' ?
          window.location.href = thankyou
        :
        Router.push(thankyou);
      })
      .catch((error) => {
        console.log('Form error: ', error);
        setSending(false);
        setError('Something went wrong. Please try again later.')
      });

    } else {
      setCaptchaError('Google captcha not complete');
      setSending(false)
    }
  }

  const handleNext = async (moveToStep) => {
    if (step === 1) {
      await step1FormProps.triggerValidation();
      setStep1FormState(step1FormProps.getValues());
      if (Object.keys(step1FormProps.errors).length === 0) {
         moveToStep !== false && setStep(2);
        setStepsValid({
          ...stepsValid,
          1: true
        })
      } else {
        setStepsValid({
          ...stepsValid,
          1: false
        })
      }
    }
    if (step === 2) {
      await step2FormProps.triggerValidation();
      setStep2FormState(step2FormProps.getValues());
      if (Object.keys(step2FormProps.errors).length === 0) {
        moveToStep !== false && setStep(3);
        setStepsValid({
          ...stepsValid,
          2: true
        })
      } else {
        setStepsValid({
          ...stepsValid,
          2: false
        })
      }
    }
    handleScroll();
    // if (step === 3) {
    //   // Time bug work around
    //   const time = step3FormProps.getValues().time || step3FormState.time || null // '9:00am';
    //   const date = step3FormProps.getValues().date || step3FormState.date || null // '9:00am';
    //   setStep3FormState({
    //     ...step3FormState, 
    //     ...step3FormProps.getValues(),
    //     time: time,
    //     date
    //   });
    //   step3FormProps.setValue("time", time)
    //   step3FormProps.setValue("date", date)
    //   await step3FormProps.triggerValidation();
    //   if (Object.keys(step3FormProps.errors).length === 0) {
    //     moveToStep !== false && setStep(4);
    //     setStepsValid({
    //       ...stepsValid,
    //       3: true
    //     })
    //   } else {
    //     setStepsValid({
    //       ...stepsValid,
    //       3: false
    //     })
    //   }
    // }
  }

  const handleChange = async () => {

    if (step === 1) {
      if (Object.keys(step1FormProps.errors).length > 0) {
        await step1FormProps.triggerValidation();
      }
      setStep1FormState(step1FormProps.getValues());
    }

    if (step === 2) {
      if (Object.keys(step2FormProps.errors).length > 0) {
        await step2FormProps.triggerValidation();
      }
      setStep2FormState(step2FormProps.getValues());
    }
    if (step === 3) {
      if (Object.keys(step3FormProps.errors).length > 0) {
        await step3FormProps.triggerValidation();
      }
      setStep3FormState(step3FormProps.getValues());
    }
  }

  const handleScroll = () => {
    const { top } = containerRef.current.getBoundingClientRect();
    const position = top + window.pageYOffset
    position ? window.scrollTo({
      top: position,
      behavior: 'smooth'
    }) : containerRef.current.scrollIntoView({ behavior: 'smooth'});
  }

  const handlePrev = () => {
    if (step === 1) {
      setStep1FormState(step1FormProps.getValues());
    }

    if (step === 2) {
      setStep2FormState(step2FormProps.getValues());
    }

    if (step === 3) {
      setStep3FormState(step3FormProps.getValues());
    }

    setStep(step - 1);
    handleScroll();
  }

  const recaptchaRef = useRef();
  const containerRef = useRef();

  return (

    <>
  	<Wrapper>

      <IconContainer ref={containerRef}>
        <TabSelect
          data={tabSelectState}
          activeIndex={step - 1}
          onChange={handleTabChange}
        />
      </IconContainer>

      <FormWrapper>
        <Container>

          <Form noValidate autoComplete="off" onChange={handleChange}>
              
              {/* default val in step 1 select & react hook forms aren't working so hiding step 1 for now */}
              <Step show={step === 1  ? true : false}>
                <Text variant="h4" uppercase colour="charcoal" align="center">Your details</Text>
                <Step1
                  formProps={step1FormProps} 
                  onFormChange={handleChange} 
                  countryCode={props.countryProps.countryCode} 
                  state={step1FormState} 
                  dealers={props.dealers}
                  />
              </Step>

              {step === 2 &&
                <>
                  <Text variant="h4" uppercase colour="charcoal" align="center">Service details</Text>
                  <Step2 formProps={step2FormProps} onFormChange={handleChange} state={step2FormState}/>
                </>
              }

              {
                step === 3 &&
                <>
                  <Text variant="h4" uppercase colour="charcoal" align="center">Summary</Text>
                  <br />
                  <Text variant="body1" align="center">Your summary is below. Once you submit your request a service representative will be in contact to confirm you booking details.</Text>
                  <Step3 
                    formProps={step3FormProps}
                    onFormChange={handleChange}
                    state={step3FormState}
                    country={country}
                    summary={{
                      step1: step1FormState,
                      // dealerSummary,
                      step2: step2FormState,
                      // step3: step3FormState
                    }}
                    setStep={(step) => setStep(step)}
                  />
                  <FormItem>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                      onChange={setGoogleCaptcha}
                    />
                    {!googleCaptcha && captchaError &&
                      <Error>{captchaError}</Error>
                    }
                    {error &&
                      <Error>{error}</Error>
                    }
                  </FormItem>
                </>
              }

              <Footer marginTop={step === 1 ? 1 : 0 }>
                <FormItem>
                  <Grid container direction={'row'} justify={'space-between'} align={'center'}>
                    <Grid item xs={3}>
                      <Text variant="body2">Mandatory*</Text>
                    </Grid>
                    <Grid item xs={8} align={'right'}>
                      {step !== 1 &&
                        <ButtonContainer>
                          <Button inline outline secondary onClick={handlePrev}>Previous</Button>
                        </ButtonContainer>
                      }
                      {step !== (tabSelectState.length) ?
                        <ButtonContainer>
                          <Button inline onClick={handleNext}>Next</Button>
                        </ButtonContainer>
                        :
                        <ButtonContainer>
                          <Button inline onClick={step3FormProps.handleSubmit(onSubmit)}>{sending ? 'Submitting...'  : 'Submit'}</Button>
                        </ButtonContainer>
                      }
                    </Grid>
                  </Grid>
                </FormItem>
              </Footer>
          </Form>
        </Container>
        
      </FormWrapper>
      
    </Wrapper>
    </>
  )
}
  
JaycoServiceBooking.propTypes = {

}

JaycoServiceBooking.defaultProps = {

}
  
export default JaycoServiceBooking
const closest = (num, array, key, noItems) => {
  var numberOfItems = noItems || array.length
  let closestItems = [];
  let arr = [...array]

  for (var i = 0; i < numberOfItems; i++) {
    var thisClosest = findClosest(num, arr, key);
    closestItems.push(thisClosest);
    arr = arr.filter(item => item.id !== thisClosest.id);
  }
  return closestItems
}

function findClosest(value, array, key) {
  return getClosestValue(array, (item) => {
    return item[key] - value;
  });
}

function getClosestValue(list, getDifference) {
  var smallestDiff = Infinity;
  return list.reduce(function(closestValue, currentValue, index) {
    var newDifference = Math.abs(getDifference(currentValue));
    if (!index) return smallestDiff = newDifference, currentValue;
    return smallestDiff = Math.min(smallestDiff, newDifference), newDifference === smallestDiff ? currentValue : closestValue;
  });
}

export default closest
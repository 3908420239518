import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Slider as MuiSlider, Typography, OutlinedInput} from '@material-ui/core';
import produce from "immer"

import toPrice from '../../../util/toPrice'
import {theme} from '../../../themes/default-theme';

const useStyles = makeStyles({
  root: {
    // width: 'calc(100% - 9px)',
    width: '100%',
  },
  input: {
    maxWidth: 107,
    width: '100%',
    display: 'inline-block',
    margin: '5px 0 10px',
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: theme.palette.middleGrey,
    },
    '&$input input': {
      maxWidth: 61,
    },
    '&.small': {
      maxWidth: 80
    },
    '&.small input': {
      maxWidth: 44
    },
    '&.full-width': {
      maxWidth: '100%'
    },
    '&.full-width input': {
      maxWidth: 'calc(100% - 38px)!important'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  inputPadding: {
    '&$input input': {
      paddingLeft: 12,
      maxWidth: 61,
    }
  },
  to: {
    textTransform: 'none',
    padding: '0 5px',
  },
  toMinWidth: {
    minWidth: 50,
    display: 'block',
    textAlign: 'center'
  },
  before: {
    position: 'absolute',
    left: 15,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 14,
    color: theme.palette.darkGrey,
    [theme.breakpoints.up('md')]: {
      fontSize: 17,
    }
  },
  sliderWrapper: {
    padding: '25px',
    margin: '-25px',
    position: 'relative',
    overflow: 'hidden',
  },
  sliderRoot: {
    height: 12,
    borderRadius: 7,
    // overflow: 'hidden',
  },
  sliderLabel: {
    display: 'none'
  },
  sliderRail: {
    height: 12,
    borderRadius: 7,
    backgroundColor: theme.palette.lightGrey,
    border: `1px solid ${theme.palette.middleGrey}`,
    opacity: 1,

    '&:after': {
      width: '7px',
      height: '12px',
      content: props => props.defaultVals.length === 1 ? "''" : "none",
      position: 'absolute',
      backgroundColor: theme.palette.skyBlue,
      top: '-1px',
      left: '1px',
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
      zIndex: 2,
      border: `1px solid ${theme.palette.middleGrey}`,
      borderRight: 0,
    },

    '&:before': {
      content: props => props.defaultVals.length === 1 ? "''" : "none",
      width: '50px',
      height: '14px',
      position: 'absolute',
      'background-color': props => props.bg ? props.bg : 'white',
      top: '-1px',
      left: '-43px',
      zIndex: 1,
    }

  },
  sliderTrack: {
    height: 12,
    backgroundColor: theme.palette.skyBlue,
    marginTop: 1,
    borderRadius: 7,

    '&:before' : {
      content: props => props.defaultVals.length === 1 ? "''" : "none",
      width: '20000px',
      height: 12,
      display: 'block',
      left: '-20000px',
      position: 'absolute',
      backgroundColor: theme.palette.skyBlue,
    }

  },
  sliderThumb: {
    width: 29,
    height: 29,
    marginTop: '-8px',
    marginLeft: '-15px',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.middleGrey}`,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.16)',
    zIndex: 3,
    // transform: 'translateX(15px)',

    '&:before': {
      width: 11,
      height: 11,
      content: "''",
      display: 'block',
      backgroundColor: theme.palette.charcoal,
      borderRadius: 12,
    },

    '&:hover': {
      boxShadow: '0 0 0 5px rgba(0,0,0,0.03)',
    },
  },
  sliderThumbActive: {
    boxShadow: '0 0 0 10px rgba(0,0,0,0.03) !important',
  },
  afterLabel: {
    position: 'absolute',
    left: 150,
    top: '50%',
    transform: 'translateY(-50%)',

    '&.small-input': {
      left: 100
    }
  },
});


function valuetext(value) {
  return `$${value}`;
}

export default function UISlider(props) {

  const sliderProps = produce(props, draftProps => {
    delete draftProps['type'];
    delete draftProps['defaultVals'];
    delete draftProps['onSliderChange'];
    delete draftProps['afterLabel'];
    delete draftProps['inputSize'];
    delete draftProps['allowDecimals']
  })

  const classes = useStyles(props);
  const [value, setValue] = React.useState(props.defaultVals);

  useEffect(() => {
    setValue(props.defaultVals)
  },[props.defaultVals])

  const handleChange = (event, newValue, update) => {
    const vals = newValue.map(item => {
      return props.allowDecimals ? parseFloat(item) : parseInt(item)
    })

    if (value.length > 1) {
      if (vals[0] >= vals[1]) {
        return
      }
    }

    props.onSliderChange(vals)

    update !== false && setValue(vals);
  };

  const keypressHandler = (e) => {
   if (event.key === "Enter") {
    e.target.blur();
    e.preventDefault();
   }
  }

  const handleInputChange = (e, index, blur) => {

    let val = props.allowDecimals ? e.target.value.replace(/,/g, '') : parseFloat(e.target.value.replace(/,/g, ''));

    val = (isNaN(val) || val < 0) ? 0 : val

    if (val < props.min && blur) {
      handleChange(null, produce(value, draftState => {
        if (value.length > 1) {
          draftState[index] = index === 0 ? props.min : value[0] + 1
        } else {
          draftState[0] = props.min
        }
      }))
      return
    }

    else if (val > props.max && blur) {
      handleChange(null, produce(value, draftState => {
        if (value.length > 1) {
          draftState[index] = index === 1 ? props.max : value[1] - 1
        } else {
          draftState[0] = props.max
        }
      }))
      return
    }

    else { 
      const newVal = produce(value, draftState => {
        if (value.length > 1) {
          if (!blur) {
            draftState[index] = val
          } else {
            if (index === 1 && val <= value[0]) {
              draftState[0] = value[1] - 1
            } 
            if (index === 0 && val >= value[1]) {
              draftState[1] = value[0] + 1
            }
          }
        } else {
          draftState[index] = val
        }
      })
      setValue(newVal);
      handleChange(null, newVal, false);
    }
  }

  return (
    <div className={classes.root}>

      <Typography gutterBottom>
        {props.title}
      </Typography>

      <div className={clsx(classes.row)} >
        {props.defaultVals.map((j, index) => (
          <React.Fragment key={index}>
            {index === 1 && props.defaultVals.length > 1 && 
              <Typography><span className={clsx(classes.to, props.inputSize === 'fullWidth' && classes.toMinWidth)}>to</span></Typography>
            }
            <OutlinedInput
              type="tel"
              variant="outlined"
              className={clsx(classes.input, props.type === 'price' && classes.inputPadding, props.inputSize === 'small' && 'small', props.inputSize === 'fullWidth' && 'full-width')}
              onChange={(e) => handleInputChange(e, index)}
              onBlur={(e) => handleInputChange(e, index, true)}
              onKeyPress={event => keypressHandler(event, index)}
              value={props.type === 'price' ? (toPrice(value[index]) || '') : value[index]}
              startAdornment={props.type === 'price' ? <Typography><span className={classes.before}>$</span></Typography> : ''}
            />
            {props.afterLabel &&
              <div className={clsx(classes.afterLabel, props.inputSize === 'small' && 'small-input')}>
                <Typography>{props.afterLabel}</Typography>  
              </div>
            }
          </React.Fragment>
        ))}
      </div>

      <div className={classes.sliderWrapper}>
        <MuiSlider
          classes={{
            root: classes.sliderRoot, 
            valueLabel: classes.sliderLabel,
            rail: classes.sliderRail,
            track: classes.sliderTrack,
            thumb: classes.sliderThumb,
            active: classes.sliderThumbActive,
          }}
          // value={value.length === 1 ?[0,[value[0]]] : value}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          valueLabelDisplay={'on'}
          {...sliderProps}
        />
      </div>
    </div>
  );
}

UISlider.propTypes = {
  type: propTypes.oneOf(['price', 'regular']),
  defaultVals: propTypes.array,
  onSliderChange: propTypes.func,
  bg: propTypes.string,
  afterLabel: propTypes.string,
  inputSize: propTypes.oneOf(['small', 'medium']),
  allowDecimals: propTypes.bool
  // Other props here: https://material-ui.com/api/slider/
}

UISlider.defaultProps = {
  type: 'regular',
  defaultVals: [],
  onSliderChange: () => {},
  bg: 'white',
  afterLabel: null,
  inputSize: 'medium',
  allowDecimals: false,
  // Other props here: https://material-ui.com/api/slider/
}
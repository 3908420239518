import React, {useState, useEffect} from 'react'
import propTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser';
import {Grid} from '@material-ui/core';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import {get} from 'lodash';

import Typography from '../../../components/typography';
import LinkButton from '../../../components/ui/LinkButton';
import {theme} from '../../../themes/default-theme';
import Dropdown from '../../../components/ui/Dropdown';
import dlPush from '../../../util/dlPush';

// icons
import phoneIcon from '../../../assets/icons/phone-white.svg';
import externalIcon from '../../../assets/icons/external-white.svg';
import MapPin from '../../../components/ui/MapPin';
import googleMapStyle from '../../../themes/google-map.json';


const Contact = styled.div`
  ${theme.breakpoints.up('md')} {
    max-width: 570px;
  }
`

const ContactHeading = styled.div`
  margin: 0 0 20px;
`
const Address = styled.div`
  margin-bottom: 15px;
`

const OpeningHours = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${theme.palette.lightGrey};
  padding-top: 15px;
  ${theme.breakpoints.up('md')} {
    height: 100%;
    border-left: 1px solid ${props => props.borderColor};
    border-top: 0;
    padding-top: 0;
    padding-left: 20px;
    margin-top: 0;
  }
`

const A = styled.a `
  display: block;
  margin-top: 7px;
  text-decoration: none;

  ${theme.breakpoints.down('md')} {
    .svg-container svg {
      height: 10px;
      position: relative;
      top: -2px;
    }
  }

  ${props => props.smallIcon === 1 && `
    .svg-container svg {
      height: 10px;

      ${theme.breakpoints.down('md')} {
        height: 8px;
      }
    }
  `}
`
const GoogleMapContainer = styled.div`
  width: 100%;
  height: 268px;
  margin-top: 30px;
  margin-bottom: 40px;

  ${theme.breakpoints.up('md')} {
    height: 400px;
  }

  ${theme.breakpoints.up('lg')} {
    height: 540px;
  }
`

const GoogleMapBorder = styled.div`
  border-top-left-radius: 40px;
  overflow: hidden;
  height: 100%;

  ${theme.breakpoints.up('md')} {
    border-top-left-radius: 50px;
  }

  ${theme.breakpoints.up('lg')} {
    border-top-left-radius: 80px;
  }
`


const ContactBtn = styled(LinkButton)`

`

const DropdownContainer = styled.div`
  margin-bottom: 30px;
  max-width: 400px;
`

const TextItem = styled(Typography)`

`

const ContactDetails = styled.h6`
  color: ${theme.palette.charcoal};
  margin: 0;
`

const DealerContactDetails = ({
  locations,
  onLocationChange
}) => {
  // const [location, setLocation] = useState(locations[0]);

  const [viewNumber, setViewNumber] = useState(false);

  const defaultLocation = get(locations, '[0]', null);
  const [location, setLocation] = useState(defaultLocation);
  const [activeLocation, setActiveLocation] = useState(defaultLocation ? {text: defaultLocation.name, value: defaultLocation.name} : null);
  const [data, setData] = useState(locations);
  const [dealerData, setDealerData] = useState(false);

  useEffect(() => {
    if (viewNumber) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'Phone Number Reveal',
        'eventCategory': 'Engage',
        'eventLabel': `${location.name} - tel:${location.phone}`
      })
    }
  }, [viewNumber]);

  useEffect(() => {
    onLocationChange(data.length);
  }, [data]);

  const handleLocationChange = (e) => {
    if (!e.value) {
      return;
    }
    const item = data.find(item => e.value === item.name);
    item && setActiveLocation(e.value)
    item && setLocation(item)
  }

	useEffect(() => {
    try {
      const component = document.getElementById('staticly-dynamics-component-DealerContactDetails');
      // const properties = component ? component.getAttribute('data-component-properties') : null;
      // const jsonData  = properties ? JSON.parse(properties) : null;
      const jsonData = window.staticly.data && window.staticly.data.DealerContactDetails ? window.staticly.data.DealerContactDetails : null

      if (component && !jsonData) {
        component.remove();
        return
      }
      
      if (jsonData) {
        jsonData ? setData(jsonData) : setData([]);
        setDealerData(true);
        const defaultLocation = get(jsonData, '[0]', null);
        jsonData && setLocation(defaultLocation);
        defaultLocation && setActiveLocation({text: defaultLocation.name, value: defaultLocation.name});
        component && component.remove();
      }
    } catch {
      // do nothing
    }
	}, []);

  const formatAddress = (address) => {
    if (!address) {
      return null
    }
    let addressArr = [];
    address.addressLine1 && addressArr.push(address.addressLine1);
    address.addressLine2 && addressArr.push(address.addressLine2);
    address.suburb && addressArr.push(address.suburb);

    if (address.state && address.postcode) {
      addressArr.push(`${address.state} ${address.postcode}`)
    }

    if (address.state && !address.postcode) {
      addressArr.push(`${address.state}`)
    }

    if (!address.state && address.postcode) {
      addressArr.push(`${address.postcode}`)
    }

    return addressArr.join(', ')
  }

  const googleMapKey = process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDvDPE1nXC0ejI_nWsFYf37isGY_XfJCp4'
  const address = formatAddress(location);

  const center = (location && location.latitude && location.longitude) ? {
    lat: location.latitude ? parseFloat(location.latitude) : null,
    lng: location.longitude ? parseFloat(location.longitude) : null
  } : null

  const test = `
    <script id="staticly-dynamics-data-DealerContactDetails">
      window.staticly = window.staticly || {};
      window.staticly.data = window.staticly.data || {};
      window.staticly.data['DealerContactDetails'] = [{"name":"Jayco Newcastle ","description":"<p>Located just off the Pacific Highway in Heatherbrae, you will find the Jayco Newcastle dealership at 1 Camfield Drive. Spanning over 5 acres, Jayco Newcastle brings caravan and motorhome sales, servicing and accessories all together in the one place.</p>","addressLine1":"1 Camfield Drive","addressLine2":"","suburb":"Heatherbrae","state":"NSW","postcode":"2324","country":"Australia","latitude":-32.7820068,"longitude":151.7408052,"openingHours":"Mon - Fri: 8:30am - 5.00pm | Sat: 9:00am – 4:00pm | Sun and Pub. Holidays: Closed","phone":"02 4028 0111"}];
    </script>
    <div id="staticly-dynamics-component-DealerContactDetails" data-component-properties='{}'><div><div class="sc-gJNiKh UsEHO"><div class="sc-brWQNQ hoZIiI"></div><div class="MuiGrid-root MuiGrid-container MuiGrid-justify-xs-space-between" align="left"><div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><p class="MuiTypography-root makeStyles-root-3 MuiTypography-body1 MuiTypography-alignLeft"></p><br/><h6 class="MuiTypography-root makeStyles-root-4 MuiTypography-h6 MuiTypography-alignLeft">Contact details</h6><br/></div><div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6"></div></div></div><div class="MuiGrid-root MuiGrid-container MuiGrid-justify-xs-space-between" align="left"><div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><div class="sc-EchRo krtusJ"><div class="sc-fMvtBK hIrCFn"></div></div></div></div></div></div>
  `
  const hbString = `{{#staticlyDynamic data from="template.data.locations" id="DealerContactDetails" component="src/containers/Contact/DealerContactDetails/index.js"}}{{/staticlyDynamic}}`
  const main_site = !process.env.DEALER_SITE;

  if (!process.browser) {
    return (
      <div dangerouslySetInnerHTML={{__html: hbString}}>
      </div>
    )
  } else {

    return (
      <>
        {data && data.length > 1 &&
          <DropdownContainer>
            <Dropdown
              options={data.map(item => {
                return {
                  text: item.name,
                  value: item.name,
                }
              })}
              value={activeLocation || undefined}
              onChange={(e) => handleLocationChange(e)}
            />
          </DropdownContainer>
        }

        <Contact>

          {location && location.name &&
            <Grid container spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
              <ContactHeading>
                {location && location.name && <TextItem variant="h4" colour="charcoal">{location.name}</TextItem>}
              </ContactHeading>
            </Grid>
          }

          <Grid container spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
            <Grid item xs={12} md={12}>
              {location && location.description &&
                <TextItem variant="body1">{ReactHtmlParser(location.description)}</TextItem>
              }
              <br />
              <ContactDetails>Contact details</ContactDetails>
              <br />
            </Grid>
          </Grid>
          
          <Grid container spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
             
            <Grid item xs={12} md={6}>
              {address && 
                <Address>
                  <TextItem variant="body1" colour="charcoal">
                    {address}
                  </TextItem>
                </Address>
              }
              {location &&
              <>
                <A href={viewNumber ? `tel:${location.phone}` : '#'} onClick={() => setViewNumber(true)}>
                  <ContactBtn svg={phoneIcon}>
                    {viewNumber ? location.phone : 'View phone number'}
                  </ContactBtn>
                </A>
                
                <A href={`https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`} target="_blank" smallIcon={1}>
                  <ContactBtn svg={externalIcon}>
                    Get directions
                  </ContactBtn>
                </A>
              </>
              }

            </Grid>

            {location && location.openingHours &&
              <Grid item xs={12} md={6}>
                <OpeningHours borderColor={theme.palette.middleGrey}>
                  <TextItem variant="subtitle1" colour="charcoal">Opening hours</TextItem>
                  <TextItem variant="body1">{ReactHtmlParser(location.openingHours)}</TextItem>
                </OpeningHours>
              </Grid>
            }

          </Grid>

        </Contact>

        {location &&
          <Grid container spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
            <Grid item xs={12}>
              <GoogleMapContainer>
                <GoogleMapBorder>
                  
                    <GoogleMapReact
                      key={Date.now()}
                      bootstrapURLKeys={{ key: googleMapKey }}
                      defaultCenter={center}
                      defaultZoom={15}
                      options={{
                        styles: googleMapStyle
                      }}
                      >
                      <MapPin
                        {...center}
                      />
                    </GoogleMapReact>
              
                </GoogleMapBorder>
              </GoogleMapContainer>
            </Grid>
          </Grid>
        }
      </>
    )
  }
}
  
DealerContactDetails.propTypes = {
  locations: propTypes.array,
  dealerHb: propTypes.bool,
  onLocationChange: propTypes.func
}
  
DealerContactDetails.defaultProps = {
  locations: [],
  dealerHb: false,
  onLocationChange: () => {}
}
  
export default DealerContactDetails
import React from 'react'
import propTypes from 'prop-types'
import Modal from '../../ui/Modal';
import CalculatorBanner from '../CalculatorBanner';
import { makeStyles } from '@material-ui/core/styles';
import {theme} from '../../../themes/default-theme';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  modalInner: {
    maxWidth: 1220,
    margin: '0 auto',
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 1460,
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  modalClose: {
      top: '5px',
      right: '5px',
      left: 'auto',
      transform: 'none',
      position: 'absolute',
      zIndex: 2,
      
      '& button': {
          background: '#0170D4'
      }
  }
}))

const Container = styled.div`
  background-color: black;
  border-radius: 21px;
  overflow: hidden;
  ${theme.breakpoints.up('md')} {


  }
`


const CalculatorBannerModal = ({
  open,
  onModalClose,
  bannerContent,
  make,
  price,
  model,
  variant,
  dealerId
}) => {

  const classes = useStyles();

  console.log('make', {make, price, model,variant, dealerId})

  return (
    
    <Modal
        isOpen={open}
        onClose={onModalClose}
        // overlayClassname={classes.modalOverlay}
        innerClassname={classes.modalInner}
        closeClassname={classes.modalClose}
        keepMounted={true}
    >   
      <Container>
        <CalculatorBanner 
            {...bannerContent}
            make={make}
            price={price}
            model={model}
            variant={variant}
            dealerId={dealerId}
        />
      </Container>
    </Modal>
  )
}
  
CalculatorBannerModal.propTypes = {
}
  
CalculatorBannerModal.defaultProps = {
}
  
export default CalculatorBannerModal
import React from 'react';
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';

import {theme} from '../../themes/default-theme';
import ForSaleHero from '../ForSaleHero';
import CaravanCard from '../../components/navigation/CaravanCard';
import CommunityContainer from '../../containers/Community/CommunityContainer';
import Text from '../../components/typography';

const CaravanCardContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    max-width: 335px;
  }
`

const Container = styled.div`
  max-width: 1164px;
  margin: 0 auto;
  h1 {
    font-weight: 700;
  }
  ${theme.breakpoints.up('md')} {
    h1 {
      font-size: 28px;
    }
  }
  ${theme.breakpoints.down('sm')} {
    h1 {
      font-size: 18px;
    }
  }
`

const Categories = styled.div`
  padding: 28px 0 28px;

  ${theme.breakpoints.up('md')} {
    padding: 90px 0 70px;
  }
`

const Articles = styled.div`
  background-color: ${theme.palette.lightGrey};
  padding: 28px 0 10px;

  ${theme.breakpoints.up('md')} {
    padding: 90px 0 70px;
  }
`

const ForSaleLanding = (props) => {
  
  const {
    hero,
    categories,
    articleConversion
  } = props.pageData

  console.log('categories', categories);
  
  return (
  	<div>
      <ForSaleHero
        {...hero}
      />
      {categories && categories.length > 0 &&
        <Categories>
          <Container>
            <Grid container direction={'row'} justify={'center'}>
              <Grid item xs={12}>
                <Box px={2}>
                  <Text variant="h1" align="center" colour="charcoal" uppercase>Explore RV's for Sale by Category</Text>
                </Box>
              </Grid>
            </Grid>
            <Grid container direction={'row'} justify={categories.length < 3 ? 'center' : 'flex-start'}>
              {categories.map(item => (
                <Grid item xs={12} md={6} lg={3} key={item.id}>
                  <Box px={2}>
                    <CaravanCardContainer>
                      <CaravanCard
                        {...item}
                        text={`${item.title} for sale: `}
                        textLinks={[{
                          text: 'VIC',
                          href: `${item.href}/vic`
                        },{
                          text: 'NSW',
                          href: `${item.href}/nsw`
                        },{
                          text: 'QLD',
                          href: `${item.href}/qld`
                        },{
                          text: 'SA',
                          href: `${item.href}/sa`
                        },{
                          text: 'WA',
                          href: `${item.href}/wa`
                        },{
                          text: 'NT',
                          href: `${item.href}/nt`
                        },{
                          text: 'ACT',
                          href: `${item.href}/act`
                        },{
                          text: 'TAS',
                          href: `${item.href}/tas`
                        }]}
                        disableCompare={true}
                      />
                    </CaravanCardContainer>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Categories>
      }
      {articleConversion && articleConversion.length > 0 &&
        <Articles>
          <Container>
            <Grid container direction={'row'} justify={'center'}>
              <CommunityContainer
                title={'Caravan &amp; Camping News from Jayco'}
                articles={articleConversion}
                borderRadius={true}
              />
            </Grid>
          </Container>
        </Articles>
      }
    </div>
  )
}
  
ForSaleLanding.propTypes = {
};
  
ForSaleLanding.defaultProps = {
};
  
export default ForSaleLanding;
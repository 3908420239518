import fetch from 'node-fetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-boost';

const getVariables = () => {

  // var id = 'hv5gwdgwcjbh3dafl44p7bjebe';
  // var key = 'da2-6wzdt6rrojbv5apvcy3i2olgne';
  // var apiEndpoint = 'https://yirivsskf5batig6dxh6m2igqm.appsync-api.ap-southeast-2.amazonaws.com/graphql';

  var id = process.env.JAYCO_GRAPHQL_API_PRODUCTION_ID;
  var key = process.env.JAYCO_GRAPHQL_API_PRODUCTION_KEY;
  var apiEndpoint = process.env.JAYCO_GRAPHQL_API_PRODUCTION_URL;

  // @TODO - update - at the moment if it's server side it will get development variables
  if (process.browser &&
    (window.location.hostname === 'www.jayco.com.au' ||
    window.location.hostname === 'jayco.com.au' ||
    window.location.hostname === 'www.jayco.co.nz' ||
    window.location.hostname === 'jayco.co.nz' ||
    window.location.hostname === 'static-master.jayco-com-au.au1.staticlyproject.com' ||
    window.location.hostname === 'static-master.jayco-co-nz.au1.staticlyproject.com'
    // Add links here for staging
  )) {
    return {
      id: id || process.env.JAYCO_GRAPHQL_API_PRODUCTION_ID,
      key: key || process.env.JAYCO_GRAPHQL_API_PRODUCTION_KEY,
      apiEndpoint: apiEndpoint || process.env.JAYCO_GRAPHQL_API_PRODUCTION_URL,
    }
  }

  if (process.browser &&
    (window.location.hostname === 'preview.jayco-com-au.staticlylocal.com' ||
    window.location.hostname === 'localhost'
    // Add links here for staging
  )) {
    return {
    id: 'hv5gwdgwcjbh3dafl44p7bjebe',
    key: 'da2-6wzdt6rrojbv5apvcy3i2olgne',
    apiEndpoint: 'https://yirivsskf5batig6dxh6m2igqm.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    }
  }

  return {
      id: id || process.env.JAYCO_GRAPHQL_API_PRODUCTION_ID,
      key: key || process.env.JAYCO_GRAPHQL_API_PRODUCTION_KEY,
      apiEndpoint: apiEndpoint || process.env.JAYCO_GRAPHQL_API_PRODUCTION_URL,
    // id: process.env.JAYCO_GRAPHQL_API_DEVELOPMENT_ID,
    // key: process.env.JAYCO_GRAPHQL_API_DEVELOPMENT_KEY,
    // apiEndpoint: process.env.JAYCO_GRAPHQL_API_DEVELOPMENT_URL,
  }

}

const authLink = new ApolloLink((operation, forward) => {

  // console.log('getVariables()', getVariables())

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      'x-api-id': getVariables().id,
      'x-api-key': getVariables().key
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({
  uri: getVariables().apiEndpoint,
  fetch: fetch,
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  // link: link,
  defaultOptions
});

export default client;
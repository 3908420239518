import React from 'react';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';

import pin from '../../../assets/icons/pin-blue.svg';
import {theme} from '../../../../src/themes/default-theme';

const Pin = styled.div`
  width: 48px;
  height: 48px;
  transform: translate(-24px, -24px);
  position: relative;
  svg {
    width: 48px;
    height: 48px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 73px;
    height: 73px;
    transform: translate(-36px, -36px);
    svg {
      width: 73px;
      height: 73px;
    }
  }
`

const Alpha = styled.h6`
  color: ${theme.palette.skyBlue};
  text-align: center;
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  margin-top: 13px;
`

const MapPin = ({
  alpha
}) => {
  return (
    <Pin>
      <div>
        {alpha &&
          <Alpha>{alpha}</Alpha>
        }
        <ReactSVG src={pin} />
      </div>
    </Pin>
  )
}

export default MapPin
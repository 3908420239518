import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from '@material-ui/core';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {ReactSVG} from 'react-svg';

import RespImg from '../../ui/RespImg';
import caravan from '../../../assets/icons/caravan.svg';
import check from '../../../assets/icons/check.svg';

const TabsContainer = styled(props => {
  return <Tabs {...props} />
})`
  &:hover: {
    outline: 'none',
  }
  & .MuiTabs-indicator {
    background: none;
  }
`;

const TabSelection = styled(props => {
  return <Tab {...props} tick={undefined} classes={{selected: 'selected'}} />
})`
  &.MuiTab-textColorInherit {
    opacity: .4;
    transition: all 0.5s ease;
  }
  &.MuiTab-root {
    min-width: auto;
  }
  
  
  
  &.selected {
    opacity: 1;
    border: none;
    transition: all 0.5s ease;
  }
  img,
  svg {
    height: 80px;
    width: 80px;
    display: block;
    ${theme.breakpoints.down("sm")} {
      height: 50px;
      width: 50px;

    }
  }

  img {
    width: auto;
  }

  * {
    height: 105px;
    background: none;
    ${theme.breakpoints.down("sm")} {
      height: 65px;
    }
  }
`;

const TabsItem = styled.div `
  position: relative;

  ${theme.breakpoints.down("sm")} {
    display: inline-grid;
  }
`

const Tick = styled.div`
  width: 16px;
  height: 17px;
  bottom: 0px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;

  svg {
    width: 16px;
    height: 17px;
    display: block;
  }
`

export const TabSelect = props => {
  const [value, setValue] = React.useState(props.activeIndex);
  const {data, variant} = props;

  const handleChange = (newValue) => {
    props.onChange(newValue);
  };

  useEffect(() => {
    setValue(props.activeIndex);
  }, [props.activeIndex])

  return (
    <TabsContainer
      value={value}
      variant={variant}
      centered={true}
    >
      {data.map((tab, index) => {
        const icon = tab.icon && tab.icon.sizes ? <RespImg {...tab.icon}/> : <ReactSVG src={tab.icon ? tab.icon : caravan} />
        return (
          <TabsItem key={tab.id || index} onClick={() => handleChange(index)}>
            <TabSelection
              selected={index === value}
              icon={icon}
            />
            {tab.tick &&
              <Tick>
                <ReactSVG src={check} />
              </Tick>
            }
          </TabsItem>
        )
      })}
    </TabsContainer>
  );
}

TabSelect.propTypes = {
  data: PropTypes.array,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  activeIndex: PropTypes.number
};

TabSelect.defaultProps = {
  variant: 'fullWidth',
  onChange: () => {},
  activeIndex: 0
};

export default TabSelect;
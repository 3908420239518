import React, { useEffect, useState, useRef} from "react";
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import Text from "../../typography";
import propTypes from 'prop-types';
import { theme } from "../../../themes/default-theme";
import Slider from "react-slick";
import RespImg from "../../ui/RespImg";
import { ReactSVG } from "react-svg";
import chevronLeft from '../../../assets/icons/new-chevron-left.svg';
import chevronRight from '../../../assets/icons/new-chevron-right.svg';
import BannerBackground from '../../../assets/banner-background.svg';
import { Cta } from "../../ui";
import LazyVideo from '../LazyVideo';
import { useRouter } from "next/router";

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)';
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)';

const SliderContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    ${theme.breakpoints.up('md')} {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 86px;
        background: url(${BannerBackground}) repeat-x bottom;
        .slick-list {
            margin: 0 -40px;
        }
    }
    ${upMd} {
        ${props => props.hasOverlay == 1 && `
            .slick-slide {
                position: relative;
                &:before {
                    content: '';
                    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    z-index: 1;
                }  
            }
        `}
    }
    ${theme.breakpoints.down('sm')} {
        padding-bottom: 22px;
        background: url(${BannerBackground}) repeat-x bottom;
        .slick-list {
            margin: 0 -20px;
        }
    }
    ul {
        list-style-type: none;
    }

    .slick-current {
        z-index: 1;
        > div {
            display: flex;
        }
    }
    ${theme.breakpoints.up('md')} {
        .slick-dots {
            position: absolute;
            bottom: 34px;
            max-width: 1400px;
            width: 100%;
            padding: 0 0 0 23px;
            margin: auto;
            left: 0;
            right: 0;
            > li {
                display: inline-block;
                margin-right: 13px;
                position: relative;
                z-index: 3;
            }
        }
    }
    ${theme.breakpoints.down('sm')} {
        .slick-dots {
            text-align: center;
            padding: 0;
            position: relative;
            top: 11px;
            > li {
                display: inline-block;
                margin-right: 13px;
                position: relative;
                z-index: 3;
            }
        }
    }
`
const BannerContainer = styled.div`
    position: relative;
    picture, img {
        background: ${theme.palette.white};
        ${theme.breakpoints.down('sm')} {
            min-height: 450px;
            object-fit: cover;
        }
    }
`
const BannerImage = styled.div`

`

const BannerVideoContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: calc(40.620%);
    ${theme.breakpoints.down('sm')} {
        min-height: 450px;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
    }
`
// const BannerVideo = styled.video`

// `

const NextArrowIcon = styled.div`
    position: absolute;
    bottom: 34px;
    max-width: 1380px;
    width: 100%;
    padding: 0 20px;
    margin: auto;
    left: 20px;
    right: 0;
    z-index: 2;
    cursor: pointer;
    ${props => props.sliderCount > 0 && `
        padding-left: ${(props.sliderCount * 12) + (props.sliderCount * 13) + 3}px;
    `}
    svg {
        position: relative;
        top: 1px;
    }
`   
const PrevArrowIcon = styled.div`
    position: absolute;
    bottom: 34px;
    max-width: 1400px;
    width: 100%;
    padding: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    svg {
        position: relative;
        top: 1px;
    }
`
const SliderDot = styled.div`
    background: ${theme.palette.white};
    border-radius: 24.5px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    .slick-active & {
        background: #0170D4;
    }
    ${theme.breakpoints.down('sm')} {
        background: #D6D6D6;
    }
`
const BannerTextContainer = styled.div`
    position: absolute;
    padding: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #ffff;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;
    ${theme.breakpoints.up('md')} {
        padding-left: 40px;
        padding-right: 40px;
        top: calc(50%);
        transform: translateY(-50%);
    }
    ${theme.breakpoints.down('sm')} {
        bottom: 42px;
    }
`
const BannerTextInnerContainer = styled.div`
    ${theme.breakpoints.up('md')} { 
        max-width: 1400px;
        width: 100%;
        padding: 0;
        margin: auto;
    }
`
const BannerText = styled.div`
    ${theme.breakpoints.down('sm')} { 
        h2 {
            font-weight: 800;
            font-size: 36px;
            line-height: 40px;
            text-transform: uppercase; 
            margin-bottom: 12px;     
        }
        > div {
            font-weight: 800;
            font-size: 14px;
            line-height: 11px;
            margin-bottom: 17px;    
        }
        > a {
            width: auto;
            font-size: 13px;
            display: inline-block;
            line-height: 18px;
        }
    }
    ${theme.breakpoints.up('sm')} { 
        h2 {
            font-weight: 800;
            font-size: 65px;
            line-height: 65px;
            text-transform: uppercase;
            margin-bottom: 12px; 
        }
        > div {
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 16px;
            margin-bottom: 17px;
        }
    }
    ${theme.breakpoints.up('md')} { 
        max-width: 660px;
        h2 {
            font-weight: 800;
            font-size: 86px;
            line-height: 86px;
            text-transform: uppercase;
            margin-bottom: 21px;
        }
        > div {
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 22px;
            margin-bottom: 30px;
        }
        > a {
            width: auto;
            
        }
    }
`
const NewHeroBanner = (props) => {

    const {
        banners
    } = props;

    const [sliderCount, setSliderCount] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0)
    const router = useRouter();
    // Media Breakpoint Start
    const desktopView = useMediaQuery(theme.breakpoints.up('md'));
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    // Media Breakpoint End

    useEffect(() => {
        banners && banners.length && setSliderCount(banners.length);
    }, [])

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            desktopView &&
            <NextArrowIcon onClick={onClick} sliderCount={sliderCount}>
                <ReactSVG src={chevronRight} />
            </NextArrowIcon>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            desktopView &&
                <PrevArrowIcon onClick={onClick}>
                    <ReactSVG src={chevronLeft} />
                </PrevArrowIcon>
            
        );
    }
    var settings = {
        customPaging: function(i) {
            return (
              <SliderDot />
            );
        },
        dots: true,
        infinite: true,
        fade: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const itemsRef = useRef([]);
    const slickRef = useRef(null);

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, banners.length);
    }, [banners]);

    console.log('activeSlide', activeSlide);

    return (
        <>
            {banners && banners.length > 0 &&
                <SliderContainer hasOverlay={router?.asPath != '/' ? 1 : 0}>
                    <Slider ref={slickRef} {...settings} beforeChange={(current, next) => {
                        setTimeout( () => {  
                            setActiveSlide(next);
                        }, 10 );			
                    }}>
                        {
                            banners.map((item, index) => {
                                return (
                                    <BannerContainer key={index}>
                                        {item.image && !item.videos ?
                                            <BannerImage>
                                                <RespImg
                                                    {...item.image}
                                                />
                                            </BannerImage>
                                        : item.videos &&
                                            <BannerVideoContainer>
                                                {index === activeSlide &&
                                                <>
                                                {desktopView && item.videos.desktopVideo &&
                                                    // <BannerVideo muted autoPlay loop playsInline>
                                                    //     <source src={item.videos.desktopVideo} />
                                                    // </BannerVideo>
                                                    // index !== 0 ?
                                                    <LazyVideo
                                                        attributes={{
                                                            muted:"true", 
                                                            autoPlay:"true", 
                                                            loop:"true", 
                                                            playsInline:"true"
                                                        }}
                                                        innerRef={el => itemsRef.current[index] = el}>
                                                        <source src={item.videos.desktopVideo}/>
                                                    </LazyVideo>
                                                    // :
                                                    // <div>
                                                    //     <video muted autoPlay loop playsInline>
                                                    //         <source src={item.videos.desktopVideo} />
                                                    //     </video>
                                                    // </div>

                                                }
                                                {mobileView && item.videos.mobileVideo &&
                                                    // <BannerVideo muted autoPlay loop playsInline>
                                                    //     <source src={item.videos.mobileVideo} />
                                                    // </BannerVideo>
                                                    // index !== 0 ?
                                                        <LazyVideo
                                                            attributes={{
                                                                muted:"true", 
                                                                autoPlay:"true", 
                                                                loop:"true", 
                                                                playsInline:"true"
                                                            }}
                                                            innerref={el => itemsRef.current[index] = el}>
                                                            <source src={item.videos.mobileVideo}/>
                                                        </LazyVideo>
                                                    // :
                                                    // <div>
                                                    //     <video muted autoPlay loop playsInline>
                                                    //         <source src={item.videos.mobileVideo} />
                                                    //     </video>
                                                    // </div>
                                                }
                                                </>
                                                }
                                            </BannerVideoContainer>
                                        }
                                        <BannerTextContainer>
                                            <BannerTextInnerContainer>
                                                <BannerText>
                                                    {item.title && <Text variant="h2" colour="white">{item.title}</Text>}
                                                    {item.subtext && <Text variant="body2" colour="white">{item.subtext}</Text>}
                                                    {(item.link && item.link.title) &&
                                                        <Cta href={item.link.href && item.link.href} target={item.link.target && item.link.target} primary uppercase>
                                                            {item.link.title}
                                                        </Cta>
                                                    }
                                                </BannerText>
                                            </BannerTextInnerContainer>
                                        </BannerTextContainer>
                                    </BannerContainer>
                                )
                            })
                        }
                    </Slider>
                </SliderContainer>
            }
        </>
    );
}

NewHeroBanner.propTypes = {
    banners: propTypes.array
}

NewHeroBanner.defaultProps = {
    banners: []
}

export default NewHeroBanner
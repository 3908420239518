const formatAddress = (address, linebreak) => {
  if (!address) {
    return null;
  }

  let addressArr = [];
  address.addressLine1 && addressArr.push(address.addressLine1);
  address.addressLine2 && addressArr.push(address.addressLine2);
  address.suburb && addressArr.push((linebreak ? '<br />' : '') + address.suburb);

  if (address.state && address.postcode) {
    addressArr.push(`${address.state} ${address.postcode}`)
  }

  if (address.state && !address.postcode) {
    addressArr.push(`${address.state}`)
  }

  if (!address.state && address.postcode) {
    addressArr.push(`${address.postcode}`)
  }
  
  return addressArr.join(', ');
};

export default formatAddress
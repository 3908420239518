import React from 'react';
import styled, { keyframes } from 'styled-components';
import {theme} from '../../../themes/default-theme';

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
`

const skBounce = keyframes`
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`

const Bounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${theme.palette.vividBlue};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${skBounce} 2.0s infinite ease-in-out;

  ${props => props.item === '2' && `
    animation-delay: -1.0s;
  `}
`
  
const Loader = () => {
  return (
    <Spinner>
      <Bounce item="1"/>
      <Bounce item="2"/>
    </Spinner>
  )
}
  
export default Loader
import React, {useState, useEffect} from 'react'
// import propTypes from 'prop-types'
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';
// import {get} from 'lodash';

import ContactUsForm from '../Forms/ContactUs';
import {theme} from '../../themes/default-theme';
import DealerContactDetails from '../../containers/Contact/DealerContactDetails';
// import Dropdown from '../../components/ui/Dropdown';
import Banner from './HeroBanner';
import {Links} from '../../components/ui';
import ContentBuilder from '../../containers/ContentBuilder'

// icons
import chevronLeft from '../../assets/icons/chevron-left-white.svg';

const GoBackContainer = styled.div `
  ${theme.breakpoints.up('lg')} {
    padding: 0 20px;
  }
`

const Container = styled.div`
  margin: 40px 0 50px;

  ${theme.breakpoints.up('lg')} {
    margin: 55px 0 50px;
    padding: 0 20px;
  }
`

const FormContainer = styled.div`
  background-color: ${theme.palette.lightBlue};
  padding-top: 20px;

  ${theme.breakpoints.up('lg')} {
    padding-top: 0;
    background-color: transparent;
    ${props => props.margin === 1 ? `
      margin-top: 75px;
    ` : `
      margin-top: 0;
    `}
    form {
      padding-left: 75px;
      border-left: 1px solid ${theme.palette.middleGrey};
      max-width: 560px;
      float: right;
    }
  }
`

const DropdownContainer = styled.div`
  margin-bottom: 30px;
  max-width: 400px;
`

const BannerContainer = styled.div`
  ${theme.breakpoints.up('sm')} {
    margin-top: -75px;
  }
  ${theme.breakpoints.up('md')} {
    margin-top: -81px;
  }
`
const ReturnLink = styled.div`
	margin: 20px auto 0;
  text-align: right;

  ${theme.breakpoints.up('md')} {
    margin-top: 40px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 100%;
  }
`

const ContactUsDetails = styled.div `
  ${theme.breakpoints.up('lg')} {
    max-width: 684px;
  }
`

const ContactUs = (props) => {

  const handleBack = (e) => {
    e.preventDefault();
    if (document.referrer == "") {
      window.location.replace('/')
    } else {
      history.back()
    }
  }

  const [locationLength, setLocationLength] = useState(0);

  return (
    <>
      <div>
        <ContentBuilder dealerCb={true} marginBottom={false} topOfDealerPage={true} />
      </div>

      <GoBackContainer>
        <Grid container className={'hasPadding'} spacing={0} direction={'row'}>
          <Grid item xs={12}>
            <Box px={2}>
              <ReturnLink>
                <Links href={'#'} svg={chevronLeft}>
                  <a onClick={handleBack}>
                    Go back
                  </a>
                </Links>
              </ReturnLink>
            </Box>
          </Grid>
        </Grid>
      </GoBackContainer>

      <Container>
        <Grid container className={'hasPadding'} spacing={0} direction={'row'}>
          <Grid item xs={12} lg={7}>
            <Box px={2}>
              <ContactUsDetails>
                <DealerContactDetails
                  onLocationChange={(length) => setLocationLength(length)}
                />
              </ContactUsDetails>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <FormContainer margin={locationLength > 1 ? 1 : false}>
              <ContactUsForm
                country={props.countryProps ? props.countryProps.countryCode : null}
              />
            </FormContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

ContactUs.propTypes = {
}
  
ContactUs.defaultProps = {
}
  
export default ContactUs
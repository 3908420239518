import React, {useState, useEffect} from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components';
import ContentBuilder from '../ContentBuilder';
import RepairAgentsList from '../../components/content/RepairAgentsList';
import { useRouter } from 'next/router';

const Container = styled.div`
    background: #F3F4F7;
`

    
const ListingPage = (props) => {
    const {
        contentBuilder,
        repairAgentsList
    } = props;

    const [isAus, setIsAus] = useState(null);
    
    const router = useRouter();

    const currentCountry = props.countryProps?.countryCode && props.countryProps.countryCode === 'AU' ? 'Australia' : 'New Zealand';

    useEffect(() => {
        if(currentCountry == 'Australia') {
          setIsAus(true);
        } else {
          setIsAus(false);
          router.push('/404');
        }
    }, [])
 
    return (
        isAus &&
            <Container>
                {contentBuilder && 
                    <ContentBuilder 
                        {...contentBuilder}
                    />
                }
                {repairAgentsList && 
                    <RepairAgentsList
                        {...repairAgentsList}
                    />
                }
            </Container>
    )
}
    
ListingPage.propTypes = {
    contentBuilder: propTypes.object,
    repairAgentsList: propTypes.object
}
    
ListingPage.defaultProps = {
    contentBuilder: null,
    repairAgentsList: null
}
    
export default ListingPage
import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {get} from 'lodash';
import styled from 'styled-components';
import {Grid, TextField} from '@material-ui/core';
import {Controller} from "react-hook-form";
import isPostalCode from 'validator/lib/isPostalCode';
import {theme} from '../../../../themes/default-theme';


const FormItem = styled.div`
  display: block;
  margin:${props => props.margin === 'none' ? `0` : `30px 0`};
  width: 100%;
  text-align: left;

  ${props => props.display === 0 && `
    display: none;
  `}
`

const FormCol = styled.div`

  ${theme.breakpoints.down('sm')} {
    ${props => props.position === 'left' && `
      margin-bottom: 30px;
    }`
  }

  ${theme.breakpoints.up('md')} {
    ${props => props.position === 'left' ? `
      padding-right: 15px;
    ` : `
      padding-left: 15px;
    `}
  }
`

const Error = styled.p`
  color: ${theme.palette.error.form};
  text-align: left;
`

const Step1 = ({formProps, state, onFormChange,}) => {

  // useEffect(() => {
  //   const asyncLoad = async () => {
  //     const loader = new Loader(process.env.GOOGLE_MAPS_API_KEY, {});
  //     const google = await loader.load();
  //     setGeocoder(new google.maps.Geocoder())
  //   }
  //   asyncLoad()
  // }, [])

  const {
    control,
    errors
  } = formProps

  const textFieldProps = {
    as:TextField,
    control:control,
    rules:{required: true },
    variant:"outlined",
    fullWidth: true,
    defaultValue: ''
  }

  
  const serviceOptions = [{
    optionValue: 'Retail repairs',
    label: 'Retail repairs'
  },{
    optionValue: 'Insurance repairs',
    label: 'Insurance repairs'
  },{
    optionValue: 'Annual servicing',
    label: 'Annual servicing'
  },{
    optionValue: 'Warranty repairs',
    label: 'Warranty repairs'
  }]

  return (
    <>
      {/* First name */}
      <FormItem>
        <Controller
          {...textFieldProps}
          name={'first_name'}
          placeholder={'First name*'}
          error={get(errors, `[first_name]`, false) ? true : false}
          defaultValue={state.first_name || ''}
        />
        {(get(errors, `[first_name]`, false)) && <Error>This field is required</Error>}
      </FormItem>
      {/* Last name */}
      <FormItem>
        <Controller
          {...textFieldProps}
          name={'last_name'}
          placeholder={'Last name*'}
          error={get(errors, `[last_name]`, false) ? true : false}
          defaultValue={state.last_name || ''}
        />
        {(get(errors, `[last_name]`, false)) && <Error>This field is required</Error>}

      </FormItem>
      {/* Email */}
      <FormItem>
        <Controller
          {...textFieldProps}
          type="email"
          name={'email'}
          placeholder={'Email*'}
          error={get(errors, `[email]`, false) ? true : false}
          defaultValue={state.email || ''}
        />
        {(get(errors, `[email]`, false)) && <Error>Please add a valid email address</Error>}

      </FormItem>
      {/* Contact number */}
      <FormItem>
        <Controller
          {...textFieldProps}
          type="tel"
          rules={{
            ...textFieldProps.rules,
            type: 'custom',
            minLength: 8,
            maxLength: 13
          }}
          name={'phone'}
          placeholder={'Contact number*'}
          error={get(errors, `[phone]`, false) ? true : false}
          defaultValue={state.phone || ''}
        />
        {(get(errors, `[phone]`, false)) && <Error>Please add a 8 digit phone number</Error>}

      </FormItem>

      <Grid container direction={'row'} justify={'space-between'} align={'center'}>
        <Grid item xs={12} md={6}>
          <FormCol position="left">
            <Controller
              {...textFieldProps}
              type="tel"
              rules={{
                ...textFieldProps.rules,
                type: 'custom',
                minLength: 3,
                maxLength: 4,
                validate: (val) => isPostalCode(val, 'AU')
              }}
              name={'postcode'}
              placeholder={'Postcode*'}
              error={get(errors, `[postcode]`, false) ? true : false}
              defaultValue={state.postcode || ''}
            />
            {(get(errors, `[postcode]`, false)) && <Error>Please add a valid 4 digit postcode</Error>}

          </FormCol>

        </Grid>
        <Grid item xs={12} md={6}>
          <FormCol position="right">
            <Controller
              {...textFieldProps}
              rules={{
                ...textFieldProps.rules,
                type: 'custom',
              }}
              name={'chassis'}
              placeholder={'Chassis number*'}
              error={get(errors, `[chassis]`, false) ? true : false}
              defaultValue={state.chassis || ''}
            />
            {(get(errors, `[chassis]`, false)) && <Error>This field is required</Error>}
          </FormCol>
        </Grid>

        
      </Grid>
    </>
  )
}
  
Step1.propTypes = {
  setDealerSummary: propTypes.func
}
  
Step1.defaultProps = {
  setDealerSummary: () => {}
}
  
export default Step1
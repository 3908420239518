import React from 'react';
import propTypes from 'prop-types';
import Text from '../../typography'
import { Grid, Box } from '@material-ui/core';
import styled from 'styled-components';
import { Cta } from '../../ui';
import { theme } from '../../../themes/default-theme';
import Link from '../../navigation/NextLink';


const Container = styled.div`  
  background: #F3F4F7;
  ${theme.breakpoints.up("md")} {
    padding: 64px 0 120px;
  }
  ${theme.breakpoints.down("sm")} {
    padding: 18px 0 60px;
  }
`
const RepairAgentsListContainer = styled.div`
  background: ${theme.palette.white}; 
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; 
  > h2 {
    border-bottom: 1px solid rgb(243, 244, 247);
  }
  ${theme.breakpoints.up("md")} {
    padding-top: 45px;
    > h2 {
      padding-bottom: 14px;
      padding-left: 17px;
      padding-right: 17px;
      font-size: 18px;
    }
  }
  ${theme.breakpoints.down("sm")} {
    padding-top: 25px;
    > h2 {
      padding-bottom: 14px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
    }
  }
`
const AgentListContainer = styled.div`
  > h3 {
     border-bottom: 1px solid rgb(243, 244, 247);
  }
  ${theme.breakpoints.up("md")} {
    > h3 {
      font-size: 14px;
      padding: 14px 17px;
    }
  }
  ${theme.breakpoints.down("sm")} {
    > h3 {
      font-size: 13px;
      padding: 14px 10px;
    }
  }
`

const List = styled.div`
  background: rgba(243, 244, 247, 0.5);
  border-bottom: 1px solid rgb(243, 244, 247);
  a {
    text-decoration: underline;
  }
  ${theme.breakpoints.up("lg")} {
    display: flex;
    align-items: baseline;
    gap: 38px;
    > * {
      width: calc(100% - 38px);
    }
  }
  ${theme.breakpoints.up("md")} {
    padding: 14px 17px;

    > div {
      font-size: 14px;
    }
    a {
      font-size: 15px;
    }
  }
  ${theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  ${theme.breakpoints.down("sm")} {
    padding: 14px 10px;
    > div {
      font-size: 13px;
    }
    a {
      font-size: 13px;
    }
  }
`

const ListTitle = styled.div`
  ${theme.breakpoints.up("lg")} {
    min-width: 21%;
    max-width: 21%;
  }
  ${theme.breakpoints.up("md")} {
    h4 {
      font-size: 14px;
    }
  }
  ${theme.breakpoints.down("sm")} {
    h4 {
      font-size: 13px;
    }
  }
`

const ListLinks = styled.div`
  ${theme.breakpoints.up("lg")} {
    display: flex;
    align-items: baseline;
    gap: 38px;
  }
  ${theme.breakpoints.down("md")} {
    display: flex;
    align-items: baseline;
    gap: 16px;
  }
`

const ListAddress = styled.div`
  color: ${theme.palette.charcoal};
  ${theme.breakpoints.up("lg")} {
    min-width: 18%;
    max-width: 18%;
  }
`

const LinkPhone = styled.div`
  ${theme.breakpoints.up("lg")} {
    width: 100%;
    > a {
      display: block;
      width: max-content;
    }
  }
`
const LinkEmail = styled.div`
  ${theme.breakpoints.up("md")} {
    > a {
      font-size: 14px;
    }
  }
  ${theme.breakpoints.up("lg")} {
      width: auto;
  }
`

const LinkLink = styled.div`
  ${theme.breakpoints.up("lg")} {
    width: 100%;
  }
`

const ListOpenningHours = styled.div`
  color: ${theme.palette.charcoal};
  ${theme.breakpoints.up("lg")} {
    min-width: 18%;
    max-width: 18%;
  }
`


const RepairAgentsList = ({
  title,
  agentsList
}) => {


  return (
    <Container>
      <Grid container spacing={0} direction={'row'}>      
        <Grid item xs={12}>
          <Box px={2}>
            <RepairAgentsListContainer>
                {title && <Text variant="h2" colour="charcoal" uppercase={true}>{title}</Text>}
                {agentsList && agentsList.length > 0 &&
                    agentsList.map((item, index) => {
                        return (
                          <AgentListContainer key={index}>
                              {item.title && <Text variant="h3" weight={800} colour="charcoal" uppercase={true}>{item.title}</Text>}
                              {item.list && item.list.length > 0 &&
                                    item.list.map((child, index) => {
                                        return (
                                          <List key={index}>
                                                {child.title &&
                                                    <ListTitle>
                                                        <Text variant="h4" weight={700} colour="charcoal">{child.title}</Text>
                                                    </ListTitle>
                                                }
                                                {child.address &&
                                                    <ListAddress>
                                                        {child.address}
                                                    </ListAddress>
                                                }
                                                {child.openningHours &&
                                                    <ListOpenningHours>
                                                        {child.openningHours}
                                                    </ListOpenningHours>
                                                }
                                                {(child.phone || child.email || (child.link && child.link.title)) &&
                                                  <ListLinks>
                                                    {child.phone &&
                                                        <LinkPhone>
                                                          <Link href={`tel:${child.phone}`}>
                                                              <a>
                                                                {child.phone}
                                                              </a>
                                                          </Link>
                                                        </LinkPhone>
                                                    }
                                                    {child.email &&
                                                        <LinkEmail>
                                                            <Link href={`mailto:${child.email}`}>
                                                                <a>
                                                                  Email
                                                                </a>
                                                          </Link>
                                                         </LinkEmail>
                                                    }
                                                      {child.link && child.link.title &&
                                                        <LinkLink>
                                                            <Link href={`${child.link.href ? child.link.href : '#'}`} target={`${child.link.target ? child.link.target : '_self'}`}>
                                                                <a>
                                                                  {child.link.title}
                                                              </a>
                                                            </Link>
                                                        </LinkLink>
                                                    }
                                                  </ListLinks>
                                                }
                                                
                                          </List>
                                        )
                                    })
                              }
                          </AgentListContainer>
                        )
                    })
                }

            </RepairAgentsListContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

RepairAgentsList.propTypes = {
  title: propTypes.string,
  agentsList: propTypes.array
}

RepairAgentsList.defaultProps = {
  title: "",
  agentsList: []
}

export default RepairAgentsList
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import {theme} from '../../themes/default-theme';
import NextLink from '../../components/navigation/NextLink';

import Typography from '@material-ui/core/Typography';
import Banner from '../../components/multimedia/Banner';
import {Links, RespImg} from '../../components/ui';

const TopWrapper = styled.div`
  background-color: white;
  padding: 30px 0;

  ${theme.breakpoints.up('md')} {
    padding: 60px 0;
  }
  ${theme.breakpoints.up('lg')} {
    padding: 120px 0;
	}
`

const PageContainer = styled.div`
  max-width: 1600px;
  font-weight: 700;
  margin: 0 auto;
` 

const IntroContainer = styled.div`
	text-align: center;
	max-width: 780px;
  margin-bottom: 48px;

  ${theme.breakpoints.down('sm')} {
    padding-left: 8px;
    padding-right: 8px;
  }
` 

const IntroTitle = styled.div`
	font-size: 28px;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: ${theme.palette.charcoal};

	${theme.breakpoints.down('sm')} {
		font-size: 16px;
  }
` 

const IntroCaption = styled.div`
	font-size: 15px;
	color: ${theme.palette.gunmentalGrey};
	font-weight: 400;

	${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
` 

const StateContainer = styled.div`
  & .MuiGrid-container {
    max-width: 1600px;
  }

  & .state-selected {
    border: 4px solid ${theme.palette.yellow};
  }

  ${theme.breakpoints.up('md')} {
    & div .MuiGrid-item:nth-child(5) .container-border {
      border-bottom-left-radius: 80px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    & div .MuiGrid-item:nth-last-child(2) .container-border {
      border-bottom-left-radius: 40px;
    }
  }
` 

const StateBox = styled(Box)`
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 50px;
  letter-spacing: 2px;
  cursor: pointer;
  border: .5px solid white;
  position: relative;
  overflow: hidden;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

	${theme.breakpoints.down('sm')} {
    max-height: 120px;
    top: 12%;
    font-size: 20px;
    overflow: hidden;
  }
` 

const ResultContainer = styled.div`
  height: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  background: ${theme.palette.lightGrey};
  margin-bottom: 120px;

  & .hasPadding {
    max-width: 1400px;
  }

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 60px;
  }
` 

const ClubGrid = styled(Grid)`
  ${theme.breakpoints.up('md')} {
    margin: 0 auto;
  }

	${theme.breakpoints.down('sm')} {
		margin-bottom: 10px;
	}
	
	& .box-child {
		${theme.breakpoints.up('md')} {
			margin-bottom: 32px;
			height: 100%;
		}
	}

	a {
		text-decoration: none;
	}
` 

const ThumbnailContainer = styled.div`
  position: relative;
  max-height: 250px;
  overflow: hidden;
  border-top-right-radius: 40px;
` 

const CaptionContainer = styled.div`
	padding: 20px 14px;
	margin-top: -4px;
  background: ${theme.palette.white};
  text-align: left;

	${theme.breakpoints.up('md')} {
		padding: 30px;
  }
` 

const ClubTitle = styled.div`
  font-size: 18px;
	font-weight: 700;
  padding-bottom: 14px;
  color: ${theme.palette.charcoal};

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
  }
` 

const ClubPage = (props) => {
  // const classes = useStyles();
  const width = props.width;

  const [subHeading, setSubHeading] = useState(null);
  const [stateRequest, setStateRequest] = useState(null);
  const [stateIndex, setStateIndex] = useState(null);

  const handleStateSelect = (state, index) => {
    props.clubsConversion && props.clubsConversion.map(function(item) {
      if (item.link.indexOf(state.url) !== -1) {
        setStateRequest(item.club);
        setSubHeading(state.title);
        setStateIndex(index);
        window.scrollTo({
          top: 260,
          behavior: "smooth"
        });
      }
    })
  };

  return (
    <div>
		  {props.heroBanner &&
				<Banner
          {...props.heroBanner}
          subHeading2={subHeading}
					type="mediumBanner"
				/>
      }
      <TopWrapper>
				<PageContainer>
					<Grid align={'center'}>
						<Grid item xs={12}>
							<Box>
								<IntroContainer>
									{props.headingIntro && <IntroTitle dangerouslySetInnerHTML={{ __html: props.headingIntro}} />}
								</IntroContainer>
                {stateRequest ?
                  <ResultContainer>
                    <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
                      {stateRequest && stateRequest.slice(0,3).map(function(club, index) {
                        return (
                          <ClubGrid item xs={12} md={6} lg={4} key={index}>
                            <Box px={2} className={'box-child'}>
                              <>
                                {club.img &&
                                  <NextLink href={`/owners-clubs/${club.uri}`}>
                                    <a>
                                      <ThumbnailContainer>
                                        <RespImg
                                          {...club.img}
                                        />
                                      </ThumbnailContainer>
                                    </a>
                                  </NextLink>
                                }
                                <CaptionContainer>
                                  {club.title && <ClubTitle dangerouslySetInnerHTML={{ __html: club.title}} />}
                                  <Typography>
                                    <Links href={`/owners-clubs/${club.uri}`} secondary>
                                      Read more
                                    </Links>
                                  </Typography>
                                </CaptionContainer>
                              </>
                            </Box>
                          </ClubGrid>
                        )
                      })}
                    </Grid>
                  </ResultContainer>
                :
                  <IntroContainer>
                    {props.bodyIntro && <IntroCaption dangerouslySetInnerHTML={{ __html: props.bodyIntro}} />}
                  </IntroContainer>
                }
							</Box>
						</Grid>
					</Grid>
        </PageContainer>
        <StateContainer>
          <Grid container spacing={0} direction={'row'} wrap={'wrap'} align={'center'}>
            {props.statesConversion && props.statesConversion.map(function(state, index) {
              return (
                <Grid
                  item 
                  xs={6} md={3} 
                  key={index} 
                  onClick={() => handleStateSelect(state.link, index)}
                >
                  <StateBox className={stateIndex === index ? 'state-selected container-border' : 'container-border'}>
                    <RespImg
											{...state.bgImg}
										/>
                    <span>
                      {state.title}
                    </span>
                  </StateBox>
                </Grid>
              )
            })}
          </Grid>
        </StateContainer>
      </TopWrapper>
    </div>
  )
}

export default ClubPage;
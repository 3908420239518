import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';

import {theme} from '../../../themes/default-theme';
import Quicklink from '../Quicklink'
import Text from '../../typography';
import Equalizer from 'react-equalizer';

const QuicklinksContainer = styled.div`
  
  ${theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction:column;
  }
  ${theme.breakpoints.up("lg")} {
    display: flex;
    flex-direction:row;
    gap:0;
    align-items:stretch;
    flex-wrap: wrap;
    > div {
      max-width: 33%;
      margin-bottom: 20px;
    }
  }
  ${theme.breakpoints.up("md")} {
    gap: 1px;
  }
`

const QuicklinkContainer = styled(Equalizer)`
  width: 100%;
  
`


const Heading = styled(props => {
  return <Text {...props} />
})`
  margin-bottom: 20px;
  ${theme.breakpoints.up("md")} {
    text-align: center;
    margin-bottom: 30px; 
  }
  ${theme.breakpoints.up("lg")} {
    margin-bottom: 50px;
  }
`

const Container = styled.div`  
  h3 {
    font-weight:800;
    ${theme.breakpoints.up('md')} {

    }
  }
  ${props => props.margin && `
    margin: 35px auto;

    ${theme.breakpoints.up('md')} {
      margin: 40px auto;
      max-width: 1254px;
    }

    ${theme.breakpoints.up('lg')} {
      margin: 120px auto;
    }
  `}
`

export const Quicklinks = props => {
  const {dealerComponent} = props;

  const [data, setData] = useState(props.data); // @todo replace with above
  const [heading, setHeading] = useState(props.heading); // @todo replace with above
  const [dealerData, setDealerData] = useState(false);

	useEffect(() => {
    try {
      const component = document.getElementById('staticly-dynamics-component-Quicklinks');
      // const properties = component ? component.getAttribute('data-component-properties') : null;
      // const jsonData  = properties ? JSON.parse(properties) : null;
      const jsonData = window.staticly.data && window.staticly.data.Quicklinks ? window.staticly.data.Quicklinks : null

      if (component && !jsonData) {
        component.remove();
        return;
      }

      if (jsonData && dealerComponent) {
        setData(jsonData.data);
        setHeading(jsonData.heading);
        setDealerData(true);
        component && component.remove();
      }
    } catch {
      
    }

	}, []);

  const hbString = `{{#staticlyDynamic data from="template.quicklinks" id="Quicklinks" component="src/components/navigation/Quicklinks/index.js"}}{{/staticlyDynamic}}`

  if (dealerComponent && !dealerData && process.env.DEALER_SITE) {
    return (
        <div dangerouslySetInnerHTML={{__html: hbString}}>
        </div>
    )
  } else {

    return (
      <Container margin={dealerComponent}>
        {heading &&
          <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
            <Grid item xs={12}>
              <Box px={2}>
                <Heading variant="h3" size="extraLarge" colour="charcoal" align={'center'} uppercase={true}>{heading}</Heading>
              </Box >
            </Grid>
          </Grid>
        }

          <Grid container className="hasPadding" direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
            <Grid item xs={12}>
              <Box px={2}>
                <QuicklinksContainer>
                  {data && data.map((quicklink, index) => {
                    return (
                        <Quicklink 
                          title={quicklink.title} 
                          image={quicklink.image} 
                          caption={quicklink.caption}  
                          href={quicklink.href} 
                          hrefTitle={quicklink.hrefTitle} 
                        />
             
                    )
                  })}  
                </QuicklinksContainer>
              </Box>
            </Grid>
          </Grid>
      </Container>
    );
  }
}

Quicklinks.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.array.isRequired,
  dealerComponent: PropTypes.bool
}

Quicklinks.defaultProps = {
  heading: null,
  data: [],
  dealerComponent: false,
}

export default Quicklinks;
import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import Text from '../../../components/typography';
import Cta from '../../../components/ui/Cta';
import {Links} from '../../../components/ui';
import NextLink from '../../../components/navigation/NextLink';
import heartIcon from '../../../assets/icons/heart.svg';
import heartFilledIcon from '../../../assets/icons/heartFilled.svg';
import {ReactSVG} from 'react-svg';
import {findIndex} from 'lodash';
import {getItems, saveItem, removeItem} from '../../../util/savedCaravans';
import toPrice from '../../../util/toPrice';
import dynamic from 'next/dynamic';

// import { Equalize } from "react-equalize";

const Equalize = dynamic(
  () => import('react-equalize').then((mod) => mod.Equalize),
  { ssr: false }
)

const Container = styled.div`
  height: calc(100% - 20px);
  background: white;
  border: 1px solid ${theme.palette.middleGrey};
`

const ImgContainer = styled.div`
  position: relative;
  img {
    border-top-right-radius: 40px;
  }
`

const Img = styled.div`
  display:block;
  height: 0;
  width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  background-position: center;
`

const Label = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 92px;
  padding: 10px;
  color: white;
  text-align: center;
  border-top-left-radius: 12px;
  font-weight: bold;
  font-size: 12px;

  ${props => props.bg ? `
    background-color: ${theme.palette[`${props.bg}`]};
  ` : `
    background-color: ${theme.palette.vividBlue};
  `}
`

const Content = styled.div`
  padding: 20px;
  
  background-color: white;

  h6 {
    margin-bottom: 0;
  }
`

const ContentBottom = styled.div`
  margin-top: 20px;
  ${theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${theme.palette.middleGrey};
`

const SavedContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const ButtonItem = styled.div`
  margin-left: 35px;
  margin-top: 0;
  margin-right: 0;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    display: block;
    [fill] {
      fill: ${theme.palette.skyBlue};
    }

    ${theme.breakpoints.up('md')} {
      width: 24px;
      height: 24px;
    }
  }
`

const CtaDesktop = styled(Cta)`
  display: none;
  ${theme.breakpoints.up('md')} {
    display: inline-block;
  }
`

const LinkMobile = styled(Links)`
  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

const Info = styled.div`
  margin-top: 10px;
`

const From = styled(Text)`
  margin-top: 5px;
`

const Series = styled.h6`
  color: ${theme.palette.vividBlue};
  font-size: 9.5px;
  line-height: 1.1;
  margin-top: 2px;
  text-transform: uppercase;
  margin-bottom: 2px;
  ${theme.breakpoints.up('md')} {
    font-size: 12px;
  }
`

const StockCard = (props) => {
  const {
    id,
    image,
    type,
    year,
    name,
    price,
    cta,
    onSave,
    onClick,
    variant,
    stockNumber,
    vin,
    series,
    weeklyRepayment
  } = props

  const isSaved = () => {
    const items = getItems();
    return items && items.length > 0 ? (findIndex(items, { id: id} ) !== -1 ? true : false) : false
  }
  
  const [saved, setSaved] = useState(isSaved())

  const handleLsChange = () => {
    const isItemSaved = isSaved()
    isItemSaved !== saved && setSaved(isItemSaved);
  }

  useEffect(() => {
    document.addEventListener("ls:change", handleLsChange);
    return () => window.removeEventListener("ls:change", handleLsChange);
  },[])

  const handleSave = () => {
    const data = {
      ...props,
      title: props.name,
      uri: props.cta.url
    };
    const saved = isSaved(id);
    setSaved(!saved);
    (id && saved) ? removeItem({type: 'model', id, data}) : saveItem({type: 'model', id, data});
    // setSavedItems(savedItems.concat([id])) // Just so we can reload the saved items from ls
  }

  return (
  	<Container>
      <ImgContainer>
        <NextLink href={cta.url}>
          <a onClick={(e) => onClick(e, id)}>
            <Img
              // src={image}
              style={{backgroundImage: `url(${image ? image : '/fallback.png'})`}}
              // width={900}
              // height={675}
            />
          </a>
        </NextLink>
      {type &&
        <Label bg={type.toLowerCase() === 'new' ? 'vividBlue' : 'skyBlue'}>{type}</Label>
      }
      </ImgContainer>
      <Content>
        {name &&
          <Text variant="h6" colour="vividBlue" uppercase>
            {name}
          </Text>
        }
        {series &&
          <Series>
            {series}
          </Series>
        }
        {price &&
          <Text variant="h5" colour="charcoal" uppercase>{price}</Text>
        }
        {weeklyRepayment &&
          <From variant="subtitle1" colour="charcoal" uppercase>FROM ${toPrice(weeklyRepayment)} per week*</From>
        }
        <Info>
          <Text variant="p" colour="charcoal">
            {variant &&
            <>
              {variant}<br />
            </>}
            {year &&
            <>
              Year: {year}<br />
            </>}
            {stockNumber &&
            <>
              Stock Number: {stockNumber}<br />
            </>}
            {vin &&
              <div data-vin={vin}>
                VIN: <span className="vin">{vin}</span><br />
              </div>
            }
          </Text>
        </Info>
        
        <ContentBottom>
          {cta &&
            <CtaDesktop outline inline href={cta.url} onClick={(e) => onClick(e, id)}>{cta.text}</CtaDesktop>
          }

          {cta &&
            <LinkMobile href={cta.url}>
              <a onClick={(e) => onClick(e, id)}>
                Find out more
              </a>
            </LinkMobile>
          }

          <SavedContainer>
            <ButtonItem onClick={handleSave}>
              <ReactSVG src={saved ? heartFilledIcon : heartIcon} />
            </ButtonItem>
          </SavedContainer>
        </ContentBottom>
        {props.dealerName && 
          <Footer>
            <Text variant="body2" colour="charcoal" weight={600}>Dealer: {props.dealerName}</Text>
          </Footer>
        }
      </Content>
    </Container>
  )
}
  
StockCard.propTypes = {
  onClick: propTypes.func,
  series: propTypes.string
}
  
StockCard.defaultProps = {
  setSavedItems: () => {},
  onClick: () => {},
  series: null
}
  
export default StockCard
import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import FormContainer from '../Forms/FormContainer';
import {theme} from '../../themes/default-theme';
import {Text} from '../../components/typography';
import dlPush from '../../util/dlPush';
import ReactHtmlParser from 'react-html-parser';
import IconLink from '../../components/ui/IconLink';

const Container = styled.div`
  // padding: 20px 15px;
  background: transparent;
  border-bottom: 1px solid ${theme.palette.middleGrey};


  // ${theme.breakpoints.up('md')} {
  //   padding: 60px 30px;
  //   border-bottom-left-radius: 0px;
  // }

  // ${theme.breakpoints.up('lg')} {
  //   padding: 60px 80px;
  // }

  [role="radiogroup"] {
    label {
      margin-right: 23px;

      ${theme.breakpoints.up('md')} {
        margin-right: 53px;
      }
    }
  }

  textarea {
    min-height: 160px;
  }
`

const Wrapper = styled.div`

  padding: 20px 15px;
  margin-left: -15px;
  margin-right: -15px;

  ${theme.breakpoints.up('md')} {
    background: transparent;
    padding: 0;
    padding-left: 30px;
  }
`

const Title = styled(Text)`
  text-align: left;
  ${theme.breakpoints.up('md')} {
    text-align: left;
  }
`

const DealerText = styled(Text)`
  margin-top: 5px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
`

const ThankYou = styled.div`

`

const Address = styled(Text)`
  padding-left: 22px;
  position: relative;
  margin-top: 20px;

  svg {
    position: absolute;
    left: 0;
    top: 5px;
  }
`

const FormHeader = styled.div`
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid ${theme.palette.middleGrey};

  ${theme.breakpoints.up('lg')} {
    padding-top: 34px;
    margin-top: 34px;
  }
`

const CalculatorLink = styled.div`

  h6 {
    margin-bottom: 10px;
  }

  button {
    margin-bottom: 30px;
    margin-top: 15px;
  }

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 30px;
    border-bottom: 1px solid ${theme.palette.middleGrey};

  }
`

const StockEnquiryForm = ({
  modelId,
  dealerId,
  dealerName,
  dealerAddress,
  title,
  calculatorModal,
  onCalcModalClick
}) => {

  const [thankYou, showThankYou] = useState(false);

  const handleSubmitted = () => {
    dlPush({
      event: 'jaycoEvent',
      eventAction: 'Form Submit Success',
      eventCategory: 'Engage',
      eventLabel: 'Make an Enquiry'
    });
    showThankYou(true);
    const { top } = wrapperRef.current.getBoundingClientRect();
    const yOffset = -100;
    const position = top + window.pageYOffset + yOffset
    position ? window.scrollTo({
      top: position,
      behavior: 'smooth'
    }) : wrapperRef.current.scrollIntoView({ behavior: 'smooth'});
  }
  const dealer = process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.name : "{{data.template.dealer.name}}"
  const dealerTitle = dealerName ? dealerName : dealer
  
  const wrapperRef = useRef();

  const dealerField = dealerId ? {
    type:  'hidden',
    name: 'dealer',
    value: dealerId
  } : {}

  console.log('dealerAddress', dealerAddress);

  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        {thankYou ? 
        <ThankYou>
          <Title>Your enquiry has been received. A representative from {dealerTitle} will be in contact with you soon.</Title>
        </ThankYou>
        :
        <>
          <Title variant="h6" colour="black">{title}</Title>
          <DealerText variant="p" colour="gunmentalGrey">DEALER</DealerText>

          {dealerAddress && 
            <Address variant="body1" colour="gunmentalGrey">

              {ReactHtmlParser(`
                <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 9.075C6.43168 9.075 5.88663 8.85112 5.48477 8.4526C5.08291 8.05409 4.85714 7.51358 4.85714 6.95C4.85714 6.38641 5.08291 5.84591 5.48477 5.4474C5.88663 5.04888 6.43168 4.825 7 4.825C7.56832 4.825 8.11336 5.04888 8.51523 5.4474C8.91709 5.84591 9.14286 6.38641 9.14286 6.95C9.14286 7.22906 9.08743 7.50539 8.97974 7.7632C8.87205 8.02102 8.71421 8.25528 8.51523 8.4526C8.31625 8.64993 8.08002 8.80645 7.82004 8.91324C7.56005 9.02004 7.2814 9.075 7 9.075ZM7 1C5.4087 1 3.88258 1.62687 2.75736 2.74271C1.63214 3.85856 1 5.37196 1 6.95C1 11.4125 7 18 7 18C7 18 13 11.4125 13 6.95C13 5.37196 12.3679 3.85856 11.2426 2.74271C10.1174 1.62687 8.5913 1 7 1Z" stroke="#0170D4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
              )}

              {ReactHtmlParser(`
                ${dealerAddress.addressLine1},<br />
                ${dealerAddress.addressLine2.length > 0 ? `${dealerAddress.addressLine2},<br />` : ''}
                ${dealerAddress.state} ${dealerAddress.postcode}
              `)}
            </Address>
          }

          <FormHeader>
                    
            {calculatorModal &&
                <CalculatorLink>
                  <Text variant={'h6'} colour="black">Jayco Finance</Text>
                  <Text variant={'body1'}>Turn your holiday dreams into reality! Get a personalised, no-obligation quote instantly, tailored to your needs.</Text>
                  <IconLink 
                      svg={''}
                      link={{
                          title: calculatorModal && calculatorModal.buttonText ? calculatorModal.buttonText : 'SEE FINANCE OPTIONS TAILORED TO YOU'
                      }}
                      colour={'vividBlue'}
                      onClick={onCalcModalClick}
                  />
                </CalculatorLink>
            } 

            <Title variant="h6" colour="black">Contact Dealer</Title>
          </FormHeader>

          <FormContainer
          
              onThankyou={() => handleSubmitted()}
              formId="stock_enquiry"
              padding={false}
              background="transparent"
              topo={false}
              thankYouUrl="inline"
              formItems={[
                {...dealerField},
                {
                  type:  'hidden',
                  name: 'model',
                  value: modelId
                },
                {
                  type: 'name',
                  name: 'first_name',
                  label: 'first_name',
                  placeholder: 'First name*',
                  required: true,
                  errorText: 'This field is required',
                },
                {
                  type: 'name',
                  name: 'last_name',
                  label: 'last_name',
                  placeholder: 'Last name*',
                  required: true,
                  errorText: 'This field is required',
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'email',
                  placeholder: 'Email*',
                  required: true,
                  errorText: 'Please add a valid email address',
                },
                {
                  type: 'phone',
                  name: 'phone',
                  label: 'phone',
                  placeholder: 'Contact number*',
                  required: true,
                  errorText: 'Please add a valid phone number',
                },
                {
                  type: 'radio',
                  name: 'trade_in',
                  label: `Do you have a van to trade in?*`,
                  showLabel: true,
                  inline: true,
                  options: [{
                    optionValue: 'Yes',
                    label: 'Yes',
                  },{
                    optionValue: 'No',
                    label: 'No',
                  }],
                  required: true,
                  // Conditional for fileupload
                  onVisibilityIndex: [7, 'Yes'],
                  errorText: 'Please select yes or no',
                },
                {
                  type: 'fileupload',
                  name: 'files[]',
                  label: 'fileupload',
                  placeholder: 'Upload any supporting photos (optional)',
                  required: false,
                  // Visibility update, handled by trade_in radio
                  visible: false,
                  errorText: 'Please select a valid file type',
                },
                {
                  type: 'textarea',
                  name: 'comments',
                  label: 'comments',
                  placeholder: 'Comments',
                  required: false,
                },
                {
                  type: 'checkbox',
                  margin: 'none',
                  name: 'e_newsletter',
                  label: `Sign me up for ${dealerTitle}'s e-Newsletter`,
                },
                {
                  type: 'checkbox',
                  margin: 'none',
                  name: 'privacy',
                  label: `I have read and agree to ${dealerTitle}'s <a href="/privacy" target="_blank">Privacy&nbsp;Policy</a>*`,
                  required: true,
                  errorText: 'This field is required',
                }
              ]}
            />
          </>
        }
      </Container>
    </Wrapper>
  )
}
  
StockEnquiryForm.propTypes = {
  modelId: propTypes.string.isRequired,
  dealerId: propTypes.string,
  dealerName: propTypes.string,
  title: propTypes.string,
  dealerAddress: propTypes.object
}
  
StockEnquiryForm.defaultProps = {
  modelId: null,
  dealerId: null,
  dealerName: null,
  title: 'Make an enquiry',
  dealerAddress: null
}
  
export default StockEnquiryForm
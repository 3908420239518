import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';

import {theme} from '../../../themes/default-theme';

// components
import Cta from '../../../components/ui/Cta';
import Banner from '../../../components/multimedia/Banner';
import BannerSlider from '../../../components/multimedia/BannerSlider';
import Text from '../../../components/typography';

const Container = styled.div`

  ${props => props.hb === 0 ? `
    margin: 60px 0;
  ` : `
    margin: 40px 0;
  `}

  ${theme.breakpoints.up('md')} {
    margin: 60px 0;
  }

  ${props => props.hb === 0 && `
    ${theme.props.desktopNavUp} {
      margin-top: 180px;
    }
  `}

  ${theme.breakpoints.up('lg')} {
    ${props => props.hb === 0 ? `
      margin: 200px 0 120px;
    ` : `
      margin: 120px 0;
    `
  }
`

const Content = styled.div`
  max-width: 780px;
  margin: 0 auto;
`

const CtaItem = styled.div `
  display: inline-block;
  padding: 10px;
  min-width: 215px;

  ${theme.breakpoints.down('xs')} {
    display: block;
  }
`

const ThankYou = (props) => {
  return (
    <>
      {props.heroBanner &&
        <Banner
          {...props.heroBanner}
          largePaddingTop={true}
          mainHeading={props.bannerHeading}
        />
      }
      {props.heroBannerSlider &&
        <BannerSlider
          {...props.heroBannerSlider}
        />
      }
      <Container hb={props.heroBanner || props.heroBannerSlider ? 1 : 0}>
        <Grid container direction={'row'} justify={'space-between'} align={'center'}>
          <Grid item xs={12}>
            <Box px={2}>
              <Content>
                <Text variant="body1" align="center">{props.text}</Text>
              </Content>
              <Box px={2} py={3}>
                {props.ctas.map((item, index) => {
                  return (
                    <CtaItem key={index}>
                      {
                        index % 2 === 0 ?
                        <Cta href={item.href}>{item.title}</Cta>
                      :
                        <Cta href={item.href} outline>{item.title}</Cta>
                      }
                    </CtaItem>
                  )
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
  
ThankYou.propTypes = {
  heroBanner: propTypes.object,
  bannerHeading: propTypes.string,
  heroBannerCarousel: propTypes.object,
  text: propTypes.string,
  ctas: propTypes.array
}
  
ThankYou.defaultProps = {
  heroBanner: null,
  heroBannerCarousel: null,
  bannerHeading: 'Thank you',
  text: 'We have received your enquiry.',
  ctas: [
    {
      href: '/range',
      title: 'Browse the range'
    },
    {
      href: '/',
      title: 'Return home'
    }
  ]
}

export default ThankYou

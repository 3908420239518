import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';

import {theme} from '../../../themes/default-theme';
import Slider from '../Slider';
import Modal from '../../ui/Modal';

const Container = styled.div`
  position: relative;

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    height: auto;
    object-fit: cover;
  }

  ${theme.breakpoints.up("md")} {
    padding-bottom: 50px;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    bottom: -45px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .slick-prev {
    transform: translateX(-40px);
  }

  .slick-next {
    transform: translateX(0px);
  }

`

const BgImg = styled.div`
  background-size: cover;
  height: 280px;
  background-position: center;
  max-height: calc(100vh - 82px);

  ${theme.breakpoints.up('sm')} {
    height: 675px;
    max-height: calc(100vh - 130px);
  }

  ${theme.breakpoints.up('lg')} {
    max-height: calc(100vh - 90px);
  }
`

const ModalGallery = ({
    items,
    settings,
    open,
    onClose,
    activeIndex
  }) => {

  const slickSettings = {
    fade: false,
    ...settings
  }

  const useStyles = makeStyles(theme => ({
    modalOverlay: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      }
    },
    modalInner: {
      width: '100%',
      display: 'inline-block',
      position: 'relative',
      maxWidth: 940,
      
      [theme.breakpoints.up('md')]: {
        paddingRight: 40,
      }
    },
    modalClose: {
      left: 10,
      marginBottom: '20px',
      position: 'absolute',

      [theme.breakpoints.down('sm')]: {
        bottom: '-45px',
        left: 0,
        right: 0,
        margin: 'auto',
        transform: 'translateX(40px)',
        width: 24,
      },

      [theme.breakpoints.up('md')]: {
        top: 0,
        bottom: 'auto',
        left: 'auto',
        right: 0,
        marginBottom: 0,
      }
    }
  }));

  const classes = useStyles();

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      overlayClassname={classes.modalOverlay}
      innerClassname={classes.modalInner}
      closeClassname={classes.modalClose}
    >
      <Container>
        <Slider
          settings={{
            ...slickSettings,
            initialSlide: activeIndex
          }}
          arrowProps={{
            grey: false,
            skyBlue: true,
            small: true
          }}
        >
          {items.map((item, index) => (
            <div key={item.id || index}>
              <BgImg style={{backgroundImage: `url(${item.img})`}} />
              {/* <img src={item.img} width={item.width} height={item.height} alt={item.alt} /> */}
            </div>
          ))}
        </Slider>
      </Container>
    </Modal>
  )
}
  
ModalGallery.propTypes = {
  items: propTypes.array.isRequired,
  settings: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  activeIndex: propTypes.number
}

ModalGallery.defaultProps = {
  settings: {},
  items: [],
  open: false,
  onClose: () => {},
  activeIndex: 0
}
  
export default ModalGallery
import React, {useState, useRef, useEffect} from 'react';
import propTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import {useForm, Controller} from "react-hook-form";
import styled from 'styled-components';
import {get} from 'lodash';
import {Grid, Box, TextField, RadioGroup, FormControlLabel} from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import axios from 'axios';
import Router from 'next/router';

import {theme} from '../../../themes/default-theme';
import Dropdown from '../../../components/ui/Dropdown';
import Checkbox from '../../../components/forms/Checkbox';
import isPostalCode from 'validator/lib/isPostalCode';

import Button from '../../../components/ui/Cta';
import Text from '../../../components/typography';

const FormItem = styled.div`
  display: block;
  padding: 0 16px;
  text-align: left;
  margin:${props => props.smallMargin === true ? `15px 0` : `30px 0`};
  ${props => props.marginTop === false && `margin-top: 0;`}

  ${theme.breakpoints.down('md')} {
    ${props => props.col === true && `margin-top: 0;`}
    ${props => props.marginBottomSmall === false && `margin-bottom: 0;`}
  }

  ${theme.breakpoints.up('md')} {
    ${props => props.col === true && `margin: 0;`}
    ${props => props.marginTop === false && `margin-top: 0;`}
  }
`

const Footer = styled.div`
  padding: 0 16px 30px 16px;
`

const Error = styled.p`
  color: ${theme.palette.error.form}
`

const Hr = styled.hr`
  border-bottom: 1px solid ${theme.palette.middleGrey};
  border-top: 0;
`

const ContactUsForm = (props) => {
  const {
    country
  } = props
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const defaultValues = {};
  const [googleCaptcha, setGoogleCaptcha] = useState(false);

  const { register, handleSubmit, watch, errors, control, formState } = useForm({
    defaultValues
  });

  const onSubmit = data => {

    const recaptchaValue = recaptchaRef.current.getValue();

    let refatorDropdowns = {
      enquiry_type: (data['enquiry_type'] && data['enquiry_type'].value) ? data['enquiry_type'].value : undefined
    }

    // formItems.forEach(item => {
    //   if (item.type === 'select') {
    //     refatorDropdowns[item.name] = data[item.name] && data[item.name].value ? data[item.name].value : undefined
    //   }
    // });

    const dealerId = process.env.DEALER_SITE && window.staticly.dealerData.id ? {
      dealer: window.staticly.dealerData.id
    } : {}

    const formData = {
      ...data,
      ...refatorDropdowns,
      'g-recaptcha-response': googleCaptcha,
      form_id: 'contact_us',
      ...dealerId
    }

    let bodyFormData = new FormData();
    Object.keys(formData).forEach(key => {
      formData[key] && bodyFormData.append(key, formData[key].toString() || '');
    })

    if (googleCaptcha && recaptchaValue.length > 0 && Object.keys(errors).length === 0) {
      setCaptchaError(null);

      const endpoint = process.env.STATICLY_FORMS_API_ENDPOINT;

      setSending(true);

      const thankYouUrl = '/contact-us/thank-you'

      // axios.post(endpoint, formData)
      axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        process.env.NODE_ENV === 'development' || process.env.DEALER_SITE ?
          window.location.href = thankYouUrl
        :
        Router.push(thankYouUrl);
      })
      .catch((error) => {
        console.log('Form error: ', error);
        setError('Something went wrong. Please try again later.');
        setSending(false);
      });

    } else {
      setCaptchaError('Google captcha not complete');
      setSending(false)
    }
  }
  const recaptchaRef = useRef();

  const requiredRules = {required: true}

  const dealerTitle = process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.name : "{{data.template.dealer.name}}"

  return (
  	<form id={'contact_us'} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormItem marginTop={false}>
        <Text variant="h4" align="left" colour="charcoal">Send us an enquiry</Text>
      </FormItem>
      <FormItem>
        <Controller
          as={TextField}
          control={control}
          rules={requiredRules}
          name={'first_name'}
          placeholder={'First Name*'}
          variant="outlined"
          error={ get(errors, `[first_name]`, false) ? true : false}
          fullWidth
          defaultValue={''}
        />
        {get(errors, `[first_name]`, false) && <Error>Please enter your first name</Error>}
      </FormItem>
      <FormItem>
        <Controller
          as={TextField}
          control={control}
          rules={requiredRules}
          name={'last_name'}
          placeholder={'Last Name*'}
          variant="outlined"
          error={ get(errors, `[last_name]`, false) ? true : false}
          fullWidth
          defaultValue={''}
        />
        {get(errors, `[last_name]`, false) && <Error>Please enter your last name</Error>}
      </FormItem>
      <FormItem>
        <Controller
          type="email"
          as={TextField}
          control={control}
          rules={{
            ...requiredRules,
            type: 'custom',
            validate: (e) => isEmail(e)
          }}
          name={'email'}
          placeholder={'Email*'}
          variant="outlined"
          error={get(errors, `[email]`, false) ? true : false}
          fullWidth
          defaultValue={''}
        />
        {get(errors, `[email]`, false) && <Error>Please enter a valid email</Error>}
      </FormItem>
      <Grid container direction={'row'} justify={'space-between'} align={'center'}>
        <Grid item xs={12} md={6}>
          <FormItem col={true}>
            <Controller
              type="tel"
              as={TextField}
              control={control}
              rules={{
                ...requiredRules,
                type: 'custom',
                minLength: 8,
                maxLength: 13
              }}
              name={'phone'}
              placeholder={'Contact number*'}
              variant="outlined"
              error={get(errors, `[phone]`, false) ? true : false}
              fullWidth
              defaultValue={''}
            />
            {get(errors, `[phone]`, false) && <Error>Please enter a valid phone number</Error>}
          </FormItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormItem col={true} marginBottomSmall={false}>
            <Controller
              type="tel"
              as={TextField}
              control={control}
              rules={{
                ...requiredRules,
                type: 'custom',
                minLength: 3,
                maxLength: 4,
                validate: (val) => isPostalCode(val, 'AU')
              }}
              name={'postcode'}
              placeholder={'Postcode*'}
              variant="outlined"
              error={get(errors, `[postcode]`, false) ? true : false}
              fullWidth
              defaultValue={''}
            />
            {get(errors, `[postcode]`, false) && <Error>Please enter a valid postcode</Error>}
          </FormItem>
        </Grid>
      </Grid>
      <FormItem>
        <Controller
          as={
            <Dropdown 
              variant="outlined"
              showComplete={true}
              error={get(errors, `[enquiry_type]`, false) ? true : false}
              label={'Nature of your enquiry*'}
              options={[{
                value: 'Warranty',
                text: 'Warranty',
                id: 1,
              },{
                value: 'Service & Parts',
                text: 'Service & Parts',
                id: 2,
              },{
                value: 'Sales enquiry',
                text: 'Sales enquiry',
                id: 3,
              },{
                value: 'Technical Enquiry',
                text: 'Technical Enquiry',
                id: 4,
              },{
                value: 'Trade-In Enquiry',
                text: 'Trade-In Enquiry',
                id: 5,
              },{
                value: 'Other',
                text: 'Other',
                id: 6,
              }]}
            />
          }
          control={control}
          rules={{
            ...requiredRules,
            type: 'custom'
          }}
          name={'enquiry_type'}
        />
        {get(errors, `[enquiry_type]`, false) && <Error>This field is required</Error>}
      </FormItem>
      <FormItem>
        <Controller
          as={TextField}
          control={control}
          type="textarea"
          name={'enquiry'}
          placeholder={'Enquiry'}
          variant="outlined"
          error={ get(errors, `[enquiry]`, false) ? true : false}
          fullWidth
          defaultValue={''}
          multiline={true}
          rows={5}
        />
      </FormItem>

      <FormItem smallMargin={true}>
        <Controller
          as={Checkbox}
          name={'e_newsletter'}
          type="checkbox"
          control={control}
          defaultValue={false}
          label={`Sign me up for ${process.env.DEALER_SITE ? `${dealerTitle}'s` : `Jayco ${country}`} e-Newsletter`}
          rules={{
            type: 'custom'
          }}
        />
        <br />

        <Controller
          as={Checkbox}
          name="privacy"
          type="checkbox"
          control={control}
          label={`I have read and agree to ${process.env.DEALER_SITE ? `${dealerTitle}'s` : `Jayco ${country}`} <a href="/privacy" target="_blank">Privacy Policy</a>*`}
          error={ get(errors, `[privacy]`, false) ? true : false}
          defaultValue={false}
          rules={{
            ...requiredRules,
            type: 'custom'
          }}
        />
        {get(errors, `[privacy]`, false) && <Error>This field is required</Error>}
      </FormItem>
      <FormItem>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={setGoogleCaptcha}
        />
        {!googleCaptcha && captchaError &&
          <Error>{captchaError}</Error>
        }
        {error &&
          <Error>{error}</Error>
        }
      </FormItem>
      <FormItem>
        <Hr />
      </FormItem>
      <Footer>
       
          <Grid container direction={'row'} justify={'space-between'} align={'center'}>
            <Grid item xs={6}>
              <Text variant="body2">Mandatory*</Text>
            </Grid>
            <Grid item xs={6} align={'right'}>
              <Button inline type="submit">{sending ? 'Submitting...'  : 'Submit'}</Button>
            </Grid>
          </Grid>
   
      </Footer>
    </form>
  )
}
  
ContactUsForm.propTypes = {
}
  
ContactUsForm.defaultProps = {
}
  
export default ContactUsForm